/* eslint-disable camelcase */
import {
  Address,
  APIPlace,
  GooglePlaceAddress,
} from "@companion-core/shared/app/Interfaces/adresses";

const formatAddress = (name: string, formattedAddress: string) => {
  return formattedAddress.includes(name) ? formattedAddress : `${name}, ${formattedAddress}`;
};

export const formatLocation = (data: APIPlace): GooglePlaceAddress => {
  const address: Address = data.address;
  return {
    formatted_address: formatAddress(data.name, address.formatted_address),
    city: address.city,
    country_code: address.country_code,
    latitude: address.latitude,
    longitude: address.longitude,
    state: address.state,
    viewport: address.viewport,
    street: [address.street_number, address.route].filter(Boolean).join(" "),
    zipcode: address.zipcode,
    timezone_id: data.timezone_id,
  };
};
