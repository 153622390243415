import { getModule } from "@companion-core/shared/app/Utils/config";
import { Category } from "@companion-core/shared/app/Components/Bell/Categories";
import { BellContent } from "@companion-core/shared/app/Store/bellStore";

export const getCategoryFromName = (name: string) => {
  const categories: Category[] = getModule("concierge").modules.bell?.options.categories;
  return categories.find((category: Category) => category.name === name);
};

export const getInitialBellContent = (): BellContent => {
  const isBellHomeEnabled: boolean = getModule("concierge")?.modules.bell.options.home.enabled;
  return isBellHomeEnabled ? "home" : "categories";
};
