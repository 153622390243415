/* eslint-disable camelcase */
import { APIMemberData } from "@companion-core/shared/app/Interfaces/API";

export const formatPayload = (
  formPayload: Record<string, unknown>,
  dataMember?: APIMemberData | null,
) => {
  const { firstname, lastname } = dataMember || {};
  const { subject, subscription_external_id } = formPayload;
  const email = dataMember?.emails[0]?.email;
  return {
    email,
    firstname,
    lastname,
    specifics: {
      family: "Member-relationship",
      subfamily: subject,
      subscription_external_id,
    },
    ...formPayload,
  };
};
