import React from "react";
import { useTranslation } from "react-i18next";
import { NavigationListProps } from "./index";
import { NavigationItemObject } from "@companion-core/shared/app/Interfaces/navigation";
import { NavigationItem } from "@companion-core/shared/app/Components/NavigationItem";
import { EventLink } from "@companion-core/shared/app/Components/EventLink";
import { ButtonNavigationItem } from "@companion-core/shared/app/Components/NavigationItem/ButtonNavigationItem";

import "@companion-core/web/src/Assets/Styles/scss/navigation.scss";

declare global {
  interface Optanon {
    ToggleInfoDisplay: () => void;
  }
  interface Window {
    Optanon: Optanon;
  }
}

export const NavigationList = ({
  name,
  navigationItems,
  onEnterInternalNavigation,
  onEnterNestedNavigation,
  onEnterLogoutNavigation,
}: NavigationListProps) => {
  const { t } = useTranslation();

  const onNavigationItemClicked = (navigationItem: NavigationItemObject) => {
    const type = navigationItem.type;
    if (type === "logout") {
      return onEnterLogoutNavigation();
    }
    if (
      ["internal", "dynamic", "internal-button"].includes(type ?? "") &&
      onEnterInternalNavigation
    ) {
      return onEnterInternalNavigation(navigationItem);
    }
    if (type === "nav") {
      return onEnterNestedNavigation(navigationItem);
    }
  };

  return navigationItems?.length ? (
    <div data-testid={`navigation-list--${name}`}>
      <h3
        data-testid={`navigation-list__title--${name}`}
        className="navigation-list__title title-5">
        {t(`my-account.navigation.${name}.title`)}
      </h3>
      {navigationItems.map((navigationItem: NavigationItemObject) => {
        if (navigationItem.type === "internal-button") {
          return (
            <ButtonNavigationItem
              key={navigationItem.name}
              name={navigationItem.name}
              label={t(navigationItem.label)}
              buttonType={navigationItem.buttonType}
              onClick={() => onNavigationItemClicked(navigationItem)}
            />
          );
        }
        return (
          <EventLink
            key={navigationItem.name}
            name={navigationItem.name}
            type={navigationItem.type}
            link={navigationItem.link}
            path={navigationItem.path}
            targetBlank={navigationItem.targetBlank}
            onClick={() => onNavigationItemClicked(navigationItem)}>
            <NavigationItem
              name={navigationItem.name}
              label={t(navigationItem.label)}
              leftIcon={navigationItem.leftIcon}
              withRightIcon={navigationItem.withRightIcon}
            />
          </EventLink>
        );
      })}
    </div>
  ) : (
    <></>
  );
};
