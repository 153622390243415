/* eslint-disable camelcase */
import { formatIncompletePhoneNumber, isValidPhoneNumber, parsePhoneNumber, parsePhoneNumberFromString, validatePhoneNumberLength } from "libphonenumber-js";
import { CountryCode } from "libphonenumber-js";

export const onTextChange = (
  phoneNumber: string,
  country: CountryCode,
  setFormattedPhoneInput: (value: string | null) => void
  ): void => {
  if (!phoneNumber.length) {
    setFormattedPhoneInput(null);
    return;
  }

  // update phone number depending on the country format, only if it does not exceed the number of digits
  if (validatePhoneNumberLength(phoneNumber, country) === "TOO_SHORT" || validatePhoneNumberLength(phoneNumber, country) === undefined) {
    const formattedNumber = isValidPhoneNumber(phoneNumber, country) 
      ? parsePhoneNumber(phoneNumber, country).formatNational()
      : formatIncompletePhoneNumber(phoneNumber, country);
    setFormattedPhoneInput(formattedNumber);
  }
}

export const isValid = (phoneNumber: string, country: CountryCode): boolean =>
  !!parsePhoneNumberFromString(phoneNumber, country)?.isPossible();

export const formatPhoneNumber = (phoneNumber: string) =>
  phoneNumber.replace(/ /g, "");

// TODO: Remove this when V2 members will be implemented
const memberV2ToMemberV1Fields = {
  personal_mobilePhone: "personal-mobile",
  personal_homePhone: "personal-home",
  professional_mobilePhone: "professional-mobile",
};

// TODO: Remove this when V2 members will be implemented
export const formatPhoneNumberToPayload = (requestPayload: Record<string, unknown>) => {
  const payload = { ...requestPayload }
  const phones = [];
  const payloadKeys: string[] = Object.keys(payload);

  Object.entries(memberV2ToMemberV1Fields).forEach(([v2Field, v1Field]) => {
    if (payloadKeys.includes(v2Field)) {
      const formattedPhone = formatPhoneNumber(payload[v2Field] as string);
      phones.push({
        "type": v1Field,
        "phone": formattedPhone,
        "is-preferred-contact-method": false,
      });
      delete payload[v2Field];
    }
  })
  
  payload["phones"] = phones;
  return payload;
}

