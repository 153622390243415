import React from "react";
import { AccountMenuItem } from ".";
import { useAuthStore } from "@companion-core/shared/app/Store/authStore";
import { useNavigate } from "react-router-dom";
import "@companion-core/web/src/Assets/Styles/scss/accountMenu.scss";

interface AccountMenuDropdownProps {
  items: AccountMenuItem[];
}

export const AccountMenuDropdown = ({ items }: AccountMenuDropdownProps) => {
  const navigate = useNavigate();

  const logout = () => {
    useAuthStore.getState().logout();
    navigate("login");
  };

  return (
    <ul className="ant-dropdown-menu">
      {items?.map((item, index) => (
        <li
          className="ant-dropdown-menu-item"
          key={index}
          onClick={() => (item.name === "logout" ? logout() : navigate(item.name))}>
          <div className="ant-dropdown-menu-item-container">
            <span className="ant-dropdown-menu-item-icon">{item?.icon}</span>
            <span className="ant-dropdown-menu-title-content">{item?.label}</span>
          </div>
        </li>
      ))}
    </ul>
  );
};
