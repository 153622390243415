import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { IndependentDynamicForm } from "@companion-core/shared/app/Components/DynamicForm/IndependentDynamicForm";
import { getJSONFromFile } from "@companion-core/shared/app/Assets/forms/register/jsonLoader";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import { getModule } from "@companion-core/shared/app/Utils/config";
import { registerRequest } from "@companion-core/shared/app/Utils/apiClient";
import { useAuthStore } from "@companion-core/shared/app/Store/authStore";
import { getLang } from "@companion-core/web/src/Utilities/lang";
import RegisterContent from "Components/Register/RegisterContent";
import { trackClickHelp } from "@companion-core/shared/app/tracking/register";
import "Assets/Styles/scss/register.scss";

const Register = () => {
  const { t } = useTranslation();
  const lang = getLang();
  const formPath = getModule("register").formPath;
  const authStore = useAuthStore();
  const navigate = useNavigate();
  const jsonForm = getJSONFromFile(formPath);
  const [registerContent, setRegisterContent] = useState<string>("register");

  const onSubmitForm = async (payload: Record<string, unknown>) => {
    const formattedPayload = {
      ...payload,
      "language": lang,
    };
    const response = await registerRequest(formattedPayload);
    if (response.success && response.code === 200) {
      if (!response.result?.isEligible && response.result?.email) {
        setRegisterContent("register-member-already-verified");
      } else if (response.result?.email) {
        setRegisterContent("register-member-to-verify");
      } else {
        const memberToRegister = {
          memberId: response.result?.id,
          "eligibility_criteria": payload,
        };
        authStore.setMemberToRegister(memberToRegister);
        navigate("/register/email");
      }
    } else {
      if (response.error?.response.status === 404) {
        setRegisterContent("register-member-not-found");
      } else {
        setRegisterContent("register-error");
      }
    }
  };

  const dynamicFormName = useMemo(() => {
    const splitPath = formPath?.split("/");
    if (!splitPath) return "form";
    return splitPath[splitPath.length - 1].replace(".json", "");
  }, [formPath]);

  const RegisterHeader = () => {
    return (
      <div className="register__header">
        {registerContent !== "register" ? (
          <span
            data-testid="register__header--back-button"
            className="register__header-back-button cursor-pointer"
            onClick={() => setRegisterContent("register")}>
            <FontIcon name="JP-Arrow-left-bold" size={24} />
            {t("register.back-button.label")}
          </span>
        ) : (
          <></>
        )}
      </div>
    );
  };

  return (
    <div data-testid="register" className="register">
      <RegisterHeader />
      <div className="register__container">
        {registerContent === "register" ? (
          <IndependentDynamicForm
            name={dynamicFormName}
            initialFormJSON={jsonForm}
            onSubmitForm={onSubmitForm}
          />
        ) : (
          <RegisterContent
            state={registerContent}
            redirectCallback={() => setRegisterContent("register")}
          />
        )}
        <div className="register__need-help" data-testid="register__need-help">
          <span className="body-text-8">
            {t("register.links.help.pre")} {t("register.links.help.post")}
            <Link
              data-testid="register__need-help--link"
              to="/need-help"
              className="body-text-7"
              onClick={() => trackClickHelp()}>
              {t("register.links.help.link")}
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Register;
