type ModuleType = typeof import("dayjs/locale/en");
/**
 * @description This object is used to dynamically import the locale files
 * or to use the default locale which is a string.
 *
 */
export const LOCALE_IMPORTS: { [key: string]: () => Promise<ModuleType> } = {
  fr: () => import("dayjs/locale/fr"),
  it: () => import("dayjs/locale/it"),
  de: () => import("dayjs/locale/de"),
};
