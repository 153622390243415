import React from "react";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";

interface Props {
  label: string;
  icon?: string;
}

const InputLabel = ({ label, icon }: Props) => {
  return (
    <div className="d-flex">
      {icon && (
        <FontIcon
          data-testid="input-label__icon"
          name={icon}
          className="body-text-5 margin-right-8"
        />
      )}
      <div className="body-text-5 margin-bottom-8">{label}</div>
    </div>
  );
};

export default InputLabel;
