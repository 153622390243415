/* eslint-disable camelcase */
import { customVars, getCustomVars } from "./customVars";
import { Page, Click } from "@companion-core/shared/app/Interfaces/tracking";

let track: (event: string, params: Page | Click) => void = () => {
  // eslint-disable-next-line no-console
  console.log("track method not initialized yet");
};

export let setUser: (
  userId: string, 
  subscriptionsId: string, 
  userCategory?: string, 
  storeId?: boolean) => void = () => {
  // eslint-disable-next-line no-console
  console.log("setUser method not initialized yet");
}

export function setTrackCallback(callback: (event: string, params: Page | Click) => void) {
  track = callback;
}

export function setUserCallback(
  callback: (userId: string, subscriptionsId: string, userCategory?: string, storeId?: boolean) => void
) {
  setUser = callback;
}

export function trackPage({
  page = "",
  page_chapter1 = "",
  page_chapter2 = "",
  page_chapter3 = "",
  site_level2 = 0,
  vendorId = "",
  benefitId = "",
  requestId = "",
}) {
  customVars.referrer = customVars.url;
  customVars.url = window?.location?.href.replace("#/", "") || ""; // In mobile url field should be empty

  if (vendorId) {
    customVars.vendor_id = vendorId;
  }
  if (benefitId) {
    customVars.offer_id = benefitId;
  }
  if (requestId) {
    customVars.case_UUID = requestId;
  }

  const vars = getCustomVars();

  const payload: Page = {
    page,
    page_chapter1,
    page_chapter2,
    page_chapter3,
    site_level2,
    ...vars,
  };

  track("page.display", payload);

  return payload;
}

export function trackClick({
  click_type = "",
  click = "",
  click_chapter1 = "",
  click_chapter2 = "",
  click_chapter3 = "",
  site_level2 = 0,
}) {
  const vars = getCustomVars();
  const payload: Click = {
    click_type,
    click,
    click_chapter1,
    click_chapter2,
    click_chapter3,
    site_level2,
    ...vars,
  };

  track("click.navigation", payload);

  return payload;
}
