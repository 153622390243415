import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";
import { Category } from "@companion-core/shared/app/Components/Bell/Categories";
import { useConfigStore } from "@companion-core/shared/app/Store/configStore";

export type BellContent = "home" | "categories" | "form" | "confirmation";
type BellStore = BellState & BellActions;

interface BellState {
  bellContent: BellContent;
  bellCategory: null | Category;
  formPath: string | null;
  isPopUpVisible: boolean;
}

interface BellActions {
  setBellContent: (bellContent: BellContent) => void;
  setBellCategory: (bellCategory: null | Category) => void;
  setIsPopUpVisible: (isPopUpVisible: boolean) => void;
  setFormPath: (formJSON: string) => void;
  clearBell: (bellContentToSet?: BellContent) => void;
  resetBell: () => void;
}

export const initialState: BellState = {
  bellContent: useConfigStore.getState().config?.modules?.bell?.options?.home?.enabled
    ? "home"
    : "categories",
  bellCategory: null,
  formPath: null,
  isPopUpVisible: false,
};

export const initialContentState = {
  bellContent: useConfigStore.getState().config?.modules?.bell?.options?.home?.enabled
    ? "home"
    : "categories",
  bellCategory: null,
  formPath: null,
};

export const useBellStore = createWithEqualityFn<BellStore>(
  (set) => ({
    ...initialState,
    setBellContent: (bellContent: BellContent) => set({ bellContent }),
    setBellCategory: (bellCategory: null | Category) => set({ bellCategory }),
    setFormPath: (formPath: string) => set({ formPath }),
    setIsPopUpVisible: (isPopUpVisible: boolean) => set({ isPopUpVisible }),
    clearBell: (bellContentToSet?: BellContent) =>
      set({
        ...initialContentState,
        bellContent: bellContentToSet
          ? bellContentToSet
          : (initialContentState.bellContent as BellContent),
      }),
    resetBell: () =>
      set({
        ...initialState,
      }),
  }),
  shallow,
);
