import React, { ReactElement, useMemo } from "react";
import { MosaicLayoutProps, MosaicLayoutTemplateProps } from "./index";
import { useMediaQuery } from "@companion-core/web/src/Utilities/mediaQuery";
import "Assets/Styles/scss/mosaicLayout.scss";

/**
 * MosaicLayoutTemplate renders a collection of items in a mosaic pattern.
 *
 * @param {Object} props - The properties that define the MosaicLayoutTemplate component.
 * @param {Array} props.items - The array of React elements to be rendered as items in the mosaic layout.
 * @param {boolean} [props.isInverted=false] - Determines if the mosaic layout template should be inverted.
 *
 * @returns {ReactElement|0} - The rendered mosaic layout or null if no items are provided.
 */
export const MosaicLayoutTemplate = ({
  items,
  isInverted = false,
}: MosaicLayoutTemplateProps): ReactElement | 0 => {
  return (
    items.length && (
      <div
        data-testid="mosaic-layout"
        className={`mosaic-layout mosaic-layout--${items.length} ${
          isInverted && "mosaic-layout--inverted"
        }`}>
        {items.map((itemReactElement, index) => {
          return (
            <div
              key={`mosaic-layout-item-${index}`}
              className={`mosaic-layout__item mosaic-layout__item-${index + 1}`}>
              {itemReactElement}
            </div>
          );
        })}
      </div>
    )
  );
};

/**
 * MosaicLayout represents a layout component that displays several MosaicLayoutTemplate in succession.
 *
 * @component
 * @param {Object} props - The properties that define the MosaicLayout component.
 * @param {Array} props.items - The mosaic items to display.
 * @param {"vertical"|"horizontal"} props.mobileDisplay - List orientation that will be displayed on small devices.
 * @returns {ReactElement|0} - The rendered mosaic layout or null if there are no items.
 */
export const MosaicLayout = ({
  items,
  mobileDisplay = "vertical",
}: MosaicLayoutProps): ReactElement | 0 => {
  const isMobileSize = useMediaQuery("(max-width: 768px)");
  let invertTemplate = true;
  // Copy items in order to keep the original value and not modify with the splice function.
  const copyItems: ReactElement[] = useMemo(() => {
    const newArray: ReactElement[] = [];
    items.forEach((item) => {
      newArray.push(item);
    });
    return newArray;
  }, [items]);
  // Splice items in multiple arrays of items. Each array contains 7 items max.
  const itemChunks: ReactElement[][] = useMemo(() => {
    const chunks: ReactElement[][] = [];
    const chunkSize = 7;
    while (copyItems.length > 0) {
      chunks.push(copyItems.splice(0, chunkSize));
    }
    return chunks;
  }, [copyItems]);

  return (
    itemChunks.length && (
      <div
        data-testid="mosaic-layout-container"
        className={`mosaic-layout-container ${
          isMobileSize &&
          mobileDisplay === "horizontal" &&
          "mosaic-layout-container--horizontal-on-mobile"
        }`}>
        {isMobileSize
          ? items
          : itemChunks.map((items, index) => {
              invertTemplate = !invertTemplate;
              return (
                <MosaicLayoutTemplate
                  key={`mosaic-layout-template-${index}`}
                  items={items}
                  isInverted={invertTemplate}
                />
              );
            })}
      </div>
    )
  );
};
