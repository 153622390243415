import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import {
  createSortedSummaryFields,
  getFamilyIcon,
} from "@companion-core/shared/app/Utils/myRequests";
import { RequestStatus } from "../RequestStatus";
import { Button } from "@companion-core/shared/app/Components/Button";
import { Summary } from "@companion-core/shared/app/Components/DynamicForm/Summary";
import { useMediaQuery } from "@companion-core/web/src/Utilities/mediaQuery";
import { RequestDetailProps } from ".";
import { PopIn, PopInProps } from "@companion-core/shared/app/Components/PopIn";
import { CancelPopin } from "./CancelPopin";
import { useMemberStore } from "@companion-core/shared/app/Store/memberStore";
import { IndependentDynamicForm } from "@companion-core/shared/app/Components/DynamicForm/IndependentDynamicForm";
import { getJSONFromFile } from "@companion-core/shared/app/Assets/forms/myRequests/jsonLoader";
import { modifyRequest } from "@companion-core/shared/app/Utils/apiClient";
import "@companion-core/web/src/Assets/Styles/scss/myrequestsDetail.scss";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(localizedFormat);

export const RequestDetail = ({ data, goBack }: RequestDetailProps) => {
  const { t } = useTranslation();
  const initialForm = getJSONFromFile("myRequests/detail/modify.json");
  const isMobileSize = useMediaQuery("(max-width: 768px)");
  const summary = createSortedSummaryFields(data);
  const { requests, setMemberRequests } = useMemberStore();
  const [requestData, setRequestData] = useState(data);
  const [isPopinOpen, setIsPopinOpen] = useState(false);
  const [detailContent, setDetailContent] = useState("detail");
  const modifyPopinIntialArgs: PopInProps = {
    testID: "request-detail__modify-popin",
    primaryButton: {
      text: t("request.my-requests.detail.popin.modify.primary-button.text"),
      onClick: () => {
        setDetailContent("detail");
        setIsPopinOpen(false);
      },
    },
    onClose: () => {
      setDetailContent("detail");
      setIsPopinOpen(false);
    },
  };

  const [popinArgs, setPopinArgs] = useState(modifyPopinIntialArgs);

  const sendModificationRequest = async (payload: Record<string, unknown>) => {
    try {
      const response = await modifyRequest(data.id, data.family, payload.message as string);
      if (requests) {
        requests[requests.indexOf(requestData)] = {
          ...response.data.attributes,
          id: response.data.id,
        };
      }
      setMemberRequests(requests);
      setRequestData({ ...response.data.attributes, id: response.data.id });
      setPopinArgs({
        ...modifyPopinIntialArgs,
        icon: "JP-Circle-check",
        title: t("request.my-requests.detail.popin.modify.title"),
      });
    } catch (e) {
      setPopinArgs({
        ...modifyPopinIntialArgs,
        icon: "JP-Circle-close",
        title: t("request.my-requests.detail.popin.modify-error.title"),
      });
    }
    setIsPopinOpen(true);
  };

  const HeaderSection = () => {
    return (
      <div className="request-detail__header">
        {detailContent === "modification" && (
          <div
            className="request-detail__header-button"
            onClick={() => setDetailContent("detail")}
            data-testid="request-detail__header-back-cta">
            <FontIcon name="JP-Arrow-left-bold" size={24} />
          </div>
        )}
        <div className="request-detail__header-placeholder" />
        <div
          className="request-detail__header-button"
          onClick={() => goBack()}
          data-testid="request-detail__header-close-cta">
          <FontIcon name="JP-Close-bold" size={24} />
        </div>
      </div>
    );
  };

  const TitleSection = () => {
    return (
      <div className="request-detail__title" data-testid="request-detail__title">
        <FontIcon
          name={getFamilyIcon(requestData.family, requestData.subfamily)}
          size={24}
          className="body-text-5 margin-right-8"
        />
        <div className={`title-${isMobileSize ? "6" : "7"} request-detail__subject`}>
          {requestData.subject}
          {requestData.location && (
            <>
              {t("request.my-requests.location.particle")}
              {requestData.location.city as string}
            </>
          )}
        </div>
        <div className="body-text-6 request-detail__date" data-testid="request-detail__date">
          <span>
            {!isMobileSize && t("request.my-requests.time.pre")}
            {dayjs(requestData.created_date).format(
              `L [${t("request.my-requests.time.particle")}] LT`,
            )}
            {!isMobileSize && t("request.my-requests.time.post")}
          </span>
        </div>
      </div>
    );
  };

  const DetailHome = () => {
    return (
      <div className="request-detail__container" data-testid="request-detail">
        <div className="request-detail__data">
          <HeaderSection />
          <TitleSection />
          <div className="request-detail__status" data-testid="request-detail__status">
            <RequestStatus status={requestData.status} />
          </div>
          <div className="margin-top-24" data-testid="request-detail__summary">
            <Summary fieldList={summary} display={isMobileSize ? "mobile" : "desktop"} />
          </div>
        </div>
        {!["cancellation-requested", "modification-requested", "closed", "cancelled"].includes(
          requestData.status,
        ) && (
          <div className="request-detail__cta-container">
            <Button
              classNames={["request-detail__modify-cta"]}
              icon="JP-Edit"
              label={t("request.my-requests.detail.button.modify.label")}
              testID="request-detail__modify-cta"
              onClick={() => setDetailContent("modification")}
            />
            <Button
              classNames={["request-detail__cancel-cta", "button--secondary"]}
              icon="JP-Close-bold"
              label={t("request.my-requests.detail.button.cancel.label")}
              testID="request-detail__cancel-cta"
              onClick={() => {
                setIsPopinOpen(true);
              }}
            />
          </div>
        )}
      </div>
    );
  };

  const RequestModification = () => {
    return (
      <div className="request-detail__modification-container">
        <HeaderSection />
        {isMobileSize ? (
          <TitleSection />
        ) : (
          <FontIcon name="JP-Edit" size={30} className="request-detail__modification-icon" />
        )}
        <div
          className="body-text-5 request-detail__modification-title"
          data-testid="request-detail__modification-title">
          {t("request.my-requests.detail.modify.title")}
        </div>
        <div data-testid="request-detail__modification-form">
          <IndependentDynamicForm
            name="modifyRequest"
            initialFormJSON={initialForm}
            onSubmitForm={sendModificationRequest}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      {isPopinOpen &&
        (detailContent === "modification" ? (
          <PopIn {...popinArgs} />
        ) : (
          <CancelPopin
            requestData={requestData}
            updateRequest={(request) => setRequestData(request)}
            onClose={() => setIsPopinOpen(false)}
          />
        ))}
      {detailContent === "detail" ? <DetailHome /> : <RequestModification />}
    </>
  );
};
