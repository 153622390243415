import { ApiEnvironment, setAppEnvironment } from "@companion-core/shared/app/Utils/config";

export function setWebEnvironment() {
  const apiEnv: ApiEnvironment = {
    configFileName: process.env.REACT_APP_CONFIG_FILENAME_LOGGED,
    configUnloggedFileName: process.env.REACT_APP_CONFIG_FILENAME_UNLOGGED,
    apiUrl: process.env.REACT_APP_API_URL,
    i18nUrl: process.env.REACT_APP_I18N_URL,
    clientId: process.env.REACT_APP_WEB_CLIENT_ID,
    clientSecret: process.env.REACT_APP_WEB_CLIENT_SECRET,
  }

  setAppEnvironment(process.env.REACT_APP_ENVIRONMENT, apiEnv);
}