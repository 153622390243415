import React, { ReactElement, useMemo } from "react";
import { Link } from "react-router-dom";
import { EventLinkProps } from "./index";
import { useBellStore } from "@companion-core/shared/app/Store/bellStore";
import { getCategoryFromName } from "@companion-core/shared/app/Utils/bell";
import { capitalize } from "@companion-core/shared/app/Utils/format";
import { Category } from "@companion-core/shared/app/Components/Bell/Categories";
import "@companion-core/web/src/Assets/Styles/scss/eventLink.scss";

declare global {
  interface Optanon {
    ToggleInfoDisplay: () => void;
  }
  interface Window {
    Optanon: Optanon;
  }
}

export const EventLink = ({
  name,
  type,
  path,
  link,
  targetBlank = true,
  children,
  onClick: onEventLinkClicked,
}: EventLinkProps) => {
  const capitalizedType: string = useMemo(() => capitalize(type), [type]);
  const { setIsPopUpVisible, setBellContent, setBellCategory, setFormPath } = useBellStore(
    (state) => ({
      isPopUpVisible: state.isPopUpVisible,
      setIsPopUpVisible: state.setIsPopUpVisible,
      setBellContent: state.setBellContent,
      setBellCategory: state.setBellCategory,
      setFormPath: state.setFormPath,
    }),
  );

  const EventLinkComponents: Record<string, () => ReactElement> = {
    Internal: () => (
      <Link
        data-testid={`event-link__internal--${name}`}
        to={path}
        className="event-link"
        onClick={onEventLinkClicked}>
        {children}
      </Link>
    ),
    Dynamic: () => (
      <Link
        data-testid={`event-link__dynamic--${name}`}
        to={path}
        className="event-link"
        onClick={onEventLinkClicked}>
        {children}
      </Link>
    ),
    Cookies: () => (
      <a
        data-testid={`event-link__cookies--${name}`}
        className="event-link"
        rel="noreferrer"
        onClick={() => {
          window.Optanon?.ToggleInfoDisplay();
          onEventLinkClicked && onEventLinkClicked(name);
        }}>
        {children}
      </a>
    ),
    External: () => (
      <a
        data-testid={`event-link__external--${name}`}
        target={targetBlank ? "_blank" : "_self"}
        href={link}
        className="event-link"
        rel="noreferrer"
        onClick={onEventLinkClicked}>
        {children}
      </a>
    ),
    Concierge: () => (
      <div
        data-testid={`event-link__concierge--${name}`}
        className="event-link"
        onClick={() => {
          const category: Category = getCategoryFromName(name);
          if (category) {
            setFormPath(category.formPath);
            setBellContent("form");
            setBellCategory(category);
          } else {
            setBellContent("categories");
          }
          setIsPopUpVisible(true);
          onEventLinkClicked && onEventLinkClicked(name);
        }}>
        {children}
      </div>
    ),
    Nav: () => (
      <div
        data-testid={`event-link__nav--${name}`}
        className="event-link"
        onClick={onEventLinkClicked}>
        {children}
      </div>
    ),
    Logout: () => (
      <div
        data-testid={`event-link__logout--${name}`}
        className="event-link"
        onClick={onEventLinkClicked}>
        {children}
      </div>
    ),
  };

  if (!EventLinkComponents[capitalizedType]) return <></>;

  const EventLinkComponent = EventLinkComponents[capitalizedType] as ReactElement;
  return <EventLinkComponent />;
};
