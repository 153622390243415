import React from "react";
import { TextInShapeProps } from "./index";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import "@companion-core/web/src/Assets/Styles/scss/textInShape.scss";

export const TextInShape = ({
  testID,
  shape = "default",
  text,
  icon,
  value,
  selected = false,
  onClick,
}: TextInShapeProps) => {
  return (
    <div
      data-testid={`${testID}-${value}`}
      className={`shape shape--${shape} ${selected && "shape--selected"}`}
      onClick={() => onClick && onClick(value)}>
      {icon && <FontIcon name={icon} size={24} />}
      <span className="shape__text body-text-6">{text}</span>
    </div>
  );
};
