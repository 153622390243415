import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form } from "antd";

// Components
import { Button } from "@companion-core/shared/app/Components/Button";
import { JPInput } from "@companion-core/shared/app/Components/Inputs";
import { JPInputPassword } from "@companion-core/shared/app/Components/Inputs/JPInputPassword";

// Utils
import { onSubmit } from "@companion-core/shared/app/Utils/login";
import { getHomeRoutes, getModule } from "@companion-core/shared/app/Utils/config";

// Icons
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";

// Styles
import "Assets/Styles/scss/login.scss";

// Store
import { useAuthStore } from "@companion-core/shared/app/Store/authStore";

// Tracking
import {
  trackClickForgotPassword,
  trackClickHelp,
  trackClickLogin,
  trackPageLogin,
} from "@companion-core/shared/app/tracking/login";
import { useBellStore } from "@companion-core/shared/app/Store/bellStore";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { error, loading, setError } = useAuthStore((state) => ({
    error: state.error,
    loading: state.loading,
    setError: state.setError,
  }));
  const { setIsPopUpVisible } = useBellStore((state) => ({
    setIsPopUpVisible: state.setIsPopUpVisible,
  }));
  const usernameValidators = getModule("login").options?.username.validators;

  useEffect(() => {
    trackPageLogin();
    return setError(null);
  }, []);

  const handleLogin = async (values: Record<string, string>) => {
    const { username, password } = values;
    const { logged: homeRouteLogged } = getHomeRoutes() || "home";
    const result = await onSubmit(username, password, t);
    if (result) {
      if (getModule("concierge")?.modules?.bell?.options?.isPopUpVisibleByDefault) {
        setIsPopUpVisible(true);
      }
      navigate(location.state?.redirectPath ?? `/${homeRouteLogged}`);
    }
  };

  return (
    <div data-testid="login" className="login">
      <div className="login__container">
        <span className="login__title super-title-3">{t("login.form.pageTitle")}</span>

        {/* Error message */}
        {error && (
          <div data-testid="login__auth-error" className="login__auth-error">
            <FontIcon name="JP-Circle-close-outline" className="login__error-icon" />
            <span className="body-text-6" dangerouslySetInnerHTML={{ __html: error }} />
          </div>
        )}
        <Form
          name="login-form"
          layout="vertical"
          autoComplete="off"
          form={form}
          requiredMark={false}
          onFinish={handleLogin}
          onFinishFailed={() => trackClickLogin(false, "empty_form")} // only 1 rule added in the form (required = true), so onFinishFailed is triggered only when the form is empty
          style={{
            marginTop: error ? "0" : "68px",
          }}>
          {/* Email */}
          <Form.Item name="username">
            <JPInput
              testID="login__input-username"
              name="username"
              required
              label={t("login.form.inputs.username")}
              type="text"
              placeholder="example@domain.com"
              validators={usernameValidators}
            />
          </Form.Item>

          {/* Password */}
          <Form.Item name="password" className="login__password">
            <JPInputPassword
              testID="login__input-password"
              name="password"
              required
              label={t("login.form.inputs.password")}
            />
          </Form.Item>

          {/* Forgot password */}
          <Form.Item className="login__forgotten-password">
            <Link
              data-testid="login__link-need-help"
              to="/password/recover"
              className="body-text-5"
              onClick={() => trackClickForgotPassword()}>
              {t("login.links.recover-password")}
            </Link>
          </Form.Item>

          {/* Submit */}
          <Form.Item className="login__submit">
            <Button
              testID="login__submit-button"
              htmlType="submit"
              type="primary"
              size="full"
              loading={loading}
              label={t("login.buttons.login")}
            />
          </Form.Item>

          {/* Contact us */}
          <Form.Item className="login__need-help">
            <span className="body-text-8">
              {t("login.links.help.pre")} {t("login.links.help.post")}
              <Link
                data-testid="login__link-need-help"
                to="/need-help"
                className="body-text-7"
                onClick={() => trackClickHelp()}>
                {t("login.links.help.link")}
              </Link>
            </span>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
