import React, { useMemo, useEffect, useState } from "react";

import { useParams, useNavigate, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Divider } from "antd";

import { useDetailPage } from "@companion-core/shared/app/Queries/DetailPage";
import { useI18nStore } from "@companion-core/shared/app/Store/i18nStore";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import { Banner } from "@companion-core/web/src/Components/Banner";
import { Button } from "@companion-core/shared/app/Components/Button";
import { Map } from "@companion-core/web/src/Components/Map";
import { useMediaQuery } from "@companion-core/web/src/Utilities/mediaQuery";
import { ContentDetailsPageProps } from "./ContentDetailsPage.model";
import { getFamilyIcon } from "@companion-core/shared/app/Utils/myRequests";
import { getContentComponentMediaUrl } from "@companion-core/shared/app/Utils/componentHelper";

import "@companion-core/web/src/Assets/Styles/scss/contentDetailsPage.scss";

const HEIGHT_BREAKPOINT = 250;

export const ContentDetailsPage = ({ googleMapsApiKey }: ContentDetailsPageProps) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const isMobileSize = useMediaQuery("(max-width: 768px)");
  const { locale: lang } = useI18nStore((state) => ({ locale: state.locale }));
  const { data, isFetching } = useDetailPage({ parentID: id ?? "", lang });
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  const bannerImageUrl: string | undefined = useMemo(() => {
    if (data)
      return (
        getContentComponentMediaUrl(data, "detail") ?? getContentComponentMediaUrl(data, "image")
      );
  }, [data]);

  const logoImageUrl: string | undefined = useMemo(() => {
    if (data) return getContentComponentMediaUrl(data, "logo");
  }, [data]);

  const bookingProcessTitle = useMemo(() => {
    if (data)
      return data.booking_process?.type != "concierge"
        ? `common.${data.content_ct}.details.cta.${data.type}.online.title`
        : `common.${data.content_ct}.details.cta.${data.type}.place.title`;
  }, [data]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const Subtitle = () => {
    if (data?.booking_process?.type != "concierge" && data?.vendor?.name) {
      return (
        <h2 data-testid="detail-page__subtitle" className="body-text-4">
          {data?.vendor?.name ?? ""}
        </h2>
      );
    } else if (data?.vendor?.google_place?.address) {
      return (
        <h2 data-testid="detail-page__subtitle" className="body-text-4">
          {data.vendor.google_place.address.formatted_address as string}
        </h2>
      );
    }
  };

  const BookingCTA = () => {
    if (data?.booking_process?.type != "concierge") {
      return (
        <Button
          size={isMobileSize ? "full" : "large"}
          label={t(`common.${data?.content_ct}.details.cta.external-link`)}
          testID="detail-page__booking-process--cta"
          onClick={() => window.open(data?.booking_process?.website ?? "", "_blank")}
        />
      );
    } else {
      return (
        <Button
          size={isMobileSize ? "full" : "large"}
          icon="JP-Concierge-bell"
          label={t(`common.${data.content_ct}.details.cta.create-request`)}
          testID="detail-page__booking-process--cta"
          // TODO: Open Bell with loaded form
        />
      );
    }
  };

  if (!isFetching && data) {
    return (
      <div className="detail-page" data-testid="detail-page">
        <div onClick={() => navigate(-1)} data-testid="detail-page__back-button">
          <FontIcon name="JP-Arrow-left-bold" size={24} className="detail-page__back-button" />
        </div>

        {bannerImageUrl && (
          <Banner imageUrl={bannerImageUrl ?? ""} overlap={false} disapearOnMobileSize={false} />
        )}

        <div className="detail-page__layout">
          <div className="detail-page__content">
            {data.title && (
              <h1 data-testid="detail-page__title" className="title-7">
                {data.title}
              </h1>
            )}

            <Subtitle />

            {data.description && (
              <div
                className="body-text-6 detail-page__description"
                data-testid="detail-page__description">
                <span className="body-text-5">
                  {t(`common.${data.content_ct}.details.your-privilege.title`)}
                </span>
                <div
                  className="detail-page__description-content"
                  dangerouslySetInnerHTML={{ __html: data.description.replace(/\n/g, "<br>") }}
                />
              </div>
            )}

            {data?.vendor?.name && data.vendor.description && (
              <div data-testid="detail-page__vendor">
                <Divider className="detail-page__divider" />
                <span className="body-text-5">{data.vendor.name}</span>
                <p
                  className="body-text-6 detail-page__vendor-description"
                  dangerouslySetInnerHTML={{
                    __html: data.vendor.description.replace(/\n/g, "<br>"),
                  }}
                />
              </div>
            )}

            {(data.legal_notice || data.booking_process?.conditions) && (
              <div data-testid="detail-page__legal-notice">
                <Divider className="detail-page__divider" />
                <span className="body-text-5">
                  {t(`common.${data.content_ct}.details.conditions.title`)}
                </span>
                {data.booking_process?.conditions && (
                  <div
                    className="body-text-6 detail-page__legal-notice-content"
                    dangerouslySetInnerHTML={{
                      __html: data.booking_process.conditions.replace(/\n/g, "<br>"),
                    }}
                  />
                )}
                {data.legal_notice && (
                  <div
                    className="body-text-6 detail-page__legal-notice-content"
                    dangerouslySetInnerHTML={{
                      __html: data.legal_notice.replace(/\n/g, "<br>"),
                    }}
                  />
                )}
              </div>
            )}

            {data?.vendor?.google_place && googleMapsApiKey && (
              <div data-testid="detail-page__map">
                <Divider className="detail-page__divider" />
                <span className="body-text-5">
                  {t(`common.${data.content_ct}.details.useful-info.title`)}
                </span>
                <div className="detail-page__map">
                  <FontIcon name="JP-Location" />
                  <a
                    href={data.vendor.google_place.url ?? ""}
                    className="link-2 detail-page__map-link">
                    {data.vendor.google_place.address.formatted_address as string}
                  </a>
                </div>
                <Map
                  center={{
                    lat: (data.vendor?.google_place.address.latitude as number) ?? 0,
                    lng: (data.vendor?.google_place.address.longitude as number) ?? 0,
                  }}
                  className="detail-page__map-wrapper"
                  zoom={11}
                  iconFamily={getFamilyIcon(data.vendor?.family)}
                  iconClassName="detail-page__map-pin-icon"
                  googleMapApiKey={googleMapsApiKey}
                />
              </div>
            )}
          </div>
        </div>

        <div
          className={`detail-page__booking-wrapper ${
            scrollPosition > HEIGHT_BREAKPOINT ? "detail-page__booking-wrapper--fixed" : ""
          }`}
          data-testid="detail-page__booking-process">
          <div className="detail-page__booking-container">
            <div className="detail-page__booking-title">{t(bookingProcessTitle ?? "")}</div>
            <div className="detail-page__booking-detail">
              {logoImageUrl && (
                <div
                  data-testid="detail-page__booking-process--logo"
                  className="detail-page__booking-logo">
                  <img src={logoImageUrl} />
                </div>
              )}
              <BookingCTA />
            </div>
          </div>
        </div>
      </div>
    );
  } else if (!isFetching && !data) {
    return <Navigate to="/not-found" />;
  }
};
