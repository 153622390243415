import React from "react";
import { Button } from "@companion-core/shared/app/Components/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getHomeRoutes } from "@companion-core/shared/app/Utils/config";
import "@companion-core/web/src/Assets/Styles/scss/errors.scss";

export const NotFound = () => {
  const { logged: homeRouteLogged } = getHomeRoutes();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="error__container">
      <img className="error__image" src="/assets/images/error/not_found_error.svg" />
      <div className="body-text-3 error__title">{t("common.error.404.title")}</div>
      <div className="body-text-4 margin-top-24">{t("common.error.404.text")}</div>
      <Button
        classNames={["error__button"]}
        testID="error-not-found__button"
        label={t("common.error.404.button")}
        onClick={() => navigate(`/${homeRouteLogged}`)}
      />
    </div>
  );
};
