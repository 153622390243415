import React, { useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { APIRequest } from "@companion-core/shared/app/Interfaces/API";
import { ContextType } from "@companion-core/shared/app/Interfaces/myRequests";
import { RequestCard, RequestListProps } from "@companion-core/shared/app/Components/Requests";
import { Button } from "@companion-core/shared/app/Components/Button";
import { useBellStore } from "@companion-core/shared/app/Store/bellStore";
import { Spin } from "antd";
import "Assets/Styles/scss/myrequests.scss"

export const RequestList = ({ initialTabIndex }: RequestListProps) => {
  const navigate = useNavigate();
  const { data, requestsFetched, activeKey, setActiveKey } = useOutletContext<ContextType>();
  const { setIsPopUpVisible } = useBellStore(
    (state) => ({ setIsPopUpVisible: state.setIsPopUpVisible })
  )
  const { t } = useTranslation();

  useEffect(() => {
    if (initialTabIndex) setActiveKey(initialTabIndex);
  }, []);

  const requestsList = () => {
    return <div data-testid="my-requests__list">
      {data?.map((value: APIRequest) => (
        <RequestCard
          data={value}
          onClick={() => navigate(`/my-requests/${value.id}`)}
          key={value.id}
        />
      ))}
    </div>
  }

  if (requestsFetched && data) {
    return data?.length > 0 
      ? requestsList()
      : <div className="my-requests__no-request" data-testid="my-requests__no-request">
          <div
            className="body-text-4 my-requests__no-request-label"
            data-testid="my-requests__no-request-label"
          >
            { activeKey == "0"
              ? t("request.my-requests.no-requests.on-going")
              : t("request.my-requests.no-requests.done")
            }
          </div>
          <Button
            classNames={["my-requests__no-request-button"]}
            label={`${t("request.my-requests.no-requests.button.label")}`}
            testID="my-requests__no-request-button"
            onClick={() => setIsPopUpVisible(true)}
          />
       </div>
      } else return <div className="my-requests__no-request"><Spin data-testid="my-requests__loader" size="large" /></div>
      
}