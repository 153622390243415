import React, { useMemo } from "react";
import { BlockProps } from "@companion-core/shared/app/Interfaces/Blocks";
import { ComponentData } from "@companion-core/shared/app/Interfaces/API";
import { BannerLayout } from "@companion-core/shared/app/Components/Blocks/Layouts/BannerLayout";
import {
  getComponentButton,
  getComponentMedia,
  getComponentsFromBlockItems,
  getComponentText,
} from "@companion-core/shared/app/Utils/componentHelper";

export const Banner = ({ simpleBlockItems }: BlockProps) => {
  const simpleComponents: ComponentData[] = useMemo(
    () => getComponentsFromBlockItems(simpleBlockItems),
    [simpleBlockItems],
  );

  const background: string | undefined = useMemo(
    () => getComponentMedia(simpleComponents)?.medias?.[0]?.url,
    [simpleComponents],
  );
  const title: string = useMemo(
    () => getComponentText(simpleComponents)?.title ?? "",
    [simpleComponents],
  );
  const subtitle: string = useMemo(
    () => getComponentText(simpleComponents)?.subtitle ?? "",
    [simpleComponents],
  );
  const description: string | undefined = useMemo(
    () => getComponentText(simpleComponents)?.description,
    [simpleComponents],
  );
  const button: ComponentData | undefined = useMemo(
    () => getComponentButton(simpleComponents),
    [simpleComponents],
  );

  return (
    <BannerLayout
      title={title}
      subtitle={subtitle}
      description={description}
      mediaUrl={background}
      button={button}
    />
  );
};
