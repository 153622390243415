import React, { ReactElement, useMemo } from "react";
import { ButtonList } from "@companion-core/shared/app/Components/Blocks/BaseComponent/ButtonList";
import { Banner } from "@companion-core/shared/app/Components/Blocks/BaseComponent/Banner";
import { Detail } from "@companion-core/shared/app/Components/Blocks/BaseComponent/Detail";
import { List } from "@companion-core/shared/app/Components/Blocks/BaseComponent/List";
import { PictureList } from "@companion-core/shared/app/Components/Blocks/BaseComponent/PictureList";
import { PostList } from "@companion-core/shared/app/Components/Blocks/BaseComponent/PostList";
import { Selection } from "@companion-core/shared/app/Components/Blocks/BaseComponent/Selection";
import { useBlockItems } from "@companion-core/shared/app/Queries";
import { BlockProps } from "@companion-core/shared/app/Interfaces/Blocks";
import { DynamicContentProps } from "@companion-core/shared/app/Components/Blocks/DynamicContent/DynamicContent.model";

const Blocks: {
  [key: string]: ({
    simpleBlockItems,
    queryBlockItems,
    contentProps,
  }: BlockProps) => ReactElement | undefined;
} = {
  "button-list": ButtonList,
  "detail": Detail,
  "header": Banner,
  "list": List,
  "picture-list": PictureList,
  "post-list": PostList,
  "selection": Selection,
};

export const DynamicContent = ({ className = "", type, contentProps }: DynamicContentProps) => {
  if (Blocks[type]) {
    const { isLoading, data: blockItems } = useBlockItems({
      id: contentProps.id,
      lang: contentProps.lang,
    });
    const queryBlockItems = useMemo(
      () => blockItems?.filter((blockItem) => blockItem.type === "query") ?? [],
      [blockItems],
    );
    const simpleBlockItems = useMemo(
      () => blockItems?.filter((blockItem) => blockItem.type === "simple") ?? [],
      [blockItems],
    );
    const Block = Blocks[type] as ({
      simpleBlockItems,
      queryBlockItems,
      contentProps,
    }: BlockProps) => ReactElement;
    return (
      !isLoading && (
        <Block
          className={className}
          simpleBlockItems={simpleBlockItems}
          queryBlockItems={queryBlockItems}
          contentProps={contentProps}
        />
      )
    );
  }
};
