// Note: This file is used to load the JSON files for the dynamic forms in the Register component
// The JSON files are located in the shared/app/Assets/forms/register folder
// ReactNative does not support the dynamic loading of JSON files, so we have to import them manually
import { DynamicFormJson } from "@companion-core/shared/app/Interfaces/wizard";
import oneStepRegisterJson from "./oneStep/oneStepRegister.json";
import oneStepRegisterWithoutEmailJson from "./oneStep/oneStepRegisterWithoutEmail.json";
import oneStepRegisterActivationJson from "./oneStep/oneStepRegisterActivation.json";
import identityJson from "./identity/identity.json";

const registerFormsMap: Record<string, Record<string, DynamicFormJson> | DynamicFormJson> = {
  identity: {
    identity: identityJson,
  },
  oneStep: {
    oneStepRegister: oneStepRegisterJson,
    oneStepRegisterActivation: oneStepRegisterActivationJson,
    oneStepRegisterWithoutEmail: oneStepRegisterWithoutEmailJson,
  },
};

export const createDeepCopy = (obj: DynamicFormJson) => {
  return JSON.parse(JSON.stringify(obj)) as DynamicFormJson;
};

/* Get the JSON from the file path provided
 * @param path - the path to the JSON file
 * @returns the JSON object
 * */
export const getJSONFromFile = (path: string) => {
  const [registerMethod, registerForm] = path.replace(".json", "").split("/").slice(-2);
  const originalFile = registerFormsMap[registerMethod]?.[registerForm] ?? {};
  return createDeepCopy(originalFile);
};
