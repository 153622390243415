import React, { useCallback, useEffect, useRef, useState } from "react";
import { Select, Form } from "antd";
import { JPInputAutoCompleteProps, Option } from ".";
import { getAddress, getAddresses } from "@companion-core/shared/app/Utils/apiClient";
import { JPInputError } from "../JPInputError";
import InputLabel from "@companion-core/web/src/Components/InputLabel";
import debounce from "lodash/debounce";
import { GooglePlaceAddress } from "@companion-core/shared/app/Interfaces/adresses";
import "@companion-core/web/src/Assets/Styles/scss/inputautocomplete.scss";

export const JPInputAutoComplete: React.FC<JPInputAutoCompleteProps> = ({
  name,
  required = false,
  addressType = "(cities)",
  label,
  value,
  icon,
  placeholder,
  testID,
  disabled = false,
  payloadPath = name,
  setFieldErrors,
  onValueChange,
  registerValidation,
  ...props
}: JPInputAutoCompleteProps) => {
  const form = Form.useFormInstance(); // Get current context form instance
  const [inputValue, setInputValue] = useState<GooglePlaceAddress | string>(value ?? "");
  const inputValueRef = useRef(inputValue); // Create a ref to store the latest value and avoid stale closure
  const [error, setError] = useState<string>("");
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    registerValidation(name, validateInput);
  }, []);

  useEffect(() => {
    inputValueRef.current = inputValue; // Update ref value
  }, [inputValue]);

  const debouncedSearch = useCallback(
    debounce((nextValue) => handleSearch(nextValue), 300),
    [], // will be created only once initially
  );

  const handleSearch = async (valueSearched: string) => {
    if (!valueSearched) return;
    let addresses: { value: string; label: string }[] = [];
    const response = await getAddresses(valueSearched, addressType);
    if (response.success && response.data) {
      addresses = response.data.map(({ attributes }) => ({
        value: attributes["place-id"],
        label: attributes.description,
      }));
    }
    setOptions(addresses);
  };

  const updateValue = async (selectedOption: string) => {
    const { response: address } = await getAddress(selectedOption);
    if (!address) return;
    if (form && payloadPath) {
      form.setFieldsValue({
        [payloadPath]: address,
      });
    }
    setInputValue(address);
    validateInput(address); // We need to re-validate input on change to delete error if any
    if (onValueChange) {
      onValueChange(address);
    }
  };

  const validateInput = (newValue?: GooglePlaceAddress) => {
    const inputValueString = newValue ?? inputValueRef.current;
    const err = !inputValueString && required ? "common.form.input.error.missing" : "";
    setError(err);
    setFieldErrors && setFieldErrors(err ? [err] : []);
  };

  return (
    <div className="JPInputAutoComplete">
      {label && <InputLabel label={label} icon={icon} />}
      <Select
        {...props}
        data-testid={testID}
        defaultValue={typeof value === "string" ? value : value?.formatted_address}
        placeholder={!disabled ? placeholder : ""}
        options={options}
        showSearch
        notFoundContent={null}
        suffixIcon={null}
        defaultActiveFirstOption={false}
        filterOption={false}
        status={error ? "error" : undefined}
        disabled={disabled}
        onSearch={debouncedSearch}
        onBlur={() => validateInput()}
        onChange={updateValue}
      />
      {error && <JPInputError testID={testID} error={error} />}
    </div>
  );
};
