import { getModule } from "@companion-core/shared/app/Utils/config";
import { NavigateFunction } from "react-router-dom";

export const isSSORoutePath = (pathname: string): boolean => pathname.startsWith("/sso");

export const hasCodeQueryParam = (searchParams: URLSearchParams): boolean => getModule("sso")?.enabled && (searchParams.get("code") !== null);

export const stringSearchToURLSearchParams = (searchString: string): URLSearchParams => {
  const urlSearchParams: URLSearchParams = new URLSearchParams();
  const searchStringItems: string[] = searchString.slice(1).split("&");
  searchStringItems.forEach((searchStringItem) => {
    const keyValueSearchParam: string[] = searchStringItem.split("=");
    urlSearchParams.append(keyValueSearchParam[0], keyValueSearchParam[1]);
  });
  return urlSearchParams;
}

export const redirectTo = (link: string, type: string, navigate: NavigateFunction) => {
  if (type === "internal") {
    navigate(link);
  } else {
    window.open(link);
  }
};
