/* eslint-disable camelcase */
import i18next, { t } from "i18next";
import { Field, formPath, FormTree, Step } from "@companion-core/shared/app/Interfaces/wizard";
import { getCurrency } from "./config";
import { datetimeToPayloadFormat } from "./datetime";

/**
 * Get the index of a given element in the formTree.
 * @param {FormTree} formTree
 * @param {number} stepNumber
 * @param {formPath} formPath
 * @returns {number}
 * @example
 * const formTree = [{ 12: "pathA" }, { 1: "pathB" }, { 4: "pathC" }];
 * const index = GetTreeElementIndex(formTree, stepNumber: 4, formPath: "pathC");
 * console.log(index); // 2
 */
const GetTreeElementIndex = (
  formTree: FormTree,
  stepNumber: number,
  formPath: formPath,
): number => {
  return formTree.findIndex((elem) => {
    return elem.stepNumber === stepNumber && elem.formPath === formPath;
  });
};

/**
 * Get the previous index of a given element in the formTree.
 * @param {FormTree} formTree
 * @param {number} stepNumber
 * @param {formPath} formPath
 * @returns {number | null}
 * @example
 * const formTree = [{ 12: "pathA" }, { 1: "pathB" }, { 4: "pathC" }];
 * const index = GetPreviousTreeElementIndex(formTree, stepNumber: 4, formPath: "pathC");
 * console.log(formTree[index]); // { 1: "pathB" }
 */
export const GetPreviousTreeElementIndex = (
  formTree: FormTree,
  stepNumber: number,
  formPath: formPath,
): number | null => {
  const index = GetTreeElementIndex(formTree, stepNumber, formPath);
  return index <= 0 ? null : index - 1;
};

/**
 * Get the next index of a given element in the formTree.
 * @param {FormTree} formTree
 * @param {number} stepNumber
 * @param {formPath} formPath
 * @returns {number | null}
 * @example
 * const formTree = [{ 12: "pathA" }, { 1: "pathB" }, { 4: "pathC" }];
 * const index = GetNextTreeElementIndex(formTree, stepNumber: 1, formPath: "pathB");
 * console.log(formTree[index]); // { 4: "pathC" }
 */
export const GetNextTreeElementIndex = (
  formTree: FormTree,
  stepNumber: number,
  formPath: formPath,
): number | null => {
  const index = GetTreeElementIndex(formTree, stepNumber, formPath);
  return index === formTree.length - 1 || index === -1 ? null : index + 1;
};

/**
 * Sets the value at path of object. If a portion of path doesn’t exist, it’s created.
 * This method mutates object.
 * @param {Record<string, unknown>} obj
 * @param {string} path
 * @param {unknown} value
 */
const set = (obj: Record<string, unknown>, path: string, value: unknown): void => {
  const pathArray = path.split(".");
  pathArray.reduce((acc, key, index) => {
    if (acc[key] === undefined) acc[key] = {}; // If path portion does not exist in object, we create it
    if (index === pathArray.length - 1) acc[key] = value; // If key is the final path item, we set the value
    return acc[key];
  }, obj);
};

/**
 * Returns a formatted payload to send to API given an array of fields.
 * @param {Field[]} fields
 * @param {Record<string, unknown>} defaultApiValues
 * @returns {Record<string, unknown>} payload
 */
export const toApiPayload = (
  fields: Field[],
  defaultApiValues: Record<string, unknown>,
): Record<string, unknown> => {
  const payload = fields
    .filter((field: Field) => !field.exclude)
    .reduce((acc: Record<string, unknown>, current: Field) => {
      set(acc, current.payloadPath ?? current.name, current.value ?? null);
      if (current.currency) set(acc, "currency", current.currency);
      return acc;
    }, {});
  // merge payload with default api values (possibility to concat with the dot, example : "participants.adults": 0)
  Object.entries(defaultApiValues).forEach(([key, value]) => {
    set(payload, key, typeof value === "string" ? t(value) || value : value);
  });
  return { ...payload };
};

/**
 * Update the fields array with a given step. Two possibilities:
 *  - Step already exists in the fields array.
 *    --> We overwrite the corresponding fields with those of the given step.
 *  - Step doesn't exist in the fields array.
 *    --> We simply add the step fields to the fields array
 * @param {Field[]} fields
 * @param {Step} step
 * @param {formPath} jsonPath
 * @returns {Field[]} updatedSummary
 */
export const getUpdatedFields = (fields: Field[], step: Step, jsonPath: formPath): Field[] => {
  let updatedFields = fields;
  const isStepAlreadyInFields: boolean = fields.some(
    (field) => field.fromStep?.stepNumber === step.order && field.fromStep?.formPath === jsonPath,
  );

  // We add the information that lets us know which step the field is attached to
  step.fields.map(
    (field: Field) => (field.fromStep = { stepNumber: step.order, formPath: jsonPath }),
  );
  if (isStepAlreadyInFields) {
    // If step already in fields, we update step fields in fields
    updatedFields.map((field) => {
      const correspondingStepField: Field | undefined = step.fields.find(
        (stepField) => stepField.payloadPath === field.payloadPath,
      );
      if (correspondingStepField) {
        field = correspondingStepField;
      }
      return field;
    });
  } else {
    // If step is not in fields, we add step fields to fields
    updatedFields = updatedFields.concat(step.fields);
  }
  return updatedFields;
};

/**
 * Format the payload with mandatory fields needed on the back side for concierge request
 * @param {Record<string, unknown>} payload
 * @param {string} family
 * @returns {Record<string, unknown>}
 */
export const formatConciergeRequestPayload = (
  payload: Record<string, unknown>,
  family: string,
): Record<string, unknown> => {
  replaceEmptyStringsByNullInPayload(payload);
  if (payload.budget) payload.currency = getCurrency().iso;
  const formattedPayload: Record<string, unknown> = { ...payload, family };
  if (payload.start_date) {
    formattedPayload.start_date = datetimeToPayloadFormat(
      payload.start_date as string,
      payload.start_time as string,
    );
    delete formattedPayload.start_time;
  }
  // Condition when start_date exists with start hour and end hour (baby-sitting category for example)
  if (!payload.end_date && payload.start_date && payload.end_time) {
    formattedPayload.end_date = datetimeToPayloadFormat(
      payload.start_date as string,
      payload.end_time as string,
    );
    delete formattedPayload.end_time;
  }
  if (payload.end_date) {
    formattedPayload.end_date = datetimeToPayloadFormat(
      payload.end_date as string,
      payload.end_time as string,
    );
    delete formattedPayload.end_time;
  }
  return addMissingMandatoryAttributesToPayload(formattedPayload);
};

/**
 * Add mandatory attributes to payload in order to send a valid concierge request
 * @param {Record<string, unknown>} payload
 * @returns {Record<string, unknown>}
 */
const addMissingMandatoryAttributesToPayload = (
  payload: Record<string, unknown>,
): Record<string, unknown> => {
  const completePayload: Record<string, unknown> = payload;
  const mandatoryAttributes: Record<string, unknown> = {
    language: i18next.language,
    benefit_id: null,
    notes: null,
    vendor_id: null,
    options: [],
    source_type: "athena",
    subject: t(`request.concierge.form.subject.${payload.subfamily || payload.family}`),
  };
  const payloadAttributes: string[] = Object.keys(payload);
  Object.entries(mandatoryAttributes).forEach(
    ([attributeKey, attributeValue]: [string, unknown]) => {
      if (!payloadAttributes.includes(attributeKey)) {
        completePayload[attributeKey] = attributeValue;
      }
    },
  );
  return completePayload;
};

/**
 * Replaces empty string in the given payload with null values for a specific list of fields.
 *
 * @param {Record<string, unknown>} payload - The payload object to modify.
 * @returns {void}
 */
const replaceEmptyStringsByNullInPayload = (payload: Record<string, unknown>): void => {
  const cannotBeBlankListOfFields: string[] = ["beginning_complementary_address"];
  Object.keys(payload).forEach((key: string) => {
    if (cannotBeBlankListOfFields.includes(key) && payload[key] === "") {
      payload[key] = null;
    }
  });
};
