/* eslint-disable camelcase */
import { trackPage, trackClick } from ".";
import PagesEnum from "./enums/pages";
import ChaptersEnum from "./enums/chapters";
import LevelsEnum from "./enums/levels";
import ClicTypes from "./enums/clic-types";
import ClicsEnum from "./enums/clics";

// Pages tracking

export function trackPageLogin() {
  return trackPage({
    page: PagesEnum.Login,
    page_chapter1: ChaptersEnum.Login,
    site_level2: LevelsEnum.Login,
  });
}

export function trackPageResetPassword({ stepNumber = 1 } = {}) {
  return trackPage({
    page: `${PagesEnum.ResetPasswordStepNumber}${stepNumber}`,
    page_chapter1: ChaptersEnum.Login,
    page_chapter2: ChaptersEnum.ResetPassword,
    page_chapter3: stepNumber === 1 ? ChaptersEnum.ResetPassword : ChaptersEnum.ResetPasswordConfirmation,
    site_level2: LevelsEnum.Login,
  });
}

// Clicks tracking

export function trackClickLogin(success: boolean, error: string|null) {
  return trackClick({
    click_type: ClicTypes.Navigation,
    click: ClicsEnum.Login,
    click_chapter1: ChaptersEnum.Login,
    click_chapter2: success ? ChaptersEnum.Success : ChaptersEnum.Fail,
    click_chapter3: error ?? "",
    site_level2: LevelsEnum.Login,
  });
}

export function trackClickForgotPassword() {
  return trackClick({
    click_type: ClicTypes.Navigation,
    click: ClicsEnum.ForgotPassword,
    click_chapter1: ChaptersEnum.Login,
    click_chapter2: ChaptersEnum.ResetPassword,
    site_level2: LevelsEnum.Login,
  });
}

export function trackClickHelp() {
  return trackClick({
    click_type: ClicTypes.Navigation,
    click: ClicsEnum.Help,
    click_chapter1: ChaptersEnum.Login,
    site_level2: LevelsEnum.Login,
  });
}
