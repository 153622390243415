import React from "react";
import { Button } from "@companion-core/shared/app/Components/Button";
import { useMediaQuery } from "@companion-core/web/src/Utilities/mediaQuery";
import { SlideProps } from "./index";
import "@companion-core/web/src/Assets/Styles/scss/slide.scss";

export const Slide = ({ slide }: SlideProps) => {
  const imageContent = slide.medias?.[0].url;
  const isMobileSize = useMediaQuery("(max-width: 768px)");

  return (
    <section className="slide d-flex justify-content-center align-items-center position-relative w-100">
      <div className="opacity-0 transition-1-ease mh-inherit w-100 h-100 opacity-1 transition-1">
        {/* Image */}
        <div className="slide-background w-100 h-100">
          <img src={imageContent} className="slide-img w-100 h-100" alt="slide-background-img" />
        </div>
        {/* Info */}
        <div className="info d-flex flex-direction-column justify-content-center position-absolute top-50 transform-y-50 box-sizing-border">
          <span className="super-title-3" dangerouslySetInnerHTML={{ __html: slide.title }} />
          {slide.subtitle && (
            <span
              className="title-8 margin-y-16"
              dangerouslySetInnerHTML={{ __html: slide.subtitle }}
            />
          )}
          {/* Show description when is mobile size <= 768px */}
          {!isMobileSize && slide.description && (
            <span
              className="title-8 margin-bottom-30 max-line-5"
              dangerouslySetInnerHTML={{ __html: slide.description }}
            />
          )}
          {slide.button_title && (
            <div className="align-self-center">
              <Button
                testID="slide__button"
                type="primary"
                size={isMobileSize ? "large" : "small"}
                isUppercase
                label={slide.button_title ?? ""}
                onClick={() => console.log("Sign up")}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
