import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";
import {
  APIMemberData,
  APIRequest,
  APISubscriptionData,
} from "@companion-core/shared/app/Interfaces/API";

interface MemberState {
  data: APIMemberData | null;
  subscriptions: APISubscriptionData[] | null;
  requests: APIRequest[] | null;
  lastConnectionDate: string | null;
}

interface MemberActions {
  setMemberData: (member: APIMemberData | null) => void;
  setMemberRequests: (requests: APIRequest[] | null) => void;
  setSubscriptions: (subscriptions: APISubscriptionData[] | null) => void;
  resetMember: () => void;
}

export const initialState: MemberState = {
  data: null,
  subscriptions: null,
  requests: null,
  lastConnectionDate: null,
};

export const useMemberStore = createWithEqualityFn<MemberState & MemberActions>()(
  (set) => ({
    ...initialState,
    setMemberData: (member: APIMemberData | null) => set({ data: member }),
    setMemberRequests: (requests: APIRequest[] | null) => set({ requests }),
    setSubscriptions: (subscriptions: APISubscriptionData[] | null) => set({ subscriptions }),
    resetMember: () => set(initialState),
  }),
  shallow,
);
