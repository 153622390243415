import React from "react";
import { PopInProps } from "./index";
import { Button } from "@companion-core/shared/app/Components/Button";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import "@companion-core/web/src/Assets/Styles/scss/popIn.scss";

export const PopIn = ({
  testID,
  icon,
  title,
  content,
  primaryButton,
  secondaryButton,
  onClose,
  freeze,
}: PopInProps) => {
  return (
    <>
      <div className="popin__overlay" onClick={freeze ? undefined : onClose} />
      <div className="popin__container" data-testid={testID}>
        {icon && <FontIcon className="popin__icon" name={icon} size={24} />}
        <div className="popin__texts">
          {title && <div className="body-text-5">{title}</div>}
          {content && <div className="body-text-6">{content}</div>}
        </div>
        <div className="popin__buttons">
          {secondaryButton && (
            <Button
              testID="popin_secondary_button"
              type="secondary"
              classNames={["popin__buttons__button"]}
              label={secondaryButton.text}
              onClick={secondaryButton.onClick}
            />
          )}
          {primaryButton && <Button
            testID="popin_primary_button"
            classNames={["popin__buttons__button"]}
            label={primaryButton.text}
            onClick={primaryButton.onClick}
            disabled={freeze ? true : false}
          />}
        </div>
      </div>
    </>
  );
};
