import React, { useEffect, useRef, useState } from "react";
import { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import { TimePicker, Form } from "antd";
import getValidation from "@companion-core/shared/app/Utils/validators";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import InputLabel from "@companion-core/web/src/Components/InputLabel";
import { JPInputTimeProps } from "./index";
import "@companion-core/web/src/Assets/Styles/scss/input.scss";
import { getLang } from "@companion-core/web/src/Utilities/lang";
import {
  is12HourFormat,
  loadLocale,
  localizedStringTotime,
  timeToLocalizedString,
} from "@companion-core/shared/app/Utils/datetime";
import { JPInputError } from "@companion-core/shared/app/Components/Inputs/JPInputError";
import { useI18nStore } from "@companion-core/shared/app/Store/i18nStore";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
dayjs.extend(localizedFormat);
dayjs.extend(utc);

export const JPInputTime: React.FC<JPInputTimeProps> = ({
  name,
  required = false,
  value,
  label,
  icon,
  validators,
  testID,
  payloadPath = name,
  disabled = false,
  setFieldErrors,
  registerValidation,
  onValueChange,
  ...props
}: JPInputTimeProps) => {
  const { t } = useTranslation();
  const form = Form.useFormInstance(); // Get current context form instance
  const [error, setError] = useState<string>("");
  const [inputValue, setInputValue] = useState<Dayjs | null>(
    value ? localizedStringTotime(value) : null,
  );
  const inputValueRef = useRef(inputValue); // Create a ref to store the latest value and avoid stale closure
  const [is24Hour, setIs24Hour] = useState(true);

  const { locale: lang } = useI18nStore((state) => ({ locale: state.locale }));

  useEffect(() => {
    registerValidation(name, () => {
      const inputValueString = inputValueRef.current
        ? timeToLocalizedString(inputValueRef.current)
        : "";
      validateInput(inputValueString);
    });
  }, []);

  useEffect(() => {
    inputValueRef.current = inputValue; // Update ref value
  }, [inputValue]);

  useEffect(() => {
    const lang = getLang();
    loadLocale(lang).then(() => setIs24Hour(!is12HourFormat()));
  }, [lang]);

  const handleInputChange = (time: Dayjs | null) => {
    const timeString = time ? timeToLocalizedString(time) : "";
    setInputValue(time);

    if (form && payloadPath) {
      form.setFieldsValue({
        [payloadPath]: timeString,
      });
    }
    if (onValueChange) {
      onValueChange(timeString);
    }

    validateInput(timeString); // Validate input on change because of changeOnBlur
  };

  const validateInput = (inputValue: string) => {
    let err = !inputValue && required ? "common.form.input.error.missing" : "";

    if (!err && validators?.length) {
      const { isValid, errorMessages } = getValidation(validators, inputValue);
      if (!isValid) err = errorMessages[0];
    }
    setError(err);
    setFieldErrors && setFieldErrors(err ? [err] : []);
  };

  return (
    <div className="JPInput">
      {label && <InputLabel label={label} icon={icon} />}
      <TimePicker
        {...props}
        status={error?.length ? "error" : ""}
        data-testid={testID}
        name={name}
        placeholder={!disabled ? t("common.form.input.placeholder.time") : ""}
        value={inputValue}
        showNow={false}
        use12Hours={!is24Hour}
        format={is24Hour ? "HH:mm" : "HH:mm A"}
        minuteStep={15}
        suffixIcon={false}
        allowClear={{
          clearIcon: (
            <FontIcon
              name="JP-Circle-close-outline"
              size={24}
              data-testid={`${testID}--clear`}
              onClick={() => setError("")}
            />
          ),
        }}
        disabled={disabled}
        onChange={handleInputChange}
        changeOnBlur={true}
      />
      {error && <JPInputError testID={testID} error={error} />}
    </div>
  );
};
