import React from "react";
import { useTranslation } from "react-i18next";
import { getOptions } from "@companion-core/shared/app/Utils/config";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import { Dropdown } from "antd";
import { AccountMenuDropdown } from "./AccountMenuDropdown";
import { AccountMenuItem } from ".";
import "@companion-core/web/src/Assets/Styles/scss/accountMenu.scss";

interface AccountLinks {
  name: string;
  icon: string;
}

export const AccountMenu = () => {
  const { t } = useTranslation();
  const { accountIcon, accountLinks } = getOptions();

  const items: AccountMenuItem[] = accountLinks.map((accountLink: AccountLinks) => {
    const { name, icon } = accountLink;
    return {
      name,
      label: (
        <span className="body-text-6" data-testid={`account-item-${name}`}>
          {t(`common.header.links.${name}`)}
        </span>
      ),
      icon: <FontIcon name={icon} size={24} />,
    };
  });

  return (
    <div className="account-menu" data-testid="account-menu">
      <Dropdown
        placement="bottomLeft"
        trigger={["click"]}
        dropdownRender={() => <AccountMenuDropdown items={items} />}>
        <FontIcon
          data-testid="account-menu__icon"
          className="account-menu__icon"
          name={accountIcon}
          size={24}
        />
      </Dropdown>
    </div>
  );
};
