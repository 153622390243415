import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMemberStore } from "@companion-core/shared/app/Store/memberStore";
import { TextInShape } from "@companion-core/shared/app/Components/TextInShape";
import { getConfig, getModule } from "@companion-core/shared/app/Utils/config";
import {
  CommunicationChannel,
  getInitialChannels,
} from "@companion-core/shared/app/Components/Bell/Home";
import { getPhoneNumberFromSubscriptions } from "@companion-core/shared/app/Utils/memberHelper";
import { useBellStore } from "@companion-core/shared/app/Store/bellStore";
import { trackClickBell, trackHomePageBell } from "@companion-core/shared/app/tracking/bell";
import "@companion-core/web/src/Assets/Styles/scss/bell.scss";

export const BellHome = () => {
  const { t } = useTranslation();
  const { member, subscriptions } = useMemberStore((state) => ({
    member: state.data,
    subscriptions: state.subscriptions,
  }));
  const homeEntries = getModule("concierge").modules.bell.options.home.channels;
  const whatsAppLink = getConfig().options.conciergeWhatsAppLink;
  const { setBellContent } = useBellStore((state) => ({ setBellContent: state.setBellContent }));
  const ask = () => {
    trackClickBell("Make_A_Request");
    setBellContent("categories");
  };
  const call = () => {
    trackClickBell("Concierge_call_request");
    const phone = channels.find((channel) => channel.name === "call")?.phoneNumber;
    window.open(`tel:${phone}`);
  };
  const whatsApp = () => {
    trackClickBell("Concierge_whatsapp_request");
    window.open(whatsAppLink);
  };
  const initialChannels: CommunicationChannel[] = getInitialChannels(
    homeEntries,
    ask,
    call,
    whatsApp,
  );
  const [channels, setChannels] = useState<CommunicationChannel[]>(initialChannels);
  const enabledChannels: CommunicationChannel[] = channels.filter((channel) => channel.enabled);

  useEffect(() => {
    trackHomePageBell();
  }, []);

  useEffect(() => {
    if (subscriptions?.length) {
      const phoneNumber = getPhoneNumberFromSubscriptions(subscriptions);
      const newChannels: CommunicationChannel[] = channels.map((channel) => {
        if (channel.name === "call" && homeEntries?.phone.enabled && phoneNumber) {
          channel.enabled = true;
          channel.phoneNumber = phoneNumber;
        }
        return channel;
      });
      setChannels(newChannels);
    }
  }, [subscriptions]);

  return (
    <div data-testid="bell__home" className="bell__home">
      <span className="bell__home-title title-1">
        {t("requestV2.concierge.home.title", { firstName: member?.firstname ?? "" })}
      </span>
      <span className="bell__home-subtitle title-6">{t("request.concierge.home.subtitle")}</span>
      <div className="bell__channels">
        {enabledChannels.map((channel) => (
          <TextInShape
            key={channel.name}
            testID={`bell__channel-button--${channel.name}`}
            shape="stretched"
            text={t(`request.concierge.home.${channel.name}.label`)}
            icon={channel.icon}
            value={channel.name}
            onClick={channel.callback}
          />
        ))}
      </div>
    </div>
  );
};
