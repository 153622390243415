import { getCurrency, getOptions } from "@companion-core/shared/app/Utils/config";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import { getSummaryValue } from "@companion-core/shared/app/Components/Inputs/JPInputCounters";
import { APIRequest } from "@companion-core/shared/app/Interfaces/API";
import { accessibleRequestFields, serviceIcons } from "@companion-core/shared/app/Constants/myRequests";
import { Field } from "@companion-core/shared/app/Interfaces/wizard";
dayjs.extend(localizedFormat);
dayjs.extend(utc);

export const getFamilyIcon = (family: string, subfamily?: string): string => {
  if (!serviceIcons[family]) return "JP-Concierge-bell";
  if (family === "transportation" && subfamily === "car-rental") return "JP-Taxi";
  return serviceIcons[family];
};

export const createSortedSummaryFields = (data: APIRequest): Field[] => {
  const summary: Field[] = [];
  const summaryFields: Record<string, unknown> = {};
  const includeStartOrEndLocation =
    Object.keys(data).includes("start_location") || Object.keys(data).includes("end_location");

  // Create an object with sorted fields
  Object.keys(accessibleRequestFields).forEach((key) => summaryFields[key] = null)

  // Fill your pre-sorted Object
  Object.entries(data).map(([key, value]): void => {
    const family = data.family;
    if (key === "subfamily" && !["transportation", "daily-services"].includes(family)) return;
    if (key === "location" && includeStartOrEndLocation) return; // skip location if start or end location is present
    if (accessibleRequestFields[key] && value) {
      summaryFields[key] = {
        name: key,
        label: `request.my-requests.summary.${
          key === "start_date" && !data["end_date"] ? "date" : key
        }.label`,
        icon: accessibleRequestFields[key],
        required: true,
        testID: `request-detail__summary-field-${key}`,
        type: "text",
        summaryValue: formatRequestSummaryField(key, value, data.family, data.subfamily),
      };
    }
  });

  // Convert your sorted object to an array for the Summary component
  Object.values(summaryFields).forEach((value) => {
    if (value) summary.push(value as Field)
  });

  return summary;
};

export const formatRequestSummaryField = (
  name: string,
  value: string | number | Record<string, unknown>,
  family: string,
  subfamily: string | undefined
) => {
  const { t } = useTranslation();

  const formatLocation = (location: Record<string, unknown>): string => {
    const city = location.city as string;
    const zipCode = location["zip_code"] ? `${location["zip_code"]}, ` : "";
    const street =
      location.street && !location.street.toString().includes("null") ? `${location.street}, ` : "";
    return `${street}${zipCode}${city}`;
  };

  // TODO: Change this once date and time fields are splitted
  const formatDateTime = (date: string): string => {
    const { familyWithHourDisplayed } = getOptions();
    let format = "L";

    if (Object.keys(familyWithHourDisplayed).includes(family)
      && (!familyWithHourDisplayed[family]
        || (familyWithHourDisplayed[family]
          && subfamily
          && familyWithHourDisplayed[family].includes(subfamily)
    ))) {
      format = `L [${t("request.my-requests.time.particle")}] LT`;
    } 

    return dayjs(date).utc().format(format);
  }

  const formattedData: Record<string, string> = {
    "additional_information_digital": value as string,
    "location": formatLocation(value as Record<string, string>),
    "start_location": formatLocation(value as Record<string, string>),
    "end_location": formatLocation(value as Record<string, string>),
    "start_date": formatDateTime(value as string),
    "end_date": formatDateTime(value as string),
    "participants": getSummaryValue(value as Record<string, number>, t),
    "max_budget": getCurrency().template.replace("{val}", (value as number) / 100),
    "description": value as string,
    "number_of_rooms": value as string,
    "category_class": t(`request.concierge.form.car-rental.category.${value}.label`),
    "start_time": value as string,
    "end_time": value as string,
  };
  return formattedData[name];
};
