import React from "react";
import { Carousel as CarouselAntd } from "antd";
import { CustomArrowProps } from "@ant-design/react-slick";
import { CarouselProps } from "@companion-core/shared/app/Components/Carousel/Carousel.model";
import { useMediaQuery } from "@companion-core/web/src/Utilities/mediaQuery";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import "@companion-core/web/src/Assets/Styles/scss/carousel.scss";

export const Carousel = ({ children, autoplay = true, duration = 8000, testID }: CarouselProps) => {
  const isMobileSize = useMediaQuery("(max-width: 768px)");

  const PrevArrow = ({ currentSlide, slideCount, ...props }: CustomArrowProps) => (
    <div {...props}>
      <div data-testid="carousel__left-arrow" className="circle">
        <FontIcon name="JP-Arrow-left-bold" size={16} />
      </div>
    </div>
  );

  const NextArrow = ({ currentSlide, slideCount, ...props }: CustomArrowProps) => (
    <div {...props}>
      <div data-testid="carousel__right-arrow" className="circle">
        <FontIcon name="JP-Arrow-right-bold" size={16} />
      </div>
    </div>
  );

  return (
    <div data-testid={testID}>
      <CarouselAntd
        autoplay={autoplay}
        autoplaySpeed={duration}
        arrows={!isMobileSize}
        prevArrow={<PrevArrow />}
        nextArrow={<NextArrow />}
        effect="scrollx">
        {children}
      </CarouselAntd>
    </div>
  );
};
