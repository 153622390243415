import instance from "@johnpaul/jp-api-client";
import { InternalAxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { NavigateFunction } from "react-router-dom";
import { getClientCredentials } from "@companion-core/shared/app/Utils/config";
import { useAuthStore } from "@companion-core/shared/app/Store/authStore";
import { isWeb } from "@companion-core/shared/app/Utils/platform";
import { useConfigStore } from "@companion-core/shared/app/Store/configStore";

const handleResponseUnauthorizedError = async (
  navigate: NavigateFunction | (() => void),
  error: AxiosError,
): Promise<never> => {
  if (!error?.config?.headers?.["doNotForceExit"]) {
    if (
      useAuthStore.getState().authenticated &&
      !error?.response.config.url?.includes("/auth/revoke")
    ) {
      await useAuthStore.getState().logout();
    }
    // Mobile navigation will automatically redirect to the correct screen
    // When the store value "authenticated" is updated, the conditionnal rendering will do the rest
    if (isWeb()) {
      useConfigStore.getState().config?.modules.sso.enabled ? navigate("/sso/no-rights") : navigate("login");
    }
  }
  return Promise.reject(error);
};

const handleResponseNotFoundError = async (
  navigate: NavigateFunction | (() => void),
  error: AxiosError,
): Promise<never> => {
  navigate(isWeb() ? "not-found" : "NotFound");
  return Promise.reject(error);
};

const handleResponseServerError = async (
  navigate: NavigateFunction | (() => void),
  error: AxiosError,
): Promise<never> => {
  navigate(isWeb() ? "error" : "ServerError");
  return Promise.reject(error);
};

const isSSOTokenRoute = (url: string) => !!url?.includes("/auth/token/sso");

export function setXClientInterceptors() {
  instance.interceptors.request.use((request: InternalAxiosRequestConfig) => {
    request.headers["x-client"] = `Basic ${btoa(
      `${getClientCredentials().clientId}:${getClientCredentials().clientSecret}`,
    )}`;
    return request;
  });
}

export function setInterceptors(navigate: NavigateFunction | (() => void)): void {
  instance.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
      switch (error?.response?.status) {
        case 401:
          return handleResponseUnauthorizedError(navigate, error);
        case 403:
          return !isSSOTokenRoute(error?.response.config.url)
            ? handleResponseUnauthorizedError(navigate, error)
            : Promise.reject(error);
        case 404:
          return handleResponseNotFoundError(navigate, error);
        case 500:
        case 503:
          return !isSSOTokenRoute(error?.response.config.url)
            ? handleResponseServerError(navigate, error)
            : Promise.reject(error);
        default:
          throw error;
      }
    },
  );

  useConfigStore.getState().setInterceptorLoaded(true);
}
