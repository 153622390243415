import React, { useEffect, useRef, useState } from "react";
import { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import { DatePicker, Form } from "antd";
import getValidation from "@companion-core/shared/app/Utils/validators";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import InputLabel from "@companion-core/web/src/Components/InputLabel";
import { JPInputDateProps } from "./index";
import "@companion-core/web/src/Assets/Styles/scss/input.scss";
import { getLang } from "@companion-core/web/src/Utilities/lang";
import {
  dateToLocalizedString,
  getLocalizedDateFormat,
  isBetweenDates,
  loadLocale,
  localizedStringToDate,
} from "@companion-core/shared/app/Utils/datetime";
import { JPInputError } from "@companion-core/shared/app/Components/Inputs/JPInputError";
import { useI18nStore } from "@companion-core/shared/app/Store/i18nStore";

/**
 * Primary UI component for user interaction
 * @return {Component} Input component
 */
export const JPInputDate: React.FC<JPInputDateProps> = ({
  name,
  required = false,
  value,
  minimumDate = "",
  maximumDate = "",
  label,
  icon,
  validators,
  testID,
  payloadPath = name,
  disabled = false,
  setFieldErrors,
  onValueChange,
  registerValidation,
  ...props
}: JPInputDateProps) => {
  const { t } = useTranslation();
  const form = Form.useFormInstance(); // Get current context form instance
  const [error, setError] = useState<string>("");
  const [inputValue, setInputValue] = useState<Dayjs | null>(
    value ? localizedStringToDate(value) : null,
  );
  const inputValueRef = useRef(inputValue); // Create a ref to store the latest value and avoid stale closure

  const [localizedFormat, setLocalizedFormat] = useState<string>("MM/DD/YYYY");

  const { locale: lang } = useI18nStore((state) => ({ locale: state.locale }));

  useEffect(() => {
    registerValidation(name, validateInput);
  }, []);

  useEffect(() => {
    inputValueRef.current = inputValue; // Update ref value
  }, [inputValue]);

  useEffect(() => {
    const lang = getLang();
    loadLocale(lang).then(() => setLocalizedFormat(getLocalizedDateFormat()));
  }, [lang]);

  const handleInputChange = (date: Dayjs | null) => {
    const dateString = date ? dateToLocalizedString(date) : "";
    setInputValue(date);

    if (form && payloadPath) {
      form.setFieldsValue({
        [payloadPath]: dateString,
      });
    }
    if (onValueChange) {
      onValueChange(dateString);
    }
  };

  const validateInput = () => {
    const inputValueString = inputValueRef.current
      ? dateToLocalizedString(inputValueRef.current)
      : "";
    let err = !inputValueString && required ? "common.form.input.error.missing" : "";

    if (!err && validators?.length) {
      const { isValid, errorMessages } = getValidation(validators, inputValueString);
      if (!isValid) err = errorMessages[0];
    }
    setError(err);
    setFieldErrors && setFieldErrors(err ? [err] : []);
  };

  return (
    <div className="JPInput">
      {label && <InputLabel label={label} icon={icon} />}
      <DatePicker
        {...props}
        status={error?.length ? "error" : ""}
        data-testid={testID}
        name={name}
        picker={"date"}
        placeholder={!disabled ? t("common.form.input.placeholder.date") : ""}
        showTime={false}
        value={inputValue}
        disabledDate={(d) => isBetweenDates(d, minimumDate, maximumDate)}
        format={localizedFormat}
        suffixIcon={false}
        showToday={false}
        clearIcon={
          <FontIcon
            name="JP-Circle-close-outline"
            size={24}
            data-testid={`${testID}--clear`}
            onClick={() => setError("")}
          />
        }
        disabled={disabled}
        onChange={handleInputChange}
        onBlur={validateInput}
      />
      {error && <JPInputError testID={testID} error={error} />}
    </div>
  );
};
