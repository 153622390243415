import React, { useState, useEffect } from "react";
import { getLocales } from "@companion-core/shared/app/Utils/config";
import { useTranslation } from "react-i18next";
import { loadLanguageAsync } from "@companion-core/shared/app/I18n";
import { JPInputRadioButton } from "@companion-core/shared/app/Components/Inputs/JPInputRadioButton";
import { Button } from "@companion-core/shared/app/Components/Button";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import { useI18nStore } from "@companion-core/shared/app/Store/i18nStore";
import "@companion-core/web/src/Assets/Styles/scss/languageSwitcher.scss";
import { getAntdLocale } from "@companion-core/web/src/Utilities/lang";

export const LanguageSelector = () => {
  const { i18n, t } = useTranslation();
  const { didChange, setDidChange, setAntdLocale } = useI18nStore();
  const formattedLanguages = getLocales().map((language: string) => ({
    value: language,
    text: t(`common.languages.${language}`),
  }));
  const [defaultValue, setDefaultValue] = useState<string>(i18n.language);
  const [isValidated, setIsValidated] = useState<boolean>(false);

  const changeLanguage = async (lang: string) => {
    if (lang) {
      setDidChange(true);
      await loadLanguageAsync(lang);
      setAntdLocale(getAntdLocale(lang));
    }
  };

  useEffect(() => {
    if (didChange) {
      setIsValidated(didChange);
      setDidChange(!didChange);
    }
  }, []);

  return (
    <div data-testid="language-switcher-mobile">
      <div className="title-5 language-switcher-mobile__title">
        {t("common.language-switcher.title")}
      </div>
      {isValidated && (
        <div
          className="body-text-9 language-switcher-mobile__validation"
          data-testid="language-switcher-mobile__validation">
          <FontIcon
            name="JP-Circle-check"
            size={16}
            className="language-switcher-mobile__validation-icon"
          />
          {t("common.language-switcher.validation")}
        </div>
      )}
      <div className="body-text-6 language-switcher-mobile__subtitle">
        {t("common.language-switcher.subtitle")}
      </div>
      <div className="language-switcher-mobile__languages">
        <JPInputRadioButton
          name="JPInputRadioButton-language-switcher"
          options={formattedLanguages}
          value={defaultValue}
          testID="JPInputSelect__language-switcher-mobile"
          onValueChange={(value) => setDefaultValue(value)}
        />
      </div>
      <Button
        label={t("common.language-switcher.button")}
        disabled={i18n.language === defaultValue}
        onClick={() => changeLanguage(defaultValue)}
        classNames={["language-switcher-mobile__button"]}
        testID="JPInputSelect__language-switcher-mobile-button"
      />
    </div>
  );
};
