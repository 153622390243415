import React from "react";
import { Button as ButtonAnt } from "antd";
import { ButtonProps } from "./index";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import "@companion-core/web/src/Assets/Styles/scss/button.scss";

export const Button = ({
  testID,
  type = "primary",
  negative = false,
  htmlType = "button",
  size = "default",
  disabled = false,
  label,
  icon,
  loading,
  isUppercase = false,
  classNames = [""],
  onClick,
}: ButtonProps) => {
  return (
    <ButtonAnt
      data-testid={testID}
      htmlType={htmlType}
      disabled={disabled}
      className={[
        ...classNames,
        "button body-text-5",
        `button--${type}`,
        negative &&`button--${type}-negative`,
        size && `button--${size}`,
        isUppercase && "button--uppercase",
      ].join(" ")}
      loading={loading}
      onClick={onClick}>
      {icon && <FontIcon name={icon} className="button__icon" />}
      <span>{label}</span>
    </ButtonAnt>
  );
};
