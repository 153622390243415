import React from "react";
import { useNavigate } from "react-router-dom";
import { SelectionLayoutProps } from "@companion-core/shared/app/Interfaces/Blocks";
import { Button } from "@companion-core/shared/app/Components/Button";
import { ComponentCard } from "@companion-core/shared/app/Components/Cards/ComponentCard";
import { useMediaQuery } from "@companion-core/web/src/Utilities/mediaQuery";
import { redirectTo } from "@companion-core/web/src/Utilities/navigation";
import "@companion-core/web/src/Assets/Styles/scss/selectionCommonLayout.scss";

export const SelectionCommonLayout = ({ text, cardList, button }: SelectionLayoutProps) => {
  const navigate = useNavigate();
  const isMobileSize = useMediaQuery("(max-width: 768px)");

  return (
    isMobileSize && (
      <section className="selection-common-layout" data-testid="selection-common-layout">
        {text && (
          <h2
            data-testid="selection-common-layout__title"
            className="selection-common-layout__title title-1">
            {text.title}
          </h2>
        )}
        <div
          data-testid="selection-common-layout__cards"
          className={`selection-common-layout__cards ${
            cardList.length > 1 ? "selection-common-layout__cards--multiple" : ""
          }`}>
          {cardList?.map((component) => {
            return (
              <ComponentCard
                key={`selection-common-layout-card-${component.id}`}
                component={component}
              />
            );
          })}
        </div>
        {button && button.button_title && (
          <Button
            testID="selection-common-layout__cta"
            classNames={["selection-common-layout__cta"]}
            label={button.button_title}
            size="large"
            onClick={() =>
              redirectTo(button.button_link ?? "/", button?.button_type ?? "internal", navigate)
            }
          />
        )}
      </section>
    )
  );
};
