import React from "react";
import { BannerProps } from "./index";
import { useMediaQuery } from "@companion-core/web/src/Utilities/mediaQuery";
import "Assets/Styles/scss/banner.scss";

export const Banner = ({
  title = "",
  imageUrl,
  boxShadow = false,
  overlap = true,
  disapearOnMobileSize = true,
}: BannerProps) => {
  const isMobileSize = useMediaQuery("(max-width: 768px)");

  return (
    <div data-testid="banner" className={`banner${overlap ? "" : " banner--no-overlap"}`}>
      {isMobileSize && disapearOnMobileSize && (
        <h2 data-testid="banner__title" className="banner__title title-6">
          {title}
        </h2>
      )}
      {((isMobileSize && !disapearOnMobileSize) || !isMobileSize) && (
        <div
          data-testid="banner__content"
          className="banner__content super-title-1"
          style={{ backgroundImage: `url("${imageUrl}")` }}>
          {boxShadow && <div className="banner__shadowed-background" />}
          <h2 data-testid="banner__title" className="banner__title super-title-1">
            {title}
          </h2>
        </div>
      )}
    </div>
  );
};
