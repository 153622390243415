import * as Sentry from "@sentry/react";

export const initSentry = () => {
  const { REACT_APP_ENVIRONMENT, REACT_APP_SENTRY_DSN, REACT_APP_SENTRY_ORIGIN } = process.env;
  const clientName = process.env.REACT_APP_CLIENT_NAME || "johnpaul";
  Sentry.init({
    environment: REACT_APP_ENVIRONMENT,
    dsn: REACT_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [new RegExp(REACT_APP_SENTRY_ORIGIN || "localhost")],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  Sentry.setContext("companion_client", { name: `companion-react-${clientName}` })
};
