import React, { ReactElement, useMemo } from "react";
import { BlockProps, SelectionLayoutProps } from "@companion-core/shared/app/Interfaces/Blocks";
import { ComponentData } from "@companion-core/shared/app/Interfaces/API";
import { SelectionMosaicLayout } from "@companion-core/shared/app/Components/Blocks/Layouts/SelectionMosaicLayout";
import { SelectionPushLayout } from "@companion-core/shared/app/Components/Blocks/Layouts/SelectionPushLayout";
import {
  getComponentButton,
  getComponentText,
  getSortedByOrderComponents,
  sortByOrder,
  TYPE_BUTTON,
  TYPE_TEXT,
} from "@companion-core/shared/app/Utils/componentHelper";

const SelectionLayouts: {
  [key: string]: ({ text, button, cardList, blockId }: SelectionLayoutProps) => ReactElement;
} = {
  "selection-push": SelectionPushLayout,
  "selection-mosaic": SelectionMosaicLayout,
};

export const Selection = ({ simpleBlockItems, contentProps }: BlockProps) => {
  const simpleComponents: ComponentData[] = getSortedByOrderComponents(simpleBlockItems);

  const text: ComponentData | undefined = useMemo(
    () => getComponentText(simpleComponents),
    [simpleComponents],
  );
  const button: ComponentData | undefined = useMemo(
    () => getComponentButton(simpleComponents),
    [simpleComponents],
  );
  const cardList: ComponentData[] = useMemo(
    () =>
      simpleComponents
        .filter(
          (component: ComponentData) =>
            component.type && component.type !== TYPE_TEXT && component.type !== TYPE_BUTTON,
        )
        .sort(sortByOrder),
    [simpleComponents],
  );

  const { displayType } = contentProps;

  if (!SelectionLayouts[displayType]) {
    return <></>;
  }

  const Layout = SelectionLayouts[displayType];
  return <Layout text={text} button={button} cardList={cardList} blockId={contentProps.id} />;
};
