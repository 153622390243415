import React from "react";
import { ContentCardProps } from "@companion-core/shared/app/Components/Cards/ContentCard/ContentCard.model";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import { ImageCard } from "@companion-core/shared/app/Components/Cards/ImageCard/ImageCard";
import "Assets/Styles/scss/contentCard.scss";

export const ContentCard = ({
  testID,
  title,
  subtitle,
  imageUrl,
  logo,
  date,
}: ContentCardProps) => {
  return (
    <div data-testid={testID} className="content-card">
      <ImageCard
        testID={`${testID}__image-card`}
        className="content-card__content"
        imageUrl={imageUrl}>
        <div className="content-card__pellet body-text-11">
          <FontIcon name="JP-Clock" size={10} />
          <span data-testid={`${testID}__pellet-text`} className="content-card__pellet-text">
            {date}
          </span>
        </div>
        <div className="content-card__header">
          <div className="content-card__information">
            <span data-testid={`${testID}__title`} className="content-card__title body-text-5">
              {title}
            </span>
            <span
              data-testid={`${testID}__subtitle`}
              className="content-card__subtitle body-text-6">
              {subtitle}
            </span>
          </div>
          {logo && (
            <div data-testid={`${testID}__logo`} className="content-card__logo">
              <img src={logo} alt="Card logo" />
            </div>
          )}
        </div>
      </ImageCard>
    </div>
  );
};
