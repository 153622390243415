import React, { useMemo } from "react";
import { Route, Routes, Navigate, useSearchParams } from "react-router-dom";
import { getHomeRoutes } from "@companion-core/shared/app/Utils/config";
import { useAuthStore } from "@companion-core/shared/app/Store/authStore";

// Components
import { DynamicPage } from "@companion-core/shared/app/Components/DynamicPage/DynamicPage";
import { RequestList } from "@companion-core/shared/app/Components/Requests";
import { DynamicSection } from "@companion-core/shared/app/Components/MyAccount/DynamicSection";
import AuthGuard from "Navigation/Guards/AuthGuard";
import SSOGuard from "Navigation/Guards/SSOGuard";
import Login from "Screens/Login";
import RecoverPassword from "Screens/RecoverPassword";
import ResetPassword from "Screens/ResetPassword";
import Register from "Screens/Register/Register";
import RegisterWithoutEmail from "Screens/Register/RegisterWithoutEmail";
import RegisterActivation from "Screens/Register/Activation";
import Requests from "Screens/Requests/Requests";
import Detail from "Screens/Requests/Detail";
import MyAccount from "Screens/MyAccount/MyAccount";
import { NotFound } from "Screens/Errors/NotFound";
import { ServerError } from "Screens/Errors/ServerError";
import { NoRights } from "Screens/Errors/SSO/NoRights";
import SSOClient from "Screens/SSO/SSOClient";
import { TechnicalError } from "../Screens/Errors/SSO/TechnicalError";
import { hasCodeQueryParam } from "Utilities/navigation";
import { NeedHelp } from "@companion-core/shared/app/Components/NeedHelp";
import { ContentDetailsPage } from "@companion-core/shared/app/Components/ContentDetailsPage";
import { ConfigGuard } from "./Guards/ConfigGuard";

const Navigation = () => {
  const authenticated = useAuthStore.getState().authenticated;
  const { logged: homeRouteLogged, unlogged: homeRouteUnlogged } = getHomeRoutes();
  const homePage = authenticated ? homeRouteLogged : homeRouteUnlogged;
  const [searchParams] = useSearchParams();
  const isSSOCompanionWithCodeParam = useMemo(
    () => hasCodeQueryParam(searchParams),
    [searchParams],
  );

  return (
    <Routes>
      <Route element={<ConfigGuard />}>
        <Route element={isSSOCompanionWithCodeParam ? <SSOClient /> : undefined}>
          <Route path="/need-help" element={<NeedHelp />} />
          <Route path="/" element={<Navigate to={`/${homePage}`} />} />
          <Route element={<AuthGuard />}>
            <Route
              path="/:origin/details/:id"
              element={
                <ContentDetailsPage googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} />
              }
            />
            <Route id="" path="/:slug" element={<DynamicPage />} />
            <Route path="/my-account" element={<MyAccount />}>
              <Route path=":section" element={<DynamicSection />} />
            </Route>
            <Route path="/my-requests" element={<Requests />}>
              <Route index element={<RequestList />} />
              <Route path="past" element={<RequestList initialTabIndex="1" />} />
              <Route path=":id" element={<Detail />} />
            </Route>
          </Route>
        </Route>

        <Route element={<AuthGuard publicOnly />}>
          <Route path="/login" element={<Login />} />
          <Route path="/register">
            <Route index path="member" element={<Register />} />
            <Route path="email" element={<RegisterWithoutEmail />} />
            <Route path="activation" element={<RegisterActivation />} />
          </Route>
          <Route path="/password">
            <Route path="recover" element={<RecoverPassword />} />
            <Route path="reset">
              <Route path=":token" element={<ResetPassword />} />
            </Route>
          </Route>
        </Route>

        <Route element={<SSOGuard onlyAvailableForSSOCompanion />}>
          <Route path="/sso">
            <Route index path="no-rights" element={<NoRights />} />
            <Route path="error" element={<TechnicalError />} />
          </Route>
        </Route>
        <Route path="/not-found" element={<NotFound />} />
        <Route path="/error" element={<ServerError />} />
        <Route path="*" element={<Navigate to="/not-found" />} />
      </Route>
    </Routes>
  );
};

export default Navigation;
