import React from "react";
import { useTranslation } from "react-i18next";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import { CategoryHeaderProps } from "@companion-core/shared/app/Components/Bell/CategoryHeader";
import "@companion-core/web/src/Assets/Styles/scss/categoryHeader.scss";

export const CategoryHeader = ({ category }: CategoryHeaderProps) => {
  const { t } = useTranslation();

  return (
    <div data-testid="category__header" className="category__header">
      <div className="category__header-title body-text-3">
        {t(`request.concierge.form.subject.${category?.name}`)}
      </div>
      <div className="category__header-icon">
        <FontIcon name={category?.icon} size={24} />
      </div>
    </div>
  );
};
