import argon2 from "argon2-browser/dist/argon2-bundled.min";

export async function hashPassword(
  password: string,
  salt: string,
  iterations: number,
  memory: number,
  parallelism: number,
  hashLength: number) {
  try {
    const { encoded } = await argon2.hash({
      hashLen: hashLength,
      pass: password,
      salt: Uint8Array.from(atob(salt), c => c.charCodeAt(0)),
      time: iterations,
      mem: memory,
      parallelism,
      type: argon2.ArgonType.Argon2i,
    });
    return `argon2${encoded}`;
  } catch (error) {
    return password;
  }
}