import { useQuery } from "@tanstack/react-query";
import { client } from "@johnpaul/jp-api-client";
import { APIContentComponent, JPClientRequest } from "@companion-core/shared/app/Interfaces/API";

type DetailPageParams = {
  parentID: string,
  lang: string,
}

export const useDetailPage = ({ parentID, lang }: DetailPageParams) => {
  return useQuery({
    queryKey: ["my-requests", parentID, lang],
    queryFn: () => client.content.v2.components.get(null, { "parent_id": parentID, lang, state: "published,scheduled,draft" }),
    select: (data: JPClientRequest<APIContentComponent>) => data.data.results.length > 0 ? data.data.results[0] : null,
    cacheTime: 0,
  });
};