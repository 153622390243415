import { setTrackCallback, setUserCallback } from "@companion-core/shared/app/tracking";
import { Page, Click, User } from "@companion-core/shared/app/Interfaces/tracking";
import { setCustomVars } from "@companion-core/shared/app/tracking/customVars";

interface Datalayer extends Page, Click, User {
  event: string,
}
declare global {
  interface Window {
    dataLayer: Datalayer[];
  }
}

export function initTracking() {
  // TO DO : set callback only if user accept cookies rules
  setTrackCallback((event: string, params: Page | Click) => {
    window?.dataLayer.push({
      event,
      ...params,
    });
  });
  setUserCallback((userId: string, subscriptionsId: string, userCategory = "", storeId = false) => {
    // eslint-disable-next-line camelcase
    setCustomVars({ contact_UUID: userId, subscription_UUID: subscriptionsId });
    window?.dataLayer.push({
      event: "setUser",
      userId,
      userCategory,
      storeId,
    });
  });
}
