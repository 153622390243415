import { useConfigStore } from "@companion-core/shared/app/Store/configStore";
import { getPlatform } from "./platform";
import { fetchConfig } from "./apiClient";
import configFailure from "@companion-core/shared/app/Assets/config/configFailure.json";
import { useAuthStore } from "@companion-core/shared/app/Store/authStore";

export interface ApiEnvironment {
  configFileName?: string;
  configUnloggedFileName?: string;
  apiUrl?: string;
  clientId?: string;
  clientSecret?: string;
  i18nUrl?: string;
  pianoAnalytics?: {
    site?: string;
    collectDomain?: string;
  };
}

let appEnvironment: string;
let env: ApiEnvironment | null = null;

export function setAppEnvironment(environment: string | undefined, apiEnv: ApiEnvironment) {
  appEnvironment = environment ?? "development";
  env = apiEnv;
}

export function getAppEnv() {
  return appEnvironment;
}

export function getEnv() {
  return env;
}

export function getConfig() {
  return useConfigStore.getState().config;
}

export function getConfigFileName() {
  const logged = useAuthStore.getState().authenticated;
  return logged ? env?.configFileName : env?.configUnloggedFileName;
}

export function getFooterConfig() {
  return useConfigStore.getState().config?.global.footer;
}

export function getModule(module: string) {
  return useConfigStore.getState().config?.modules[module];
}

export function getClientCredentials() {
  return {
    clientId: env?.clientId,
    clientSecret: env?.clientSecret,
  };
}

export function getApiURL() {
  return env?.apiUrl;
}

export function getI18nUrl() {
  return env?.i18nUrl;
}

export function getOptions() {
  return useConfigStore.getState().config?.options;
}

export function getCurrency() {
  return getOptions()?.currency;
}

export function getHomeRoutes() {
  const platform = getPlatform() || "web";
  return getOptions()?.homeRoutes[platform] ?? {};
}

export function getLocales() {
  return getOptions()?.locales;
}

export async function refetchConfig() {
  const configFileName = getConfigFileName();
  if (!configFileName) return;
  const response = await fetchConfig(configFileName);
  if (response.success) {
    useConfigStore.getState().setConfig(response.config);
    useConfigStore.getState().setFailure(false);
  } else {
    useConfigStore.getState().setConfig(configFailure);
    useConfigStore.getState().setFailure(true);
  }
}
