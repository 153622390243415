import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { getModule } from "@companion-core/shared/app/Utils/config";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import { Button } from "@companion-core/shared/app/Components/Button";
import { EventLink } from "@companion-core/shared/app/Components/EventLink";

export const TechnicalError = () => {
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const errorCode = useMemo(() => searchParams.get("errorCode"), [searchParams]);
  const ssoModule = getModule("sso");
  const ssoContact = ssoModule?.options.contact;
  const shouldDisplayDetail =
    i18n.exists(`common.error.sso.technical-error.detail.${errorCode}`) ||
    i18n.exists("common.error.sso.technical-error.detail.default");
  const partnerLink = ssoModule?.options.partnerLink;

  return (
    <div data-testid="sso__technical-error" className="error__container">
      <img
        data-testid="sso__technical-error--image"
        className="error__image"
        src="/assets/images/error/server_error.svg"
        alt="error image"
      />
      <h1 data-testid="sso__technical-error--title" className="body-text-3 error__title">
        {t("common.error.sso.technical-error.title")}
      </h1>
      <p
        data-testid="sso__technical-error--subtitle"
        className="error__sso-subtitle body-text-4 margin-top-24">
        {t("common.error.sso.technical-error.subtitle")}
      </p>
      <p
        data-testid="sso__technical-error--description"
        className="error__sso-description body-text-4 margin-top-24">
        {i18n.exists(`common.error.sso.technical-error.description.${errorCode}`)
          ? t(`common.error.sso.technical-error.description.${errorCode}`)
          : t("common.error.sso.technical-error.description.default")}
      </p>
      <div data-testid="sso__technical-error--info" className="error__sso-info">
        {ssoContact && (
          <div
            data-testid="sso__technical-error--contact"
            className="error__sso-contact body-text-5">
            <FontIcon
              data-testid="sso__technical-error--contact-icon"
              className="error__sso-contact-icon"
              name="JP-Message"
              size={24}
            />
            <p
              data-testid="sso__technical-error--contact-label"
              className="error__sso-contact-label">
              {ssoContact}
            </p>
          </div>
        )}
        {shouldDisplayDetail && (
          <div
            data-testid="sso__technical-error--info-detail"
            className="error__sso-info-detail body-text-5">
            <FontIcon
              data-testid="sso__technical-error--info-detail-icon"
              name="JP-Edit"
              size={24}
            />
            <p
              data-testid="sso__technical-error--info-detail-label"
              className="error__sso-info-detail-label">
              {i18n.exists(`common.error.sso.technical-error.detail.${errorCode}`)
                ? t(`common.error.sso.technical-error.detail.${errorCode}`)
                : t("common.error.sso.technical-error.detail.default")}
            </p>
          </div>
        )}
      </div>
      {partnerLink && (
        <EventLink name="sso__technical-error--partner-button" type="external" link={partnerLink}>
          <Button
            testID="sso__technical-error--partner-button"
            classNames={["error__sso-partner-button"]}
            label={t("common.error.sso.technical-error.partner-button")}
            size="large"
          />
        </EventLink>
      )}
    </div>
  );
};
