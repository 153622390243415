import { useAuthStore } from "@companion-core/shared/app/Store/authStore";
import { useBellStore } from "@companion-core/shared/app/Store/bellStore";
import { useAccountNavigationStore } from "@companion-core/shared/app/Store/accountNavigationStore";
import { useMemberStore } from "@companion-core/shared/app/Store/memberStore";
import { useWizardStore } from "@companion-core/shared/app/Store/wizardStore";
import { setUser } from "@companion-core/shared/app/tracking";

/**
 * Be sure to reset stores states and tracking information before Single Sign-On login.
 *
 * @returns {void}
 */
export const onBeforeSSOLogin = (): void => {
  setUser("", "", ""); // Reset User infos for tracking events
  useAuthStore.getState().resetAuth();
  useBellStore.getState().resetBell();
  useAccountNavigationStore.getState().resetAccountNavigation();
  useMemberStore.getState().resetMember();
  useWizardStore.getState().resetWizard();
};
