export function getPlatform(): "web" | "mobile" | undefined {
  if (typeof document !== "undefined") {
    return "web";
  } else if (typeof navigator !== "undefined" && navigator.product === "ReactNative") {
    return "mobile";
  }
}

export function isWeb(): boolean {
  return getPlatform() === "web";
}

export function isMobile(): boolean {
  return getPlatform() === "mobile";
}
