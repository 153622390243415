import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ComponentCardProps } from "@companion-core/shared/app/Components/Cards/ComponentCard/ComponentCard.model";
import { ContentCard } from "@companion-core/shared/app/Components/Cards/ContentCard";
import { getContentComponentMediaUrl } from "@companion-core/shared/app/Utils/componentHelper";
import { getLocalizedDateFormat } from "@companion-core/shared/app/Utils/datetime";
import dayjs from "dayjs";
import "Assets/Styles/scss/componentCard.scss";

export const ComponentCard = ({ component }: ComponentCardProps) => {
  const { t } = useTranslation();
  const componentId = useMemo(() => component.id, [component]);
  const componentImageUrl = useMemo(
    () => getContentComponentMediaUrl(component, "image"),
    [component],
  );
  const componentLogoUrl = useMemo(
    () => getContentComponentMediaUrl(component, "logo"),
    [component],
  );
  const componentTitle = useMemo(() => component.title, [component]);
  const componentSubtitle = useMemo(() => component.vendor?.name, [component]);
  const componentDate = useMemo(
    () =>
      `${t("common.component-card.end-date")} ${dayjs(component.end_date).format(
        getLocalizedDateFormat(),
      )}`,
    [component],
  );
  const componentDetailUrl = useMemo(
    () => `/${component.content_ct}/details/${component.parent_id}`,
    [component],
  );

  return (
    <Link
      data-testid={`component-card--${componentId}--link`}
      to={componentDetailUrl}
      className="component-card">
      <ContentCard
        testID={`component-card--${componentId}`}
        title={componentTitle}
        subtitle={componentSubtitle}
        imageUrl={componentImageUrl}
        logo={componentLogoUrl}
        date={componentDate}
      />
    </Link>
  );
};
