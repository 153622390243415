import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { useRequests } from "@companion-core/shared/app/Queries";
import { APIRequest } from "@companion-core/shared/app/Interfaces/API";
import { useTranslation } from "react-i18next";
import { getModule } from "@companion-core/shared/app/Utils/config";
import { Banner } from "../../Components/Banner";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { getLang } from "Utilities/lang";
import { loadLocale } from "@companion-core/shared/app/Utils/datetime";
import { useMemberStore } from "@companion-core/shared/app/Store/memberStore";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import "Assets/Styles/scss/myrequests.scss";

const Requests = () => {
  const headerImgURL = getModule("concierge").modules["my-requests"].assets.headerImg;
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { isLoading, data } = useRequests();
  const [requestList, setRequestList] = useState<APIRequest[]>();
  const [requestsFetched, setRequestFetched] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<string>("0");
  const { requests, setMemberRequests } = useMemberStore();

  useEffect(() => {
    const locale = getLang();
    loadLocale(locale);
    if (!isLoading && data) {
      setRequestList(data.data.results as APIRequest[]);
      setMemberRequests(data.data.results as APIRequest[]);
      setRequestFetched(true);
    }
  }, [isLoading, data]);

  const navigateTabsRoute: Record<string, () => void> = {
    "0": () => navigate("/my-requests/past"),
    "1": () => navigate("/my-requests"),
  };

  const handleTabChange = (key: string) => {
    setActiveKey(key);
    navigateTabsRoute[activeKey]();
  };

  const handleArrowNavigate = () => {
    const request = requests?.find((value) => value.id === id);
    if (request) {
      navigate(
        ["cancelled", "closed"].includes(request.status) ? "/my-requests/past" : "/my-requests",
      );
    }
  };

  return (
    <div className="my-requests__content">
      {id && (
        <div onClick={() => handleArrowNavigate()}>
          <FontIcon name="JP-Arrow-left-bold" size={24} className="my-requests__back-button" />
        </div>
      )}
      <Banner title={t("common.my-requests.title.label")} imageUrl={headerImgURL} />
      <Tabs
        className="my-requests__tab-content"
        defaultActiveKey="0"
        activeKey={activeKey}
        onChange={(key) => handleTabChange(key)}
        centered
        items={[
          {
            key: "0",
            label: t("common.my-requests.tab.on-going.label"),
            children: (
              <Outlet
                context={{
                  activeKey,
                  setActiveKey,
                  requestsFetched,
                  data: requestList?.filter(
                    (item) => item.status !== "closed" && item.status !== "cancelled",
                  ),
                }}
              />
            ),
          },
          {
            key: "1",
            label: t("common.my-requests.tab.done.label"),
            children: (
              <Outlet
                context={{
                  activeKey,
                  setActiveKey,
                  requestsFetched,
                  data: requestList?.filter(
                    (item) => item.status === "closed" || item.status === "cancelled",
                  ),
                }}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default Requests;
