import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import { JPInputError } from "@companion-core/shared/app/Components/Inputs/JPInputError";
import InputLabel from "@companion-core/web/src/Components/InputLabel";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { getCountries, getCountryCallingCode } from "react-phone-number-input/input";
import { CountryCode, E164Number, parsePhoneNumber } from "libphonenumber-js";
import { getOptions } from "@companion-core/shared/app/Utils/config";
import { JPInputPhoneProps } from "./index";
import "@companion-core/web/src/Assets/Styles/scss/inputphone.scss";

export const JPInputPhone: React.FC<JPInputPhoneProps> = ({
  name,
  value = "",
  required = false,
  label,
  placeholder,
  icon,
  payloadPath = name,
  testID,
  setFieldErrors,
  onValueChange,
  registerValidation,
}: JPInputPhoneProps) => {
  const { i18n } = useTranslation();
  const form = Form.useFormInstance();
  const i18nLanguage = i18n?.languages[0]?.toLocaleLowerCase();
  const { country: defaultCountry, number: defaultPhone } =
    value && isValidPhoneNumber(value)
      ? parsePhoneNumber(value)
      : {
          country: (getOptions()?.formatLocales[i18nLanguage]?.substr(3, 4) ?? "FR") as CountryCode,
          number: "",
        };
  const [error, setError] = useState<string>("");
  const [formattedPhoneInput, setFormattedPhoneInput] = useState<E164Number | undefined>(
    defaultPhone,
  );
  const formattedPhoneInputRef = useRef(formattedPhoneInput); // Create a ref to store the latest value and avoid stale closure
  const countryLabels = getCountries().reduce(
    (acc, country) => ({ ...acc, [country]: `${country} +${getCountryCallingCode(country)}` }),
    {},
  );

  useEffect(() => {
    registerValidation &&
      registerValidation(name, () => {
        validateInput();
      });
  }, []);

  useEffect(() => {
    formattedPhoneInputRef.current = formattedPhoneInput; // Update ref value
  }, [formattedPhoneInput]);

  useEffect(() => {
    if (form && payloadPath) {
      form.setFieldsValue({
        [payloadPath]: formattedPhoneInput,
      });
    }
    if (onValueChange) {
      onValueChange(formattedPhoneInput ?? "");
    }
  }, [onValueChange, formattedPhoneInput, form, payloadPath]);

  const validateInput = () => {
    const err =
      !formattedPhoneInputRef.current && required
        ? "common.form.input.error.missing"
        : !isValidPhoneNumber(formattedPhoneInputRef.current || "")
        ? "common.form.input.validators.isPhoneNumber.error"
        : "";
    setError(err);
    setFieldErrors && setFieldErrors(err ? [err] : []);
  };

  return (
    <div data-testid={testID} className="JPInputPhone">
      {label && <InputLabel label={label} icon={icon} />}
      <PhoneInput
        international={false}
        className={`JPInputPhone__container ${error && "JPInputPhone__container--error"}`}
        labels={countryLabels}
        placeholder={placeholder}
        limitMaxLength={true}
        flagComponent={() => <></>} // Remove country flags
        internationalIcon={() => <></>} // Remove international icon
        defaultCountry={defaultCountry}
        value={formattedPhoneInput}
        onChange={setFormattedPhoneInput}
        onBlur={validateInput}
      />
      {error && <JPInputError testID={testID} error={error} />}
    </div>
  );
};
