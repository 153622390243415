import React, { useEffect, useMemo, useRef, useState } from "react";
import { Select, Form } from "antd";
import { useTranslation } from "react-i18next";
import { JPInputSelectProps } from ".";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import "@companion-core/web/src/Assets/Styles/scss/inputselect.scss";
import InputLabel from "@companion-core/web/src/Components/InputLabel";
import { JPInputError } from "@companion-core/shared/app/Components/Inputs/JPInputError";

export const JPInputSelect: React.FC<JPInputSelectProps> = ({
  name,
  payloadPath = name,
  required = false,
  bordered = true,
  icon,
  label,
  placeholder,
  defaultValue = undefined,
  options,
  testID,
  dropdownClass,
  setFieldErrors,
  onValueChange,
  registerValidation,
}: JPInputSelectProps) => {
  const form = Form.useFormInstance(); // Get current context form instance
  const { t } = useTranslation();
  const [inputValue, setValue] = useState<string | undefined>(defaultValue);
  const inputValueRef = useRef(inputValue); // Create a ref to store the latest value and avoid stale closure

  const [error, setError] = useState<string>("");

  // transform each locolize keys to string value
  const translatedOptions = useMemo(
    () =>
      options.map((option) => ({
        ...option,
        label: t(option.text),
      })),
    [options, t],
  );

  useEffect(() => {
    registerValidation && registerValidation(name, validateInput);
  }, []);

  useEffect(() => {
    inputValueRef.current = inputValue; // Update ref value
  }, [inputValue]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (form && payloadPath) {
      form.setFieldsValue({
        [payloadPath]: inputValue,
      });
    }
    if (onValueChange) {
      onValueChange(inputValue ?? "");
    }
  }, [payloadPath, form, inputValue]);

  const validateInput = () => {
    const error = !inputValueRef.current && required ? "common.form.input.error.missing" : "";
    setError(error);
    setFieldErrors && setFieldErrors(error ? [error] : []);
  };

  const handleOnChange = (value: string) => {
    if (value) {
      setError("");
    }
    setValue(value);
  };

  return (
    <div className="JPInputSelect">
      {label && (
        <div className="JPInputSelect__label">
          <InputLabel label={label} icon={icon} />
        </div>
      )}
      <Select
        dropdownRender={(menu) => (
          <div className={`JPInputSelect__dropdown ${dropdownClass}`}>{menu}</div>
        )}
        className="JPInputSelect__input"
        data-testid={testID}
        bordered={bordered}
        options={translatedOptions}
        status={error ? "error" : undefined}
        value={inputValue}
        onBlur={validateInput}
        onChange={(value) => handleOnChange(value)}
        size="large"
        popupClassName="pop-up"
        suffixIcon={<FontIcon name="JP-Arrow-down-bold" size={14} />}
        placeholder={<span className="placeholder body-text-6">{placeholder}</span>}></Select>
      {error && <JPInputError testID={testID} error={error} />}
    </div>
  );
};
