// Note: This file is used to load the JSON files for the dynamic forms in the recover / reset password components
// The JSON files are located in the shared/app/Assets/forms/password folder
// ReactNative does not support the dynamic loading of JSON files, so we have to import them manually
import { DynamicFormJson } from "@companion-core/shared/app/Interfaces/wizard";
import recoverJSON from "./recover.json";
import resetJSON from "./reset.json";

const passwordFormsMap: Record<string, DynamicFormJson> = {
  recover: recoverJSON,
  reset: resetJSON,
};

export const createDeepCopy = (obj: DynamicFormJson) => {
  return JSON.parse(JSON.stringify(obj)) as DynamicFormJson;
};

/* Get the JSON from the file path provided
 * @param path - the path to the JSON file
 * @returns the JSON object
 * */
export const getJSONFromFile = (path: string) => {
  const passwordForm = path.replace(".json", "").split("/")[1];
  const originalFile = passwordFormsMap[passwordForm] ?? {};
  return createDeepCopy(originalFile);
};
