import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import { Button } from "@companion-core/shared/app/Components/Button";
import { BellConfirmationProps } from ".";
import { trackClickBell } from "@companion-core/shared/app/tracking/bell";
import "Assets/Styles/scss/bellConfirmation.scss";

export const BellConfirmation = ({ requestStatus = "success", onClick }: BellConfirmationProps) => {
  const { t } = useTranslation();
  const status = requestStatus === "success" ? "success" : "error";

  return (
    <div data-testid="bell__confirmation" className="bell__confirmation">
      <div className="bell__confirmation-container">
        <div className="bell__confirmation-icon">
          <FontIcon name={status === "success" ? "JP-Circle-check" : "JP-Circle-close"} size={46} />
        </div>
        <div className="bell__confirmation-title title-2">
          {t(`requestV2.concierge.confirmation.${status}.title`)}
        </div>
        <div className="body-text-6">
          {t(`requestV2.concierge.confirmation.${status}.subtitle`)}
          {status === "error" && (
            <Link
              data-testid="confirmation__link-need-help"
              to="/need-help"
              className="body-text-5"
              onClick={() => trackClickBell("help", "", "help")}>
              {t("requestV2.concierge.confirmation.error.help.link")}
            </Link>
          )}
        </div>
      </div>
      <div className="bell__confirmation-button">
        <Button
          testID="bell__confirmation-button"
          type="primary"
          size="full"
          isUppercase={true}
          label={t(`requestV2.concierge.confirmation.${status}.buttonLabel`)}
          loading={requestStatus === "loading"}
          onClick={onClick}
        />
      </div>
    </div>
  );
};
