import React from "react";
import { IndependentDynamicFormProps } from "./index";
import { WizardStoreProvider } from "@companion-core/shared/app/Store/wizardStore";
import { DynamicForm } from "@companion-core/shared/app/Components/DynamicForm";

/**
 * Represents a dynamic form component that use its own context.
 * It uses a WizardStoreProvider to encapsulate the DynamicForm.
 * No external component will be able to interact with this component's WizardStore.
 * So you can use as many forms as you like on the same page without conflict.
 *
 * @component
 * @param {Object} props - The properties that define the IndependentDynamicForm component.
 * @param {string} props.name - The name of the form.
 * @param {object} props.initialFormJSON - The initial JSON data for the form.
 * @param {function} props.onSubmitForm - The function to be called when the form is submitted.
 * @param {function} [props.onSubmitConclude] - The function to be called when the form submission is concluded.
 * @returns {React.ReactElement} - The rendered independent dynamic form component.
 */
export const IndependentDynamicForm = ({
  name,
  initialFormJSON,
  onSubmitForm,
  onSubmitConclude,
}: IndependentDynamicFormProps): React.ReactElement => {
  return (
    <WizardStoreProvider>
      <DynamicForm
        name={name}
        initialFormJSON={initialFormJSON}
        useProvider
        onSubmitForm={onSubmitForm}
        onSubmitConclude={onSubmitConclude}
      />
    </WizardStoreProvider>
  );
};
