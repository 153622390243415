import { MutationFunction, useMutation, UseMutationResult, useQuery } from "@tanstack/react-query";
import { client } from "@johnpaul/jp-api-client";
import {
  getClientToken,
  getHashParams,
  hashPassword,
} from "@companion-core/shared/app/Utils/apiClient";
import jwtDecode, { JwtPayload } from "jwt-decode";

export const useMember = (enabled = true) => {
  return useQuery({
    queryKey: ["member"],
    queryFn: () => client.members.get("me"),
    enabled,
    retry: false,
  });
};

export const useSubscriptions = (enabled = true) => {
  return useQuery({
    queryKey: ["subscriptions"],
    queryFn: () => client.members.subscriptions.get("me"),
    enabled,
    retry: false,
  });
};

export const useVerifyAccount = (accessTokenClient: string, vToken: string, enabled = true) => {
  return useQuery({
    queryKey: ["verifyAccount"],
    queryFn: () => client.members.v2.register.verifyAccount(accessTokenClient, vToken),
    enabled: enabled && !!accessTokenClient.length,
    retry: false,
    cacheTime: 0,
  });
};

type RecoverPasswordVariables = {
  email: string;
};

export function useRecoverPassword(): UseMutationResult<unknown, Error, RecoverPasswordVariables> {
  const mutationFn: MutationFunction<unknown, RecoverPasswordVariables> = ({ email }) => {
    return getClientToken().then((response: string) => {
      const token = { "access_token_client": response };
      return client.password.recover({ email, token });
    });
  };

  return useMutation<unknown, Error, RecoverPasswordVariables>(mutationFn, {
    retry: false,
    cacheTime: 0,
  });
}

type ResetPasswordVariables = {
  password: string;
  token: string;
};

type ResetError = {
  response?: {
    data?: {
      error_description?: {
        password?: [string];
      };
    };
  };
};

export function useResetPassword(
  onError?: (error: ResetError) => void,
): UseMutationResult<unknown, ResetError, ResetPasswordVariables> {
  const mutationFn: MutationFunction<unknown, ResetPasswordVariables> = async ({
    password,
    token,
  }) => {
    return getClientToken().then(async (response: string) => {
      const clientToken = { "access_token_client": response };
      const jwt = jwtDecode<JwtPayload>(token);
      // eslint-disable-next-line camelcase
      const { hash_len, m, p, salt, t } = await getHashParams({
        sub: jwt.sub,
        token: response,
      });
      const hashedPassword = await hashPassword(password, salt, t, m, p, hash_len);
      return await client.password.reset({
        password: hashedPassword,
        token,
        clientToken,
        sub: jwt.sub,
        isPasswordHashed: true,
      });
    });
  };

  return useMutation(mutationFn, {
    retry: false,
    cacheTime: 0,
    onError: onError ? onError : undefined,
  });
}

type UpdateMemberVariables = {
  id: string;
  payload: Record<string, unknown>;
};

export function useUpdateMember(onError?: () => void) {
  const mutationFn: MutationFunction<unknown, UpdateMemberVariables> = async ({ id, payload }) => {
    return await client.members.update(id, payload);
  };

  return useMutation(mutationFn, {
    retry: false,
    cacheTime: 0,
    onError: onError ? onError : undefined,
  });
}
