/* eslint-disable camelcase */
import { useQuery } from "@tanstack/react-query";
import { client } from "@johnpaul/jp-api-client";

import { COMPONENTS_PAGE_SIZE, sortData } from "@companion-core/shared/app/Utils/componentHelper";
import { APIBlockItem, JPClientRequest } from "@companion-core/shared/app/Interfaces/API";

interface BlockItemQuery {
  id: string;
  lang: string;
  pageSize?: number;
  enabled?: boolean;
}

export const useBlockItems = ({
  id,
  lang,
  pageSize = COMPONENTS_PAGE_SIZE,
  enabled,
}: BlockItemQuery) => {
  return useQuery(
    ["block-items", id, lang, pageSize],
    () =>
      client.content.v2.blocks.getBlockItems(id, {
        components_lang: lang,
        components_page_size: pageSize,
      }),
    {
      select: (data: JPClientRequest<APIBlockItem>) =>
        sortData(data.data.results) as APIBlockItem[],
      enabled: enabled ?? true,
    },
  );
};

interface BlockItemComponentQuery {
  id: string;
  lang: string;
  page?: number;
  pageSize?: number;
  enabled?: boolean;
}

export const useBlockItemComponents = ({
  id,
  lang,
  page,
  pageSize = COMPONENTS_PAGE_SIZE,
  enabled,
}: BlockItemComponentQuery) => {
  const params: Record<string, unknown> = {
    lang,
    page_size: pageSize,
  };
  if (page) {
    params.page = page;
  }
  return useQuery(
    ["block-item-components", id, lang, pageSize, page],
    () =>
      client.content.v2.blocks.getBlockItemComponents(id, {
        lang,
        page_size: pageSize,
        page,
      }),
    {
      enabled: enabled ?? true,
    },
  );
};
