import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Spin } from "antd";
import { getJSONFromFile } from "@companion-core/shared/app/Assets/forms/password/jsonLoader";
import { useResetPassword } from "@companion-core/shared/app/Queries";
import { JPInputError } from "@companion-core/shared/app/Components/Inputs/JPInputError";
import { IndependentDynamicForm } from "@companion-core/shared/app/Components/DynamicForm/IndependentDynamicForm";

const ResetPassword = () => {
  const {
    mutate: resetPassword,
    isLoading,
    isSuccess,
  } = useResetPassword((error) => {
    const errorReason = error?.response?.data?.error_description?.password?.[0];
    const clientMessage =
      errorReason === "known_password"
        ? "password.resetV2.errors.sameAsCurrent"
        : "password.resetV2.errors.unknown";
    setError(t(clientMessage));
    setForm(getJSONFromFile("password/reset.json"));
  });

  const { t } = useTranslation();
  const [form, setForm] = useState(getJSONFromFile("password/reset.json"));
  const { token } = useParams();
  const [error, setError] = React.useState<string>("");

  return (
    <div className="passwordManagement__container d-flex justify-content-center">
      <div className="passwordManagement__title-container">
        <span className="super-title-3 d-flex align-self-center">
          {isSuccess ? t("password.resetV2.success.title") : t("password.resetV2.title")}
        </span>
      </div>

      {isSuccess && (
        <a href="/login" className="body-text-5 text-decoration-none">
          {t("password.resetV2.success.buttons.back")}
        </a>
      )}
      {error ? <JPInputError testID="error_reset_password" error={error} /> : null}
      {!isSuccess && (
        <>
          <IndependentDynamicForm
            name="resetPassword"
            initialFormJSON={form}
            onSubmitForm={(payload) => {
              if (!token) return;
              setError("");
              const { password } = payload as { password: string };
              resetPassword({ password, token });
            }}
          />
        </>
      )}
      {isLoading && <Spin data-testid="resetPassword__loader" size="large" />}
    </div>
  );
};

export default ResetPassword;
