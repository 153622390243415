import React from "react";

import { useTranslation } from "react-i18next";

import { Button } from "@companion-core/shared/app/Components/Button";
import { ButtonNavigationItemProps } from "./ButtonNavigationItem.model";

import "@companion-core/web/src/Assets/Styles/scss/navigation.scss";

export const ButtonNavigationItem = ({
  name,
  label,
  onClick,
  buttonType = "primary",
}: ButtonNavigationItemProps) => {
  const { t } = useTranslation();

  return (
    <Button
      testID={`navigation-item__button--${name}`}
      classNames={["navigation-item__button"]}
      type={buttonType}
      label={t(label)}
      size="full"
      onClick={onClick}
    />
  );
};
