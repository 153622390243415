import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow"
import { persist, createJSONStorage } from "zustand/middleware";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { isWeb } from "@companion-core/shared/app/Utils/platform";
import type { Locale } from "antd/es/locale";

interface I18nState {
  locale: string;
  didChange: boolean;
  antdLocale: Locale | undefined;
}

interface I18nActions {
  setLocale: (locale: string) => void;
  setDidChange: (changed: boolean) => void;
  setAntdLocale: (antdLocale: Locale) => void;
}

export const initialState: I18nState = {
  locale: "",
  didChange: false,
  antdLocale: undefined,
}

export const useI18nStore = createWithEqualityFn<I18nState & I18nActions>()(
  persist(
    (set) => ({
      ...initialState,
      setLocale: (locale: string) => set({ locale }),
      setDidChange: (didChange: boolean) => set({ didChange }),
      setAntdLocale: (antdLocale: Locale) => set({ antdLocale }),
    }),
    {
      name: "i18n-storage",
      storage: createJSONStorage(() => isWeb() ? localStorage : AsyncStorage),
    }
  ), shallow
);
