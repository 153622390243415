import React, { useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Image } from "antd";
import { getConfig, getOptions } from "@companion-core/shared/app/Utils/config";
import { Button, ButtonType } from "@companion-core/shared/app/Components/Button";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import { EventLink } from "@companion-core/shared/app/Components/EventLink";
import { NavLinkItem } from "@companion-core/shared/app/Interfaces/navigation";
import { useAuthStore } from "@companion-core/shared/app/Store/authStore";
import { useAccountNavigationStore } from "@companion-core/shared/app/Store/accountNavigationStore";
import { useMediaQuery } from "@companion-core/web/src/Utilities/mediaQuery";
import { AccountMenu } from "./AccountMenu";
import "Assets/Styles/scss/header.scss";

const Header: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { accountIcon } = getOptions();
  const options = getOptions();
  const modules = getConfig()?.modules;
  const global = getConfig()?.global;
  const { authenticated } = useAuthStore((state) => ({ authenticated: state.authenticated }));
  const { openPopover } = useAccountNavigationStore((state) => ({
    openPopover: state.openPopover,
  }));
  const isMobileSize = useMediaQuery("(max-width: 768px)");
  const conciergeEnabled = useMemo(() => modules?.concierge?.enabled, [modules]);
  const displayLogoCentered = useMemo(
    () => !conciergeEnabled && isMobileSize,
    [conciergeEnabled, authenticated, isMobileSize],
  );
  const isRegisterPage = useMemo(() => location.pathname.includes("register"), [location]);
  const isLoginPage = useMemo(() => location.pathname.includes("login"), [location]);

  const Logo = () => <Image max-height={54} src={"/" + global?.header?.logo} preview={false} />;

  const ClickableLogo: React.FC = () => {
    return (
      <div
        data-testid="header__logo"
        className={`cursor-pointer me-auto ${
          displayLogoCentered && "centered-horizontally sm:absolute"
        }`}
        onClick={() => navigate("/")}>
        <Logo />
      </div>
    );
  };

  const NavLink: React.FC = () => {
    // Dynamic mapping of header links in config file
    return options?.headerLinks.map(({ name, type, link, path }: NavLinkItem) => {
      // check if user is on this page
      const selected = path === location.pathname;

      return (
        <EventLink
          data-testid={`header__link--${name}`}
          key={name}
          name={name}
          type={type}
          link={link}
          path={path}>
          <span
            className={`link cursor-pointer uppercase ${selected ? "body-text-3" : "body-text-4"}`}>
            {t(`common.menu.links.${name}`)}
          </span>
        </EventLink>
      );
    });
  };

  const NavBar: React.FC<{ disabled?: boolean }> = (props: { disabled?: boolean }) => {
    return (
      !props.disabled && (
        <nav
          data-testid="header__link-bar"
          className="header__nav-bar centered-horizontally sm:hidden">
          <NavLink />
        </nav>
      )
    );
  };

  const ProfileButton: React.FC<{ disabled?: boolean }> = (props: { disabled?: boolean }) => {
    return (
      !props.disabled && (
        <div
          data-testid="header__profile"
          className={`uppercase cursor-pointer ${displayLogoCentered && "hidden"}`}
          onClick={() => {
            if (isMobileSize) {
              openPopover();
            }
          }}>
          {isMobileSize ? <FontIcon name={accountIcon} size={24} /> : <AccountMenu />}
        </div>
      )
    );
  };

  const registerButtonType = (): ButtonType => (isRegisterPage ? "ghost" : "primary");
  const loginButtonType = (): ButtonType => {
    if (isLoginPage) {
      return "ghost";
    }
    if (isRegisterPage) {
      return "primary";
    }
    return "secondary";
  };

  // Action button Register / Login, with conditional render if connected or not
  const AuthActionButtons: React.FC<{ disabled?: boolean }> = (props: { disabled?: boolean }) => {
    return (
      !props.disabled && (
        <div className="header__button-container sm:hidden d-inline-flex">
          <div className="register-button">
            <Button
              testID="header__button-register"
              type={registerButtonType()}
              size="small"
              isUppercase
              label={t("common.header.buttons.register")}
              onClick={() => navigate("/register/member")}
            />
          </div>
          {/* Connection button */}
          <div className="auth-button">
            <Button
              testID="header__button-login"
              type={loginButtonType()}
              size="small"
              isUppercase
              label={t("common.header.buttons.login")}
              onClick={() => navigate("/login")}
            />
          </div>
        </div>
      )
    );
  };

  return (
    <div className="header__container">
      <ClickableLogo />
      <AuthActionButtons disabled={authenticated} />
      <NavBar disabled={!authenticated} />
      <ProfileButton disabled={!isMobileSize && !authenticated} />
    </div>
  );
};

export default Header;
