import React, { useMemo, useEffect } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { Spin } from "antd";
import { useAuthTokenSSOClient } from "@companion-core/shared/app/Queries";
import { useAuthStore } from "@companion-core/shared/app/Store/authStore";
import { useBellStore } from "@companion-core/shared/app/Store/bellStore";
import { getModule, refetchConfig } from "@companion-core/shared/app/Utils/config";
import "Assets/Styles/scss/sso.scss";

interface AuthError {
  response?: {
    data?: {
      internal_error_code: number;
    };
  };
}

const SSOClient = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const authCode = useMemo(() => searchParams.get("code"), [searchParams]);
  const { setAuthenticated } = useAuthStore((state) => ({
    setAuthenticated: state.setAuthenticated,
  }));
  const { setIsPopUpVisible } = useBellStore((state) => ({
    setIsPopUpVisible: state.setIsPopUpVisible,
  }));
  const { error, isFetched, isSuccess } = useAuthTokenSSOClient(authCode ?? "");

  useEffect(() => {
    if (isFetched && authCode) {
      if (isSuccess) {
        if (getModule("concierge")?.modules?.bell?.options?.isPopUpVisibleByDefault) {
          setIsPopUpVisible(true);
        }
        searchParams.delete("code");
        let queryParams = "";
        searchParams.forEach((value, key) => {
          if (queryParams !== "") queryParams += "&";
          queryParams += `${key}=${value}`;
        });
        setAuthenticated(true);
        navigate({
          pathname: pathname,
          search: `?${queryParams}`,
        });
        refetchConfig();
      } else {
        setAuthenticated(false);
        navigate({
          pathname: "/sso/error",
          search: `?errorCode=${(error as AuthError)?.response?.data?.internal_error_code}`,
        });
        refetchConfig();
      }
    }
  }, [isFetched]);

  return (
    <div data-testid="sso" className="sso">
      <Spin data-testid="sso__loader" size="large" />
    </div>
  );
};

export default SSOClient;
