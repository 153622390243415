import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import getValidation from "@companion-core/shared/app/Utils/validators";
import { JPInputProps } from "./index";
import "@companion-core/web/src/Assets/Styles/scss/input.scss";
import { JPInputTips } from "@companion-core/shared/app/Components/Inputs/JPInputTips";
import { getCurrency } from "@companion-core/shared/app/Utils/config";
import { JPInputElement } from "@companion-core/shared/app/Components/Inputs/JPInput/JPInputElement/JPInputElement";
import {
  isValidBudget,
  isValidNumber,
} from "@companion-core/shared/app/Components/Inputs/JPInput/Utils";

/**
 * Primary UI component for user interaction
 * @return {Component} Input component
 */
export const JPInput: React.FC<JPInputProps> = ({
  type = "text",
  name,
  required = false,
  value = "",
  label,
  icon,
  placeholder,
  tips,
  validators,
  testID,
  maxLength,
  confirmationInput = false,
  confirmationLabel,
  payloadPath = name,
  disabled = false,
  setFieldErrors,
  onValueChange,
  registerValidation,
  ...props
}: JPInputProps) => {
  const { t } = useTranslation();
  const form = Form.useFormInstance(); // Get current context form instance
  const [inputValue, setInputValue] = useState<string>(value);
  const inputValueRef = useRef(inputValue); // Create a ref to store the latest value and avoid stale closure
  const [confirmationValue, setConfirmationValue] = useState<string>(value);
  const confirmationValueRef = useRef(confirmationValue); // Create a ref to store the latest value and avoid stale closure
  const [error, setError] = useState<string>("");
  const [confirmationError, setConfirmationError] = useState<string>("");
  const typeProps = useMemo(() => (type === "textarea" ? { showCount: true } : {}), [type]);

  useEffect(() => {
    registerValidation &&
      registerValidation(name, () => {
        validateInput();
        if (confirmationInput) {
          validateConfirmationInput();
        }
      });
  }, []);

  useEffect(() => {
    // Update refs value
    inputValueRef.current = inputValue;
    confirmationValueRef.current = confirmationValue;
  }, [inputValue, confirmationValue]);

  const updateValue = (newValue: string) => {
    if (form && payloadPath) {
      form.setFieldsValue({
        [payloadPath]: newValue,
      });
    }
    setInputValue(newValue);
    if (onValueChange) {
      onValueChange(newValue);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const value = e.target.value as string;
    if (type === "number") {
      isValidNumber(value) && updateValue(value);
    } else if (type === "budget") {
      isValidBudget(value) && updateValue(value);
    } else {
      updateValue(value);
    }
  };

  const handleConfirmationChange = (
    e: React.ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const value = e.target.value as string;
    if (type === "number") {
      isValidNumber(value) && setConfirmationValue(value);
    } else if (type === "budget") {
      isValidBudget(value) && setConfirmationValue(value);
    } else {
      setConfirmationValue(value);
    }
  };

  const validateInput = () => {
    let err = !inputValueRef.current && required ? "common.form.input.error.missing" : "";

    if (!err && validators?.length) {
      const { isValid, errorMessages } = getValidation(validators, inputValueRef.current);
      if (!isValid) err = errorMessages[0];
    }
    setError(err);
    if (setFieldErrors) {
      const errors = [err, confirmationError].filter((element) => Boolean(element));
      setFieldErrors(errors);
    }
  };

  const validateConfirmationInput = () => {
    const err =
      !confirmationValueRef.current && required
        ? "common.form.input.error.missing"
        : inputValueRef.current !== confirmationValueRef.current
        ? "common.form.input.confirmation.error"
        : "";
    setConfirmationError(err);
    if (setFieldErrors) {
      const errors = [error, err].filter((element) => Boolean(element));
      setFieldErrors(errors);
    }
  };

  const commonProps: { [key: string]: unknown } = useMemo(
    () => ({
      ...props,
      ...typeProps,
      icon,
      prefix: type === "budget" ? (getCurrency().symbol as string) : undefined,
      maxLength,
    }),
    [props, typeProps],
  );

  return (
    <div className="JPInput">
      <JPInputElement
        {...commonProps}
        type={type}
        key="input"
        testID={testID}
        name={name}
        label={label}
        placeholder={!disabled ? placeholder : ""}
        value={inputValue}
        error={error}
        disabled={disabled}
        onChange={handleInputChange}
        onBlur={validateInput}
        onReset={() => setError("")}
      />
      {confirmationInput && (
        <div className="JPInput__confirmation">
          <JPInputElement
            {...commonProps}
            key="confirmation-input"
            type={type}
            testID={`${testID}__confirmation`}
            name={`${name}__confirmation`}
            label={confirmationLabel ?? t("common.form.input.confirmation.label")}
            placeholder={!disabled ? placeholder : ""}
            value={confirmationValue}
            error={confirmationError}
            disabled={disabled}
            onChange={handleConfirmationChange}
            onBlur={validateConfirmationInput}
            onReset={() => setConfirmationError("")}
          />
        </div>
      )}
      {tips && <JPInputTips testID={testID} tip={tips.text} icon={tips.icon} />}
    </div>
  );
};
