import React, { useMemo } from "react";
import { useConfigStore } from "@companion-core/shared/app/Store/configStore";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { Button } from "@companion-core/shared/app/Components/Button";

export const ConfigGuard = () => {
  const { t } = useTranslation();
  const { failure } = useConfigStore();
  const configFailure = useMemo(() => failure, [failure]);
  const navigate = useNavigate();

  if (configFailure) {
    return (
      <div className="error__container">
        <img className="error__image" src="/assets/images/error/server_error.svg" />
        <div className="body-text-3 error__title">{t("common.error.config.title")}</div>
        <div className="body-text-4 margin-top-24">{t("common.error.config.text")}</div>
        <Button
          classNames={["error__button"]}
          testID="config-failure__button"
          label={t("common.error.config.button")}
          onClick={() => navigate(0)}></Button>
      </div>
    );
  } else {
    return <Outlet />;
  }
};
