import { getConfig } from "@companion-core/shared/app/Utils/config";
import { useI18nStore } from "@companion-core/shared/app/Store/i18nStore";

// antd Locales
import itIT from "antd/locale/it_IT";
import frFR from "antd/locale/fr_FR";
import enGB from "antd/locale/en_GB";
import plPL from "antd/locale/pl_PL";
import deDE from "antd/locale/de_DE";
import { Locale } from "antd/es/locale";

const antdLocales: Record<string, Locale> = {
  fr: frFR,
  en: enGB,
  it: itIT,
  de: deDE,
  pl: plPL,
};

export function getAntdLocale(lang: string) {
  if (!Object.keys(antdLocales).includes(lang)) return frFR;
  return antdLocales[lang];
}

export function getLang() {
  // Return lang stored if user already change the lang with the language switcher component
  const localeI18nStore = useI18nStore.getState().locale;
  if (localeI18nStore) return localeI18nStore;
  // Init lang with the language user browser
  let lang = navigator.language.slice(0, 2);
  try {
    const config = getConfig();
    // Load default language set in the config if user language browser not available in our list
    const { locales, defaultLocale } = config?.config.options;
    lang = locales.includes(lang) ? lang : defaultLocale;
  } catch {
    lang = "fr";
  }
  return lang;
}
