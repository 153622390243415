import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { loadLanguageAsync } from "@companion-core/shared/app/I18n";
import { Bell } from "./Components/Bell";
// Utils
import {
  fetchConfig,
  initApiClient,
  initHashPassword,
} from "@companion-core/shared/app/Utils/apiClient";
import { initTracking } from "Utilities/tracking";
import { initSentry } from "Utilities/sentry";
import { getLang } from "Utilities/lang";
import { hashPassword } from "Utilities/hash";
// Fonts
import "@companion-core/shared/app/Assets/Fonts/Montserrat-Bold.ttf";
import "@companion-core/shared/app/Assets/Fonts/Montserrat-Light.ttf";
import "@companion-core/shared/app/Assets/Fonts/Montserrat-SemiBold.ttf";
// Styles
import "Assets/Styles/scss/index.scss";
import { setMetaThemeColor } from "Utilities/meta";
import { setWebEnvironment } from "Utilities/config";
import { useConfigStore } from "@companion-core/shared/app/Store/configStore";
import { getConfigFileName } from "@companion-core/shared/app/Utils/config";
import configFailure from "@companion-core/shared/app/Assets/config/configFailure.json";
import { setXClientInterceptors } from "@companion-core/shared/app/Utils/interceptors";
import { stringSearchToURLSearchParams } from "./Utilities/navigation";
import { useAuthStore } from "@companion-core/shared/app/Store/authStore";

// Set App Environment
setWebEnvironment();
setXClientInterceptors();

/**
 * Quick fix to force authenticated state to false when query parameters contain "code".
 * We cannot check if the companion have the SSO feature enabled because the config is null here.
 * /!\ There may be a side effect for non SSO companion if query parameters contain "code" /!\
 *
 * @function checkQueryParamsToHandleAuthState
 * @returns {void}
 */
const checkQueryParamsToHandleAuthState = (): void => {
  if (
    window.location.search &&
    stringSearchToURLSearchParams(window.location.search).get("code") !== null
  ) {
    useAuthStore.getState().setAuthenticated(false);
  }
};

async function load(): Promise<boolean> {
  // Init app
  return new Promise((resolve) => {
    checkQueryParamsToHandleAuthState();
    initApiClient();
    const configFileName = getConfigFileName();
    configFileName &&
      fetchConfig(configFileName).then((response) => {
        if (response.success) {
          useConfigStore.getState().setConfig(response.config);
          useConfigStore.getState().setFailure(false);
        } else {
          useConfigStore.getState().setConfig(configFailure);
          useConfigStore.getState().setFailure(true);
        }
        initHashPassword(hashPassword);
        initTracking();
        loadLanguageAsync(getLang());
        initSentry();
        setMetaThemeColor("default");
        resolve(true);
      });
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function start() {
  const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <App />
          <Bell />
        </BrowserRouter>
      </QueryClientProvider>
    </React.StrictMode>,
  );
  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}

load().then(start);
