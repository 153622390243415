import React from "react";
import { ImageCardProps } from "@companion-core/shared/app/Components/Cards/ImageCard/ImageCard.model";
import "Assets/Styles/scss/imageCard.scss";

export const ImageCard = ({ testID, className, imageUrl, children }: ImageCardProps) => {
  return (
    <div data-testid={testID} className="image-card">
      <div
        data-testid={`${testID}--img`}
        style={{ backgroundImage: `url(${imageUrl})` }}
        className="image-card__img"
      />
      <div data-testid={`${testID}--children`} className={`image-card__content ${className}`}>
        {children}
      </div>
    </div>
  );
};
