import React, { useMemo } from "react";
import { ButtonListLayout } from "@companion-core/shared/app/Components/Blocks/Layouts/ButtonListLayout";
import { BlockProps } from "@companion-core/shared/app/Interfaces/Blocks";
import { ComponentData } from "@companion-core/shared/app/Interfaces/API";
import {
  getComponentsCard,
  getComponentsFromBlockItems,
  getComponentText,
} from "@companion-core/shared/app/Utils/componentHelper";

export const ButtonList = ({ simpleBlockItems }: BlockProps) => {
  const simpleComponents: ComponentData[] = useMemo(
    () => getComponentsFromBlockItems(simpleBlockItems),
    [simpleBlockItems],
  );
  const title: string = useMemo(
    () => getComponentText(simpleComponents)?.title ?? "",
    [simpleComponents],
  );
  const subtitle: string = useMemo(
    () => getComponentText(simpleComponents)?.subtitle ?? "",
    [simpleComponents],
  );
  const cards: ComponentData[] = useMemo(
    () => getComponentsCard(simpleComponents),
    [simpleComponents],
  );

  return <ButtonListLayout title={title} subtitle={subtitle} cards={cards} />;
};
