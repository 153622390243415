import { MutationFunction, useMutation } from "@tanstack/react-query"
import { client } from "@johnpaul/jp-api-client";

type AssistanceVariables = {
  payload: Record<string, unknown>;
};

export function useAssistance(onError?: () => void) {
  const mutationFn: MutationFunction<unknown, AssistanceVariables> = async ({ payload }) => {
    return await client.auth.assistance(payload);
  };

  return useMutation(mutationFn, {
    retry: false,
    cacheTime: 0,
    onError: onError ? onError : undefined,
  });
}