import React from "react";
import { useTranslation } from "react-i18next";
import { useWizardStore } from "@companion-core/shared/app/Store/wizardStore";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import { Field } from "@companion-core/shared/app/Interfaces/wizard";
import { SummaryProps } from ".";
import "@companion-core/web/src/Assets/Styles/scss/summary.scss";

export const Summary = ({ fieldList, display = "mobile" }: SummaryProps) => {
  const { t } = useTranslation();
  let usedFields: Field[];
  if (fieldList) {
    usedFields = fieldList;
  } else {
    const { fields } = useWizardStore(
      (state) => ({
        fields: state.fields,
      })
    );
    usedFields = fields;
  }

  return (
    <div data-testid="summary" className="summary">
      {usedFields
        .filter((field: Field) => !field.excludeFromSummary && field.summaryValue)
        .map((field, index) => (
          <div
            key={index}
            className={`${display === "desktop" ? "summary__item--desktop" : "summary__item"}`}>
            <div
              className={`${display === "desktop" ? "summary__label--desktop" : "summary__label"}`}>
              {field.icon && (
                <FontIcon
                  name={field.icon}
                  className="summary__label-icon"
                  size={display === "desktop" ? 20 : 14}
                />
              )}
              <span
                className={`body-text-5 ${
                  display === "desktop" ? "summary__label-title--desktop" : ""
                }`}>
                {t(`${field.label}`)}
              </span>
            </div>
            <span
              className={`${
                display === "desktop" ? "summary__value--desktop" : "summary__value"
              } body-text-6`}>
              {field.type !== "time" ? t(`${field.summaryValue}`) : field.summaryValue}
            </span>
          </div>
        ))}
    </div>
  );
};
