import React, { FC, ReactNode, createContext, useContext } from "react";

interface IDynamicFormContext {
  toNextStep: () => void;
}

type Props = {
  children: ReactNode;
  toNextStep: () => void; // could be incrementStep or submitForm methods define into DynamicForm component
};

const DynamicFormContext = createContext<IDynamicFormContext | null>(null);

const DynamicFormContextProvider: FC<Props> = ({ children, toNextStep }) => {
  return (
    <DynamicFormContext.Provider value={{ toNextStep }}>{children}</DynamicFormContext.Provider>
  );
};

export default DynamicFormContextProvider;

export const useDynamicFormContext = () => useContext(DynamicFormContext) as IDynamicFormContext;
