import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DynamicSection } from "@companion-core/shared/app/Components/MyAccount/DynamicSection";
import { useMediaQuery } from "@companion-core/web/src/Utilities/mediaQuery";
import { useAccountNavigationStore } from "@companion-core/shared/app/Store/accountNavigationStore";
import { Banner } from "Components/Banner";
import { getModule } from "@companion-core/shared/app/Utils/config";
import "@companion-core/web/src/Assets/Styles/scss/myAccount.scss";

const MyAccount = () => {
  const { t } = useTranslation();
  const isMobileSize = useMediaQuery("(max-width: 768px)");
  const { hideBackButtonOnHome, openPopover } = useAccountNavigationStore((state) => ({
    hideBackButtonOnHome: state.hideBackButtonOnHome,
    openPopover: state.openPopover,
  }));
  const headerImage = getModule("my-account").assets.headerImg;

  useEffect(() => {
    if (isMobileSize) {
      hideBackButtonOnHome();
      openPopover();
    }
  }, [isMobileSize]);

  return !isMobileSize ? (
    <div className="my-account-desktop">
      <Banner imageUrl={headerImage} title={t("my-account.banner.title")} overlap={false} />
      <DynamicSection />
    </div>
  ) : (
    <></>
  );
};

export default MyAccount;
