import React, { useEffect } from "react";
import { FloatButton, Popover } from "antd";
import { getModule } from "@companion-core/shared/app/Utils/config";
import { BellContent } from "./BellContent";
import { BellHeader } from "@companion-core/shared/app/Components/Bell/Header/BellHeader";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import { useAuthStore } from "@companion-core/shared/app/Store/authStore";
import { useBellStore } from "@companion-core/shared/app/Store/bellStore";
import { useMediaQuery } from "Utilities/mediaQuery";
import { lockScroll, unlockScroll } from "Utilities/scroll";
import { trackClickBell } from "@companion-core/shared/app/tracking/bell";
import { ConfigProvider } from "antd";
import { useI18nStore } from "@companion-core/shared/app/Store/i18nStore";
import { getAntdLocale, getLang } from "Utilities/lang";
import { setMetaThemeColor } from "Utilities/meta";
import colors from "Assets/Styles/scss/_exports.module.scss";
import { useConfigStore } from "@companion-core/shared/app/Store/configStore";
import "Assets/Styles/scss/bell.scss";

interface BellProps {
  isPopover?: boolean;
}

export const Bell = ({ isPopover = true }: BellProps) => {
  const { authenticated } = useAuthStore((state) => ({ authenticated: state.authenticated }));
  const { fetching: isConfigFetching }: { fetching: boolean } = useConfigStore();
  const { isPopUpVisible, setIsPopUpVisible, setBellContent } = useBellStore((state) => ({
    isPopUpVisible: state.isPopUpVisible,
    setIsPopUpVisible: state.setIsPopUpVisible,
    setBellContent: state.setBellContent,
  }));
  const isMobileSize = useMediaQuery("(max-width: 768px)");
  const bellModule = getModule("concierge")?.modules.bell;
  const isBellEnabled: boolean = bellModule?.enabled;
  const isBellHomeEnabled: boolean = bellModule?.options.home.enabled;
  const { antdLocale } = useI18nStore((state) => ({ antdLocale: state.antdLocale }));

  const toggleBell = (event: "close" | "reduce" = "close"): void => {
    const eventName = event == "close" ? "Close_Bell" : "Reduce_Bell";
    trackClickBell(isPopUpVisible ? eventName : "Open_Bell");
    setIsPopUpVisible(!isPopUpVisible);
  };

  // Lock scroll in background when bell is opened on mobile size
  useEffect(() => {
    if (isMobileSize) {
      if (isPopUpVisible) {
        lockScroll();
        setMetaThemeColor("concierge");
      } else {
        unlockScroll();
        setMetaThemeColor("default");
      }
    } else {
      setMetaThemeColor("default");
    }
  }, [isMobileSize, isPopUpVisible]);

  useEffect(() => {
    setBellContent(isBellHomeEnabled ? "home" : "categories");
  }, [isBellHomeEnabled]);

  useEffect(() => {
    setIsPopUpVisible(!isPopover);
  }, [isPopover]);

  if (isConfigFetching || !authenticated || !isBellEnabled) return <></>;

  return (
    <ConfigProvider
      locale={antdLocale ?? getAntdLocale(getLang())}
      theme={{
        token: {
          colorPrimary: colors.textPrimary,
          fontFamily: "Primary",
        },
      }}>
      <Popover
        placement="topRight"
        title={<BellHeader onClose={toggleBell} />}
        content={<BellContent onClose={toggleBell} />}
        trigger="click"
        arrow={false}
        autoAdjustOverflow={false}
        open={isPopUpVisible}
        overlayClassName="bell-popover">
        <FloatButton
          data-testid="bell__toggle-button"
          type="primary"
          className={`bell__float-button ${!isPopover && "bell__float-button--no-popover"} ${
            isMobileSize && "d-none"
          }`}
          icon={
            !isPopUpVisible ? (
              <FontIcon
                name="JP-Concierge-bell"
                size={28}
                className="bell__icon bell__icon--concierge-bell"
              />
            ) : (
              <FontIcon name="JP-Minus" size={32} className="bell__icon" />
            )
          }
          onClick={() => toggleBell("reduce")}
        />
      </Popover>
    </ConfigProvider>
  );
};
