import React from "react";
import { ComponentData } from "@companion-core/shared/app/Interfaces/API";
import { PostListLayoutProps } from "@companion-core/shared/app/Interfaces/Blocks";
import "@companion-core/web/src/Assets/Styles/scss/postListAlternateLayout.scss";

type ListElementProps = {
  component: ComponentData;
  key: string;
};

export const PostListAlternateLayout = ({ cards }: PostListLayoutProps) => {
  const ListElement = ({ component }: ListElementProps) => {
    return (
      <div className="post-list-alternate-layout__wrapper colored-background">
        <div className="post-list-alternate-layout__content">
          {component.medias && component.medias.length > 0 && (
            <img
              className="post-list-alternate-layout__media"
              data-testid={`post-list-alternate__media--${component.id}`}
              src={component.medias[0].url}
              alt={component.medias[0].description_alt}
            />
          )}
          <div className="post-list-alternate-layout__text-content">
            {component.title && (
              <span
                className="super-title-1"
                data-testid={`post-list-alternate__title--${component.id}`}>
                {component.title}
              </span>
            )}
            {component.subtitle && (
              <span
                className="post-list-alternate-layout__subtitle title-8"
                data-testid={`post-list-alternate__subtitle--${component.id}`}>
                {component.subtitle}
              </span>
            )}
            {component.description && (
              <span
                className="post-list-alternate-layout__description body-text-4"
                data-testid={`post-list-alternate__description--${component.id}`}
                dangerouslySetInnerHTML={{ __html: component.description }}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    cards && (
      <div className="post-list-alternate-layout" data-testid="post-list-alternate">
        {cards.map((card) => (
          <ListElement key={`post-list-alternate-layout__element--${card.id}`} component={card} />
        ))}
      </div>
    )
  );
};
