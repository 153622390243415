import React, { useEffect, useRef, useState } from "react";
import { ComponentData } from "@companion-core/shared/app/Interfaces/API";
import { PictureListLayoutProps } from "@companion-core/shared/app/Interfaces/Blocks";
import { Button } from "@companion-core/shared/app/Components/Button";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import "@companion-core/web/src/Assets/Styles/scss/picturelist.scss";

export const PictureListLayout = ({ title, subtitle, cards, button }: PictureListLayoutProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [shouldShowArrows, setShouldShowArrows] = useState(false);

  useEffect(() => {
    const updateWidths = () => {
      if (ref.current) {
        const containerWidth = ref.current.offsetWidth;

        const images = ref.current.querySelectorAll(".picture-list-layout__image-container");
        const totalImageWidth = Array.from(images).reduce((total, img) => {
          const style = window.getComputedStyle(img);
          const marginLeft = parseInt(style.marginLeft, 10);
          const marginRight = parseInt(style.marginRight, 10);
          return total + (img as HTMLElement).offsetWidth + marginLeft + marginRight;
        }, 0);
        setShouldShowArrows(totalImageWidth > containerWidth);
      }
    };

    window.addEventListener("resize", updateWidths);
    updateWidths();

    return () => window.removeEventListener("resize", updateWidths);
  }, []);

  const handleArrowClick = (direction: "left" | "right") => {
    if (ref.current) {
      const imageWidth = 150;
      const imageMargin = 60;
      const scrollAmount =
        direction === "left" ? -(imageWidth + imageMargin) : imageWidth + imageMargin;
      ref.current.scrollLeft += scrollAmount;
    }
  };

  return (
    cards && (
      <section className="picture-list-layout">
        <Title title={title} />
        <Subtitle subtitle={subtitle} />

        <div className="picture-list-layout__gallery">
          {shouldShowArrows && (
            <>
              <Arrow direction="left" onClick={() => handleArrowClick("left")} />
              <Arrow direction="right" onClick={() => handleArrowClick("right")} />
            </>
          )}

          <div ref={ref} className="picture-list-layout__slide">
            {cards.map((slide) => (
              <PictureCard key={`slide-${slide.id}`} card={slide} />
            ))}
          </div>
        </div>
        <GalleryButton button={button} />
      </section>
    )
  );
};

const GalleryButton = ({ button }: { button: ComponentData | null | undefined }) => {
  if (!button || !button.title) {
    return null;
  }
  return (
    <Button
      testID="block-picturelist-button"
      label={button.title.toUpperCase()}
      classNames={["picture-list-layout__button"]}
    />
  );
};

const Title = ({ title }: { title: string | null }) => {
  if (!title) {
    return null;
  }
  return <h2 className="picture-list-layout__title super-title-1">{title}</h2>;
};

const Subtitle = ({ subtitle }: { subtitle: string | null | undefined }) => {
  if (!subtitle) {
    return null;
  }
  return <span className="picture-list-layout__subtitle title-8">{subtitle}</span>;
};

const PictureCard = ({ card }: { card: ComponentData }) => {
  return (
    <div className="picture-list-layout__image-container">
      <div
        className="picture-list-layout__image"
        style={{
          backgroundImage: `url(${card.medias?.[0]?.url})`,
        }}
      />
    </div>
  );
};

const Arrow = ({ direction, onClick }: { direction: "left" | "right"; onClick: () => void }) => {
  return (
    <div className={`picture-list-layout__${direction}-arrow d-flex top-50`} onClick={onClick}>
      <FontIcon name={`JP-Arrow-${direction}-bold`} className="gallery__icon" />
    </div>
  );
};
