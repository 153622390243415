export const toPascalCase = (text): string => {
  return text.replace(/(^\w|-\w)/g, clearAndUpper);
};

const clearAndUpper = (text): string => {
  return text.replace(/-/, "").toUpperCase();
};

export const capitalize = <T extends string>(s: T): Capitalize<T> =>
  (s?.[0].toUpperCase() + s?.slice(1)) as Capitalize<typeof s>;
