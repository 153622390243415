import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow"
import { NavigationListObject } from "@companion-core/shared/app/Interfaces/navigation";

interface AccountState {
  isPopoverOpen: boolean;
  isBackButtonOnHomeVisible: boolean;
  navigationTree: NavigationListObject[][];
  navigationContentName: string | null;
}

interface AccountActions {
  openPopover: () => void;
  closePopover: () => void;
  addListsToNavigationTree: (navigationLists: NavigationListObject[]) => void;
  deleteLastListsFromNavigationTree: () => void;
  setNavigationContentName: (name: string | null) => void;
  showBackButtonOnHome: () => void;
  hideBackButtonOnHome: () => void;
  resetAccountNavigation: () => void;
}

export const initialState: AccountState = {
  isPopoverOpen: false,
  isBackButtonOnHomeVisible: true,
  navigationTree: [],
  navigationContentName: null,
}

export const useAccountNavigationStore = createWithEqualityFn<AccountState & AccountActions>()((set) => ({
  ...initialState,
  openPopover: () => set({ isPopoverOpen: true }),
  closePopover: () => set({ isPopoverOpen: false }),
  addListsToNavigationTree: (navigationLists: NavigationListObject[]) => {
    return set((state) => ({
      navigationTree: [...state.navigationTree, navigationLists],
    }));
  },
  deleteLastListsFromNavigationTree: () => {
    return set((state) => ({
      navigationTree: state.navigationTree.slice(0, -1),
    }));
  },
  setNavigationContentName: (name: string | null) => set({ navigationContentName: name }),
  showBackButtonOnHome: () => set({ isBackButtonOnHomeVisible: true }),
  hideBackButtonOnHome: () => set({ isBackButtonOnHomeVisible: false }),
  resetAccountNavigation: () => set(initialState),
}), shallow);
