import React from "react";
import { DetailLayoutProps } from "@companion-core/shared/app/Interfaces/Blocks";
import { Banner } from "@companion-core/web/src/Components/Banner";
import "Assets/Styles/scss/detailLayout.scss";
import { useMediaQuery } from "@companion-core/web/src/Utilities/mediaQuery";

export const DetailLayout = ({
  mediaUrl = "",
  title,
  subtitle,
  description = "",
}: DetailLayoutProps) => {
  const isMobileSize = useMediaQuery("(max-width: 768px)");

  return (
    <div data-testid="block-detail" className="detail-layout">
      {!isMobileSize && (
        <div data-testid="block-detail__banner" className="detail-layout__banner">
          <Banner title={title} imageUrl={mediaUrl} boxShadow={true} />
        </div>
      )}
      <div className="detail-layout__content">
        {isMobileSize && title && (
          <span
            data-testid="block-detail__content-title"
            className="title-5 text-align-center detail-layout__content-title">
            {title}
          </span>
        )}
        {!isMobileSize && subtitle && (
          <span
            data-testid="block-detail__content-subtitle"
            className="title-5 text-align-center"
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        )}
        <div
          data-testid="block-detail__content-description"
          className="body-text-6 margin-top-16"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </div>
  );
};
