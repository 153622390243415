const metaColorTheme : Record<string, string> = {
  default: "--color-background-primary",
  concierge: "--color-background-secondary",
}

export function setMetaThemeColor(theme: string) {
  const rootElement = document.querySelector(":root");
  if (!rootElement) return;
  const color = getComputedStyle(rootElement).getPropertyValue(metaColorTheme[theme] ?? metaColorTheme.default);
  document.querySelector(`meta[name="${"theme-color"}"]`)?.setAttribute("content", color);
}

export function getColorFromCssVariable(color: string): string | undefined {
  const rootElement = document.querySelector(":root");
  if (!rootElement) return;
  return getComputedStyle(rootElement).getPropertyValue(color);
}