import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@companion-core/shared/app/Components/Button";
import { RegisterContentProps } from "./index";
import "Assets/Styles/scss/register.scss";

const RegisterContent = ({ state, redirectCallback }: RegisterContentProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const content = () => {
    switch (state) {
      case "register-member-to-verify":
        return <div className="register__member-to-verify">
          <div data-testid="register__member-to-verify--title" className="title-1">
            {t("register.member-to-verify.title")}
          </div>
          <div
            data-testid="register__member-to-verify--body"
            className="register__member-to-verify-body body-text-4"
          >
            <span dangerouslySetInnerHTML={{ __html: t("register.member-to-verify.text")}} />
              <Link
                data-testid="register__link--retry"
                to="/register/member"
                className="body-text-3"
                onClick={() => redirectCallback()}>
                  {t("register.member-to-verify.link")}
              </Link>
          </div>
        </div>
      case "register-member-not-found":
        return <div className="register__member-not-found">
          <div data-testid="register__member-not-found--title" className="title-1">
            {t("register.member-not-found.title")}
          </div>
          <div
            data-testid="register__member-not-found--body"
            className="register__member-not-found-body body-text-4"
          >
            <div dangerouslySetInnerHTML={{ __html: t("register.member-not-found.text")}} />
          </div>
          <Button
            testID="register__member-not-found--button"
            classNames={["register__member-not-found-button"]}
            label={t("register.member-not-found.button")}
            onClick={() => {redirectCallback()}}
          />
        </div>
      case "register-member-already-verified":
        return <div data-testid="register__member-already-verified">
          <div data-testid="register__member-already-verified--title" className="title-1">
            {t("register.member-already-verified.title")}
          </div>
          <div
            data-testid="register__member-already-verified--body"
            className="register__member-already-verified-body body-text-4"
          >
            <span dangerouslySetInnerHTML={{ __html: t("register.member-already-verified.text")}} />
          </div>
          <Button
            testID="register__member-already-verified--button"
            classNames={["register__member-already-verified-button"]}
            label={t("register.member-already-verified.button")}
            onClick={() => {navigate("/login")}}
          />
        </div>
      case "register-error":
        return <div data-testid="register__error">
          WIP register-error
        </div>
      default:
        break;
    }
  }

  return content(); 
}

export default RegisterContent;