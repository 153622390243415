/**
 * Enum for tri-state values.
 * @readonly
 * @enum {string}
 */
const ChaptersEnum: { [key: string]: string } = {
  Fail: "fail",
  Login: "login",
  Registration: "registration",
  Bell: "bell",
  Home: "home",
  ResetPassword: "reset_password",
  ResetPasswordConfirmation: "reset_password_confirmation",
};

export default ChaptersEnum;
