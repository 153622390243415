import React from "react";
import { NavigationItemProps } from "./index";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import "@companion-core/web/src/Assets/Styles/scss/navigation.scss";

export const NavigationItem = ({
  name,
  label,
  leftIcon,
  withRightIcon = true,
}: NavigationItemProps) => {
  return (
    <div data-testid={`navigation-item--${name}`} className="navigation-item">
      {leftIcon && (
        <FontIcon
          data-testid={`navigation-item--${name}__left-icon`}
          className="navigation-item__left-icon"
          name={leftIcon}
          size={24}
        />
      )}
      <span
        data-testid={`navigation-item--${name}__label`}
        className="navigation-item__label body-text-4">
        {label}
      </span>
      {withRightIcon && (
        <FontIcon
          data-testid={`navigation-item--${name}__right-icon`}
          className="navigation-item__right-icon"
          name="JP-Arrow-right-bold"
          size={24}
        />
      )}
    </div>
  );
};
