import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";

// Components
import Navigation from "Navigation/Navigation";
import Header from "./Components/Header";
import Footer from "./Components/Footer";

// Styles
import "Assets/Styles/scss/global.scss";
import colors from "Assets/Styles/scss/_exports.module.scss";

// Document Title
import { usePopulateMember } from "@companion-core/shared/app/Hooks/member";
import { AccountPopover } from "@companion-core/web/src/Components/AccountPopover";
import { TabBar } from "@companion-core/shared/app/Components/TabBar";
import { ConfigProvider, Spin } from "antd";
import { useI18nStore } from "@companion-core/shared/app/Store/i18nStore";
import { useAuthStore } from "@companion-core/shared/app/Store/authStore";
import { updateDocumentTitle } from "Utilities/title";
import { hasCodeQueryParam } from "Utilities/navigation";
import { getAntdLocale, getLang } from "Utilities/lang";
import { setInterceptors } from "@companion-core/shared/app/Utils/interceptors";
import { getModule } from "@companion-core/shared/app/Utils/config";
import { onBeforeSSOLogin } from "@companion-core/shared/app/Utils/sso";
import { useQueryClient } from "@tanstack/react-query";
import { useConfigStore } from "@companion-core/shared/app/Store/configStore";

interface AppContentProps {
  showHeaderAndFooter?: boolean;
}

const ActivityIndicator = () => {
  return (
    <div className="app-loader">
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: colors.textPrimary,
            fontFamily: "Primary",
          },
        }}>
        <Spin data-testid="app__loader" size="large" />
      </ConfigProvider>
    </div>
  );
};

// Render the application content.
const AppContent = ({ showHeaderAndFooter = true }: AppContentProps) => {
  const { antdLocale } = useI18nStore((state) => ({ antdLocale: state.antdLocale }));

  return (
    <ConfigProvider
      locale={antdLocale ?? getAntdLocale(getLang())}
      theme={{
        token: {
          colorPrimary: colors.textPrimary,
          fontFamily: "Primary",
        },
      }}>
      <div className="app-container">
        {showHeaderAndFooter && <Header />}
        <div className="page-content">
          <Navigation />
        </div>
        <AccountPopover />
        {showHeaderAndFooter && <TabBar />}
        {showHeaderAndFooter && <Footer />}
      </div>
    </ConfigProvider>
  );
};

// Trigger the usePopulateMember hook and render AppContent when member is populated.
const AppContentWithMemberHook = ({ showHeaderAndFooter = true }: AppContentProps) => {
  const isPopulatedMember = usePopulateMember();

  return isPopulatedMember ? (
    <AppContent showHeaderAndFooter={showHeaderAndFooter} />
  ) : (
    <ActivityIndicator />
  );
};

// Represents the main application component.
const App = () => {
  const navigate = useNavigate();
  const { authenticated } = useAuthStore((state) => ({ authenticated: state.authenticated }));
  const [searchParams] = useSearchParams();
  const isSSOCompanionWithCodeParam = useMemo(
    () => hasCodeQueryParam(searchParams),
    [searchParams],
  );
  const pathname = useLocation().pathname;
  const pageSlug = pathname.split("/").length > 1 ? pathname.split("/")[1] : "";
  const isCompanionSSOOnly = getModule("sso")?.options?.ssoOnly;
  const { failure, interceptorLoaded, fetching } = useConfigStore();
  const showHeaderAndFooter = useMemo(
    () => !failure && (!isCompanionSSOOnly || (authenticated && !isSSOCompanionWithCodeParam)),
    [isSSOCompanionWithCodeParam, isCompanionSSOOnly, authenticated, failure],
  );
  const isMyAccountPage = useMemo(() => pageSlug === "my-account", [pageSlug]);
  const [isLoaded, setIsLoaded] = useState(false);
  const queryClient = useQueryClient();

  if (!isLoaded && !interceptorLoaded) {
    setIsLoaded(true);
    setInterceptors(navigate);
  }

  useEffect(() => {
    if (isSSOCompanionWithCodeParam && authenticated) {
      onBeforeSSOLogin();
    }
  }, []);

  useEffect(() => {
    updateDocumentTitle(pageSlug);
    if (isMyAccountPage) {
      if (!queryClient.isFetching({ queryKey: ["member"] })) {
        queryClient.invalidateQueries({ queryKey: ["member"] });
      }
    }
  }, [pageSlug]);

  return fetching ? (
    <ActivityIndicator />
  ) : !isSSOCompanionWithCodeParam ? (
    <AppContentWithMemberHook showHeaderAndFooter={showHeaderAndFooter} />
  ) : (
    <AppContent showHeaderAndFooter={showHeaderAndFooter} />
  );
};

export default App;
