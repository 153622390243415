export * from "./BellHome";

export interface CommunicationChannel {
  name: string;
  icon: string;
  phoneNumber?: string;
  enabled?: boolean;
  callback: () => void;
}

export const getInitialChannels = (
  homeEntries,
  askCallback,
  callCallback,
  whatsAppCallback,
): CommunicationChannel[] => ([
  {
    name: "ask",
    icon: "JP-Document-copy",
    enabled: homeEntries?.ask.enabled,
    callback: askCallback,
  },
  {
    name: "call",
    icon: "JP-Phone",
    enabled: false,
    callback: callCallback,
  },
  {
    name: "whatsapp",
    icon: "JP-Whatsapp",
    enabled: homeEntries?.whatsapp.enabled,
    callback: whatsAppCallback,
  },
]);