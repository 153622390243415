/* eslint-disable camelcase */
import { trackPage, trackClick } from ".";
import PagesEnum from "./enums/pages";
import ChaptersEnum from "./enums/chapters";
import LevelsEnum from "./enums/levels";
import ClicTypes from "./enums/clic-types";

// Pages tracking

export function trackHomePageBell() {
  return trackPage({
    page: PagesEnum.Bell,
    page_chapter1: ChaptersEnum.Home,
    site_level2: LevelsEnum.Bell,
  });
}

export function trackStepBell(page: string, page_chapter1: string, page_chapter2 = "", page_chapter3 = "") {
  return trackPage({
    page,
    page_chapter1: page_chapter1 || ChaptersEnum.Bell,
    page_chapter2,
    page_chapter3,
    site_level2: LevelsEnum.Bell,
  });
}

// Clicks tracking

export function trackClickBell(clickName: string, click_chapter1 = "", click_chapter2 = "", click_chapter3 = "") {
  return trackClick({
    click: clickName,
    click_type: ClicTypes.Navigation,
    click_chapter1: click_chapter1 || ChaptersEnum.Bell,
    click_chapter2,
    click_chapter3,
    site_level2: LevelsEnum.Bell,
  });
}