import client from "@johnpaul/jp-api-client";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { isWeb } from "../Utils/platform";
import { getI18nUrl } from "../Utils/config";
import { useI18nStore } from "@companion-core/shared/app/Store/i18nStore";

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18next
  .use(initReactI18next) // binding i18next instance to an internal store
  .init({
    returnNull: false,
    interpolation: {
      prefix: "{",
      suffix: "}",
    },
    simplifyPluralSuffix: true,
    compatibilityJSON: "v3",
    resources: {},
  });

i18next.languages = [""];

const loadedLanguages: string[] = [];

export function setI18nLanguage(lang: string): string {
  useI18nStore.getState().setLocale(lang);
  i18next.changeLanguage(lang);
  client.defaults.headers["Accept-Language"] = lang;
  if (isWeb()) document.querySelector("html")?.setAttribute("lang", lang);
  return lang;
}

export async function loadLanguageAsync(lang: string): Promise<string> {
  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  try {
    const result = await fetch(`${getI18nUrl()}/${lang}.json`);
    const messages = await result.json();
    i18next.addResourceBundle(lang, "translation", messages);
    loadedLanguages.push(lang);
    return Promise.resolve(setI18nLanguage(lang));
  } catch (error) {
    // If fetching i18n files failed => display error for debugging
    console.error(error);
    return Promise.reject(error);
  }
}

export default i18next;
