import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Spin } from "antd";
import { ListLayoutProps } from "@companion-core/shared/app/Interfaces/Blocks";
import { ComponentData } from "@companion-core/shared/app/Interfaces/API";
import { ComponentCard } from "@companion-core/shared/app/Components/Cards/ComponentCard";
import "@companion-core/web/src/Assets/Styles/scss/listLayout.scss";

export const ListLayout = ({ title, queryComponents, blockId }: ListLayoutProps) => {
  const queryClient = useQueryClient();
  const isQueryComponentsFetching = queryClient.isFetching({
    queryKey: ["block-item-components"],
  });

  return (
    <section data-testid={`list-layout--${blockId}`} className="list-layout">
      <h1
        data-testid={`list-layout__title--${blockId}`}
        className="list-layout__title super-title-1">
        {title}
      </h1>
      {isQueryComponentsFetching ? (
        <Spin data-testid="list-layout__loader" className="list-layout__loader" size="large" />
      ) : (
        queryComponents && (
          <div data-testid={`list-layout__content--${blockId}`} className="list-layout__content">
            {queryComponents.map((item: ComponentData) => (
              <ComponentCard key={`list-layout-card-${item.id}`} component={item} />
            ))}
          </div>
        )
      )}
    </section>
  );
};
