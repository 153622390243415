import React, { useState, useCallback } from "react";

import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

import { MapProps } from "./Map.model";
import Pin from "@companion-core/shared/app/Assets/images/pin.svg";
import { getColorFromCssVariable } from "@companion-core/web/src/Utilities/meta";

export const Map = ({ googleMapApiKey, className, iconFamily, center, zoom }: MapProps) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleMapApiKey,
  });

  const [map, setMap] = useState<google.maps.Map | null>(null);

  const onLoad = useCallback(function callback(map: google.maps.Map) {
    center && map.setCenter(center);
    map.setOptions({ minZoom: 1, maxZoom: 15 });
    zoom && map.setZoom(zoom);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback() {
    setMap(null);
  }, []);

  return (
    isLoaded && (
      <GoogleMap
        mapContainerClassName={className}
        center={center}
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}>
        {React.cloneElement(
          <Marker
            icon={{
              url: Pin,
              size: new window.google.maps.Size(34, 42),
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(17, 42),
              labelOrigin: new window.google.maps.Point(17, 18),
            }}
            label={{
              fontFamily: "JP-icons",
              color: getColorFromCssVariable("--color-text-secondary") ?? "white",
              fontSize: "18px",
              text: iconFamily ?? "JP-Concierge-bell",
            }}
            position={new google.maps.LatLng(center?.lat ?? 0, center?.lng ?? 0)}
          />,
          { map },
        )}
      </GoogleMap>
    )
  );
};
