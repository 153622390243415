import { useMutation, useQuery } from "@tanstack/react-query";
import { client } from "@johnpaul/jp-api-client";

export const useRequests = () => {
  return useQuery({
    queryKey: ["my-requests"],
    queryFn: () => client.requests.fetch("me", { "items-per-page": 200 }),
    cacheTime: 0,
  });
};

type BellRequestVariables = {
  family: string;
  payload: Record<string, unknown>;
};

export const useBellRequest = () =>
  useMutation({
    mutationFn: async ({ family, payload }: BellRequestVariables) =>
      await client.requests.post(family, payload),
    retry: false,
    cacheTime: 0,
  });
