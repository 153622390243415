import React, { ReactElement, useMemo } from "react";
import { PostListCarouselLayout } from "@companion-core/shared/app/Components/Blocks/Layouts/PostListCarouselLayout";
import { PostListAlternateLayout } from "@companion-core/shared/app/Components/Blocks/Layouts/PostListAlternateLayout";
import { BlockProps, PostListLayoutProps } from "@companion-core/shared/app/Interfaces/Blocks";
import { ComponentData } from "@companion-core/shared/app/Interfaces/API";
import {
  getComponentsCard,
  getSortedByOrderComponents,
} from "@companion-core/shared/app/Utils/componentHelper";

const PostListLayouts: {
  [key: string]: ({ cards, blockId }: PostListLayoutProps) => ReactElement;
} = {
  "post-list-carousel": PostListCarouselLayout,
  "post-list-alternate": PostListAlternateLayout,
};

export const PostList = ({ simpleBlockItems, contentProps }: BlockProps) => {
  const simpleComponents: ComponentData[] = useMemo(
    () => getSortedByOrderComponents(simpleBlockItems),
    [simpleBlockItems],
  );
  const cards: ComponentData[] = useMemo(
    () => getComponentsCard(simpleComponents),
    [simpleComponents],
  );
  const { displayType } = contentProps;

  if (!PostListLayouts[displayType]) {
    return <div>The component {displayType} has not been created yet.</div>;
  }

  const Layout = PostListLayouts[displayType];
  return <Layout cards={cards} blockId={contentProps.id} />;
};
