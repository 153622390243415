import { useQuery } from "@tanstack/react-query";
import { client } from "@johnpaul/jp-api-client";
import { getClientTokenFn } from "@companion-core/shared/app/Utils/apiClient";
import { getClientCredentials } from "@companion-core/shared/app/Utils/config";

export const useAuthClientToken = (enabled: boolean) => {
  return useQuery({
    queryKey: ["authClientToken"],
    queryFn: getClientTokenFn,
    enabled,
  });
};

export const useAuthTokenSSOClient = (authCode: string, enabled = true) => {
  return useQuery({
    queryKey: ["authTokenSSOClient"],
    queryFn: () =>
      client.auth.v2.getTokenSSOClient(
        getClientCredentials().clientId,
        getClientCredentials().clientSecret,
        authCode,
      ),
    enabled,
    retry: false,
    cacheTime: 0,
  });
};
