/* eslint-disable camelcase */
import { trackClick } from ".";
import ChaptersEnum from "./enums/chapters";
import LevelsEnum from "./enums/levels";
import ClicTypes from "./enums/clic-types";
import ClicsEnum from "./enums/clics";

export function trackClickHelp() {
    return trackClick({
      click_type: ClicTypes.Navigation,
      click: ClicsEnum.Help,
      click_chapter1: ChaptersEnum.Registration,
      site_level2: LevelsEnum.Registration,
    });
}