import React, { useEffect, useRef, useState } from "react";
import { IndependentDynamicForm } from "@companion-core/shared/app/Components/DynamicForm/IndependentDynamicForm";
import { getJSONFromFile } from "@companion-core/shared/app/Assets/forms/needHelp/jsonLoader";
import { useMemberStore } from "@companion-core/shared/app/Store/memberStore";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@companion-core/web/src/Utilities/mediaQuery";
import { Banner } from "@companion-core/web/src/Components/Banner";
import { getModule } from "@companion-core/shared/app/Utils/config";
import { useAuthStore } from "@companion-core/shared/app/Store/authStore";
import { DynamicFormJson, Field } from "@companion-core/shared/app/Interfaces/wizard";
import { APIMemberData } from "@companion-core/shared/app/Interfaces/API";
import { useAssistance } from "@companion-core/shared/app/Queries/assistance";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import { formatPayload } from "@companion-core/shared/app/Utils/assistance";
import { WizardStoreProvider } from "@companion-core/shared/app/Store/wizardStore";
import { useClientToken } from "@companion-core/shared/app/Hooks/clientToken";
import DOMPurify from "dompurify";
import { trackClickNeedHelpSubmit, trackPageNeedHelp, trackPageNeedHelpConfirmation } from "@companion-core/shared/app/tracking/needHelp";
import "@companion-core/web/src/Assets/Styles/scss/needHelp.scss";

export const NeedHelp = () => {
  const { t } = useTranslation();
  const scrollRef = useRef();
  const isMobileSize = useMediaQuery("(max-width: 768px)");
  const urlBanner = getModule("need-help").options.img;
  const { authenticated } = useAuthStore((state) => ({ authenticated: state.authenticated }));
  const formName = authenticated ? "needHelpOn" : "needHelpOff";
  const baseJson = getJSONFromFile(`needHelp/${formName}.json`);
  const fetchClientToken = useClientToken();
  const [jsonForm, setJsonForm] = useState<DynamicFormJson>(baseJson);
  const { data: dataMember } = useMemberStore();
  const [requestError, setRequestError] = useState<string | null>(null);
  const [requestValidated, setRequestValidated] = useState<string | null>(null);
  const { mutateAsync: sendAssistanceRequest } = useAssistance();

  useEffect(() => {
    if (dataMember) prefillForm(dataMember);
  }, [dataMember]);

  useEffect(() => {
    trackPageNeedHelp();
  }, []);

  const prefillForm = (formData: APIMemberData | null) => {
    baseJson?.steps[0]?.fields.forEach((field: Field) => {
      // TODO: delete this if when the API will be fixed (V2)
      if (
        field.name === "personal_mobilePhone" &&
        // @ts-ignore: temp fix
        formData?.phones?.[0]?.phone &&
        // @ts-ignore: temp fix
        formData?.phones?.[0]?.type === "personal-mobile"
      ) {
        // @ts-ignore: temp fix
        field.value = formData?.phones?.[0]?.phone;
      } else {
        field.value = formData?.[field.name];
      }
    });
    setJsonForm(baseJson);
  };

  const onSubmitForm = async (payload: Record<string, unknown>) => {
    trackClickNeedHelpSubmit();
    setRequestValidated(null);
    const formattedPayload = formatPayload(payload, dataMember);
    try {
      await sendAssistanceRequest({ payload: formattedPayload });
      setJsonForm(baseJson);
      if (requestError) setRequestError(null);
      setRequestValidated(t("need-help.confirmation.title"));
      trackPageNeedHelpConfirmation({ error: false })
      scrollRef.current?.scrollIntoView();
    } catch (error) {
      setJsonForm(baseJson);
      setRequestError(t("need-help.error.unknown"));
      trackPageNeedHelpConfirmation({ error: true })
      scrollRef.current?.scrollIntoView();
    }
  };

  return (
    <WizardStoreProvider>
      <div
        ref={scrollRef}
        data-testid="need-help"
        className="need-help d-flex flex-direction-column align-items-center">
        {!isMobileSize && (
          <div data-testid="need-help__banner" className="need-help__banner">
            <Banner title={t("need-help.description.title")} imageUrl={urlBanner} />
          </div>
        )}
        <div data-testid="need-help__title" className="need-help__title">
          <h2 className="title-5">{t("need-help.description.subtitle")}</h2>
        </div>
        <div data-testid="need-help__form" className="need-help__form">
          {(requestValidated || requestError) && (
            <div
              className={`body-text-9 need-help__status need-help__status-${
                requestError ? "error" : "success"
              }`}
              data-testid={`need-help__status-${requestError ? "error" : "success"}`}>
              <FontIcon name="JP-Circle-check" size={16} className="need-help__status-icon" />
              {requestValidated || requestError}
            </div>
          )}
          <span
            data-testid="need-help__text"
            className="need-help__text body-text-4"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(t("need-help.description.text"), { ALLOWED_TAGS: ["br"] }),
            }}
          />
          <div className="margin-top-16">
            <IndependentDynamicForm
              name={formName}
              initialFormJSON={jsonForm}
              onSubmitForm={onSubmitForm}
            />
          </div>
        </div>
      </div>
    </WizardStoreProvider>
  );
};
