import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow"

interface ConfigState {
  config: Record<string, any> | null,
  failure: boolean;
  fetching: boolean;
  interceptorLoaded: boolean;
  deepLinkPrefix: string | null,
}

interface ConfigActions {
  setConfig: (config: Record<string, unknown>) => void;
  setFailure: (failure: boolean) => void;
  setFetching: (fetching: boolean) => void;
  setInterceptorLoaded: (interceptorLoaded: boolean) => void;
  setDeepLinkPrefix: (deepLinkPrefix: string) => void;
}

export const initialState: ConfigState = {
  config: null,
  failure: false,
  fetching: false,
  interceptorLoaded: false,
  deepLinkPrefix: null,
}

export const useConfigStore = createWithEqualityFn<ConfigState & ConfigActions>()((set) => ({
  ...initialState,
  setConfig: (config: Record<string, unknown>) =>  set({ config }),
  setFailure: (failure: boolean) => set({ failure }),
  setFetching: (fetching: boolean) => set({ fetching }),
  setInterceptorLoaded: (interceptorLoaded: boolean) => set({ interceptorLoaded }),
  setDeepLinkPrefix: (deepLinkPrefix: string) => set({ deepLinkPrefix }),
}), shallow);