import React from "react";
import { useTranslation } from "react-i18next";
import { Image } from "antd";
import { getConfig, getFooterConfig } from "@companion-core/shared/app/Utils/config";
import { useMediaQuery } from "@companion-core/web/src/Utilities/mediaQuery";
import { LanguageSwitcher } from "./LanguageSwitcher";
import { EventLink } from "@companion-core/shared/app/Components/EventLink";
import { NavLinkItem } from "@companion-core/shared/app/Interfaces/navigation";

const Footer = () => {
  const isMobileSize = useMediaQuery("(max-width: 768px)");
  const { t } = useTranslation();

  if (isMobileSize) {
    return <></>;
  }

  return (
    <div className="footer">
      <div className="footer__links body-text-8">
        {getConfig()?.options?.footerLinks?.map(({ name, type, link, path }: NavLinkItem) => (
          <EventLink
            data-testid={`footer__link--${name}`}
            key={name}
            name={name}
            type={type}
            link={link}
            path={path}>
            {t(`common.links.${name}`)}
          </EventLink>
        ))}
      </div>

      <div className="footer__language-switcher">
        <LanguageSwitcher />
      </div>

      {/* Logo container */}
      <div className="footer__logo">
        <Image width={80} height={44} src={"/" + getFooterConfig()?.logo} preview={false} />
      </div>
    </div>
  );
};

export default Footer;
