import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Image, Popover } from "antd";
import { useAccountNavigationStore } from "@companion-core/shared/app/Store/accountNavigationStore";
import { useAuthStore } from "@companion-core/shared/app/Store/authStore";
import { useMemberStore } from "@companion-core/shared/app/Store/memberStore";
import { getConfig, getModule } from "@companion-core/shared/app/Utils/config";
import {
  NavigationItemObject,
  NavigationListObject,
} from "@companion-core/shared/app/Interfaces/navigation";
import { NavigationList } from "@companion-core/shared/app/Components/NavigationList";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import {
  LanguageSelector,
  PersonalInformation,
} from "@companion-core/shared/app/Components/MyAccount/Sections";
import { PopIn } from "@companion-core/shared/app/Components/PopIn";
import { useMediaQuery } from "@companion-core/web/src/Utilities/mediaQuery";
import { WizardStoreProvider } from "@companion-core/shared/app/Store/wizardStore";
import "Assets/Styles/scss/myAccount.scss";
import { useQueryClient } from "@tanstack/react-query";

export const AccountPopover = () => {
  const navigate = useNavigate();
  const isMobileSize = useMediaQuery("(max-width: 768px)");
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const global = getConfig()?.global;
  const navigationLists: NavigationListObject[] = getModule("my-account")?.navigationLists ?? [];
  const [currentNavigationLists, setCurrentNavigationLists] = useState<NavigationListObject[]>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isLogoutPopInOpen, setIsLogoutPopInOpen] = useState<boolean>(false);
  const { logout } = useAuthStore((state) => ({
    logout: state.logout,
  }));
  const { resetMember } = useMemberStore((state) => ({
    resetMember: state.resetMember,
  }));
  const {
    isPopoverOpen,
    isBackButtonOnHomeVisible,
    navigationTree,
    navigationContentName,
    closePopover,
    addListsToNavigationTree,
    deleteLastListsFromNavigationTree,
    setNavigationContentName,
    resetAccountNavigation,
  } = useAccountNavigationStore((state) => ({
    isPopoverOpen: state.isPopoverOpen,
    isBackButtonOnHomeVisible: state.isBackButtonOnHomeVisible,
    navigationTree: state.navigationTree,
    navigationContentName: state.navigationContentName,
    closePopover: state.closePopover,
    addListsToNavigationTree: state.addListsToNavigationTree,
    deleteLastListsFromNavigationTree: state.deleteLastListsFromNavigationTree,
    setNavigationContentName: state.setNavigationContentName,
    resetAccountNavigation: state.resetAccountNavigation,
  }));

  useEffect(() => {
    if (isPopoverOpen) {
      if (!queryClient.isFetching({ queryKey: ["member"] })) {
        queryClient.invalidateQueries({ queryKey: ["member"] });
      }
      if (!navigationTree.length) {
        addListsToNavigationTree(navigationLists);
      }
    }
    setIsLoaded(true);
  }, [isPopoverOpen]);

  useEffect(() => {
    if (navigationTree.length) {
      setCurrentNavigationLists(navigationTree[navigationTree.length - 1]);
    } else {
      setCurrentNavigationLists([]);
    }
  }, [navigationTree]);

  useEffect(() => {
    if (!isMobileSize && isLoaded) {
      cleanAndClosePopover();
    }
  }, [isMobileSize]);

  const onLogoutClicked = () => {
    setIsLogoutPopInOpen(false);
    logout();
    resetMember();
    cleanAndClosePopover();
    navigate("login");
  };

  const cleanAndClosePopover = () => {
    resetAccountNavigation();
    closePopover();
  };

  const showBackButton = useMemo(
    () =>
      isBackButtonOnHomeVisible ||
      (!isBackButtonOnHomeVisible && (navigationTree.length > 1 || navigationContentName)),
    [isBackButtonOnHomeVisible, navigationTree, navigationContentName],
  );

  const handleBackButton = () => {
    if (navigationTree.length <= 1 && !navigationContentName) {
      cleanAndClosePopover();
    } else if (navigationContentName) {
      setNavigationContentName(null);
    } else {
      deleteLastListsFromNavigationTree();
    }
  };

  const getAccountMobileHeader = () => {
    return (
      <div data-testid="account-popover__title" className="account-popover__title">
        {showBackButton && (
          <FontIcon
            data-testid="account-popover__back"
            name="JP-Arrow-left-bold"
            size={24}
            onClick={handleBackButton}
          />
        )}
        <Image
          data-testid="account-popover__logo"
          src={"/" + global?.header?.logo}
          preview={false}
          onClick={() => {
            cleanAndClosePopover();
            navigate("/");
          }}
        />
      </div>
    );
  };

  const navigationContentMap: { [key: string]: ReactElement } = {
    "personal-information": <PersonalInformation />,
    language: <LanguageSelector />,
  };

  const AccountNavigationList = () => {
    return (
      <>
        {currentNavigationLists.map((navigationList: NavigationListObject) => (
          <NavigationList
            key={navigationList.name}
            name={navigationList.name}
            navigationItems={navigationList.navigationItems}
            onEnterInternalNavigation={(navigationItem: NavigationItemObject) => {
              if (navigationItem.path) {
                cleanAndClosePopover();
                navigate(navigationItem.path);
              }
            }}
            onEnterNestedNavigation={(navigationItem: NavigationItemObject) => {
              if (navigationItem.nestedNavigationLists) {
                addListsToNavigationTree(navigationItem.nestedNavigationLists);
              } else {
                setNavigationContentName(navigationItem.name);
              }
            }}
            onEnterLogoutNavigation={() => setIsLogoutPopInOpen(true)}
          />
        ))}
      </>
    );
  };

  const AccountMobileContent = () => {
    return (
      <div data-testid="account-popover__content" className="account-popover__content">
        {navigationContentName ? (
          <WizardStoreProvider>{navigationContentMap[navigationContentName]}</WizardStoreProvider>
        ) : (
          <AccountNavigationList />
        )}
        {isLogoutPopInOpen && (
          <PopIn
            testID="account-popover__logout-pop-in"
            content={<span className="body-text-6">{t("common.logout.pop-in.content")}</span>}
            primaryButton={{
              text: t("common.logout.pop-in.confirm.label"),
              onClick: () => onLogoutClicked(),
            }}
            secondaryButton={{
              text: t("common.logout.pop-in.cancel.label"),
              onClick: () => setIsLogoutPopInOpen(false),
            }}
            onClose={() => setIsLogoutPopInOpen(false)}
          />
        )}
      </div>
    );
  };

  return (
    <Popover
      title={getAccountMobileHeader()}
      content={<AccountMobileContent />}
      trigger="click"
      arrow={false}
      autoAdjustOverflow={false}
      open={isPopoverOpen}
      overlayClassName="account-popover"
    />
  );
};
