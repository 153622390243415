// Note: This file is used to load the JSON files for the dynamic forms in the PersonalInformation component
// The JSON files are located in the shared/app/Assets/forms/myAccount folder
// ReactNative does not support the dynamic loading of JSON files, so we have to import them manually
import { DynamicFormJson } from "@companion-core/shared/app/Interfaces/wizard";
import personalInformationJson from "./personalInformation/personalInformation.json";

const myAccountFormsMap: Record<string, Record<string, DynamicFormJson> | DynamicFormJson> = {
  personalInformation: {
    personalInformation: personalInformationJson,
  },
};

export const createDeepCopy = (obj: DynamicFormJson) => {
  return JSON.parse(JSON.stringify(obj)) as DynamicFormJson;
};

/* Get the JSON from the file path provided
 * @param path - the path to the JSON file
 * @returns the JSON object
 * */
export const getJSONFromFile = (path: string) => {
  const [myAccountMethod, myAccountForm] = path.replace(".json", "").split("/").slice(-2);
  const originalFile = myAccountFormsMap[myAccountMethod]?.[myAccountForm] ?? {};
  return createDeepCopy(originalFile);
};
