// Note: This file is used to load the JSON files for the dynamic forms in the NeedHelp component
// The JSON files are located in the shared/app/Assets/forms/myAccount folder
// ReactNative does not support the dynamic loading of JSON files, so we have to import them manually
import { DynamicFormJson } from "@companion-core/shared/app/Interfaces/wizard";
import needHelpOnJson from "./needHelpOn.json";
import needHelpOffJson from "./needHelpOff.json";

const needHelpFormsMap: Record<string, Record<string, DynamicFormJson> | DynamicFormJson> = {
  needHelpOn: needHelpOnJson,
  needHelpOff: needHelpOffJson,
};

export const createDeepCopy = (obj: DynamicFormJson) => {
  return JSON.parse(JSON.stringify(obj)) as DynamicFormJson;
};

/* Get the JSON from the file path provided
 * @param path - the path to the JSON file
 * @returns the JSON object
 * */
export const getJSONFromFile = (path: string) => {
  const needHelpForm = path.replace(".json", "").split("/").slice(-1);
  const originalFile = needHelpFormsMap[needHelpForm] ?? {};
  return createDeepCopy(originalFile);
};
