import React from "react";
import { ButtonListLayoutProps } from "@companion-core/shared/app/Interfaces/Blocks";
import { getLang } from "@companion-core/web/src/Utilities/lang";
import { useMediaQuery } from "@companion-core/web/src/Utilities/mediaQuery";
import "@companion-core/web/src/Assets/Styles/scss/buttonListLayout.scss";

const AppleLangs: { [key: string]: string } = {
  en: "us",
  fr: "fr",
  de: "de",
  it: "it",
};

export const ButtonListLayout = ({ title, subtitle, cards }: ButtonListLayoutProps) => {
  const lang = getLang();
  const isMobileSize = useMediaQuery("(max-width: 768px)");

  return (
    <section data-testid="block-buttonlist" className="button-list-layout">
      <h2
        className={`${isMobileSize ? "title-1" : "super-title-1"} button-list-layout__title`}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      {subtitle && (
        <span
          className="title-8 text-align-center margin-top-16"
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      )}

      <div className="button-list-layout__store-button">
        {cards.map((content, index) => {
          const isApple = content.button_link?.includes("https://apps.apple.com");

          const buttonImage = isApple
            ? `https://apple-resources.s3.amazonaws.com/media-badges/download-on-the-app-store/black/${
                AppleLangs[lang] ? lang + "-" + AppleLangs[lang] : "en-us"
              }.svg`
            : `https://play.google.com/intl/en/badges/static/images/badges/${lang}_badge_web_generic.png`;

          return (
            <a
              key={`button-${index}`}
              href={content?.button_link}
              target="_blank"
              rel="noreferrer"
              data-testid={`block-buttonlist-button-${isApple ? "apple" : "google"}`}>
              <img
                className={
                  "button-list-layout__store-button--" + (isApple ? "apple_btn" : "google_btn")
                }
                alt={content.button_title}
                src={buttonImage}
              />
            </a>
          );
        })}
      </div>
    </section>
  );
};
