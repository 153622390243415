import {
  DynamicFormJson,
  Field,
  FormTree,
  Step,
} from "@companion-core/shared/app/Interfaces/wizard";
import {
  GetNextTreeElementIndex,
  GetPreviousTreeElementIndex,
} from "@companion-core/shared/app/Utils/wizardHelper";
import { getJSONFromFile } from "@companion-core/shared/app/Assets/forms/concierge/jsonLoader";

export const getFirstStep = (jsonForm: DynamicFormJson): Step | null => {
  return getStepFromJson(jsonForm, 1);
};

export const hasFormPath = (field: Field): boolean => {
  return field.options?.some((option) => option?.formPath) ?? false;
};

/*
 * If the current step has already been added to formTree,
 * possesses a formPath (only feasible for Steps with a single field)
 * and selected value differs from the one in the tree, we will clear the entire tree after the current step.
 * It returns the index and the formTree (updated if necessary)
 * @param {number | null} index
 * @param {Step} actualStep
 * @param {FormTree} formTree
 * @param {(index: number) => void} deleteStepsAfterIndex
 * @returns {{index: number | null; formTree: FormTree }} index and formTree
 */
const refreshFormTree = (
  index: number | null,
  actualStep: Step,
  formTree: FormTree,
  deleteStepsAfterIndex: (index: number) => void,
): { index: number | null; formTree: FormTree } => {
  if (index !== null && actualStep.fields.length === 1 && hasFormPath(actualStep.fields[0])) {
    const option = actualStep.fields[0].options?.find(
      (option) => option.value === actualStep.fields[0].value,
    );
    if (option?.formPath && option.formPath !== formTree[index].formPath) {
      deleteStepsAfterIndex(index - 1);
      formTree = formTree.slice(0, index); // we update the formTree locally
      index = null;
    }
  }
  return { index: index, formTree: formTree };
};

export const getNextStep = (
  actualJsonForm: DynamicFormJson,
  actualStep: Step,
  formTree: FormTree,
  addStepToFormTree: (stepNumber: number, formPath: string, formState: DynamicFormJson) => void,
  deleteStepsAfterIndex: (index: number) => void,
): { step: Step | null; jsonForm: DynamicFormJson } => {
  let jsonForm = actualJsonForm;
  let nextStepNumber = actualStep.order + 1;

  // check if next element already exists in the tree
  let index = GetNextTreeElementIndex(formTree, actualStep.order, actualJsonForm.jsonPath);

  // update index and formTree if necessary
  ({ index, formTree } = refreshFormTree(index, actualStep, formTree, deleteStepsAfterIndex));

  // if the next step is not in the same json file, we load it
  if (index !== null && formTree[index].formPath !== actualJsonForm.jsonPath) {
    jsonForm = formTree[index].savedForm ?? getJSONFromFile(formTree[index].formPath);
    nextStepNumber = formTree[index].stepNumber;
  }
  // if next element not in the tree, we check if the current step has a formPath
  else if (hasFormPath(actualStep.fields[0])) {
    const option = actualStep.fields[0].options?.find(
      (option) => option.value === actualStep.fields[0].value,
    );
    if (!option || !option.formPath) return { step: null, jsonForm: jsonForm };
    jsonForm = getJSONFromFile(option.formPath);
    nextStepNumber = 1;
  }
  const step: Step | null = getStepFromJson(jsonForm, nextStepNumber);

  // if the next step is not in the tree, we add it (if it exists)
  if (index === null && step) {
    addStepToFormTree(nextStepNumber, jsonForm.jsonPath, jsonForm);
  }

  return { step: step, jsonForm: jsonForm };
};

export const getPreviousStep = (
  actualJsonForm: DynamicFormJson,
  actualStepNumber: number,
  formTree: FormTree,
): { step: Step | null; jsonForm: DynamicFormJson } => {
  let jsonForm = actualJsonForm;
  let previousStepNumber = actualStepNumber - 1;
  const index = GetPreviousTreeElementIndex(formTree, actualStepNumber, actualJsonForm.jsonPath);
  if (index === null) {
    return { step: null, jsonForm: jsonForm };
  }
  // if the previous step is not in the same json file, we load it
  if (formTree[index].formPath !== actualJsonForm.jsonPath) {
    jsonForm = formTree[index].savedForm ?? getJSONFromFile(formTree[index].formPath);
    previousStepNumber = 1;
  }
  const step = getStepFromJson(jsonForm, previousStepNumber);
  return { step: step, jsonForm: jsonForm };
};

/**
 * Find step from the json form and a given number.
 * Returns the step if it exists. Returns null otherwise.
 * @param {DynamicFormJson} jsonForm
 * @param {number} stepNumberToRetrieve
 * @returns {Step | null}
 */
export const getStepFromJson = (
  jsonForm: DynamicFormJson,
  stepNumberToRetrieve: number,
): Step | null => jsonForm.steps.find((step: Step) => step.order === stepNumberToRetrieve) ?? null;

/**
 * Check if any step fields contain an error
 * @param {Step} step
 * @returns {boolean}
 */
export const isStepValid = (step: Step): boolean =>
  !step.fields.some((field) => {
    if (field.required && !field.value) {
      return true;
    }
    return field.errors && field.errors.length;
  });

/**
 * Check if any step fields contain a radioshape input
 * @param {Step} step
 * @returns {boolean}
 */
export const hasOnlyOneRadioShape = (step: Step) => step.fields[0]?.type === "radioshape";
