import React, { useState } from "react";
import { PopIn, PopInProps } from "@companion-core/shared/app/Components/PopIn";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";
import { CancelPopinProps } from ".";
import { useMemberStore } from "@companion-core/shared/app/Store/memberStore";
import { cancelRequest } from "@companion-core/shared/app/Utils/apiClient";
import "@companion-core/web/src/Assets/Styles/scss/myrequestsDetail.scss";

export const CancelPopin = ({ requestData, updateRequest, onClose }: CancelPopinProps) => {
  const { t } = useTranslation();
  const { requests, setMemberRequests } = useMemberStore();

  const popinInitialArgs: PopInProps = {
    testID: "request-detail__cancel-popin",
    icon: "JP-Circle-close",
    title: t("request.my-requests.detail.popin.cancel.title"),
    content: `${t("request.my-requests.detail.popin.cancel.text.pre")}${requestData.subject}${requestData.location ? t("request.my-requests.location.particle") + requestData.location.city as string : ""}${t("request.my-requests.detail.popin.cancel.text.post")}`,
    primaryButton: {
      text: t("request.my-requests.detail.popin.cancel.primary-button.text"),
      onClick: () => sendCancelRequest(),
    },
    secondaryButton: {
      text: t("request.my-requests.detail.popin.cancel.secondary-button.text"),
      onClick: () => onClose(),
    },
    onClose: () => onClose(),
    freeze: false,
  }

  const [popinArgs, setPopinArgs] = useState(popinInitialArgs);

  const sendCancelRequest = async () => {
    setPopinArgs({
      testID: "request-detail__cancel-popin",
      content: <Spin size="large" />,
      freeze: true,
    });
    try {
      const response = await cancelRequest(requestData.id, requestData.family);
      requests[requests.indexOf(requestData)] = { ...response.data.attributes, id: response.data.id };
      setMemberRequests(requests);
      updateRequest({ ...response.data.attributes, id: response.data.id });
      setPopinArgs({
        testID: "request-detail__cancel-popin",
        icon: "JP-Circle-check",
        title: t("request.my-requests.detail.popin.cancel-done.title"),
        content: t("request.my-requests.detail.popin.cancel-done.text"),
        primaryButton: {
          text: t("request.my-requests.detail.popin.cancel.close-button.text"),
          onClick: () => onClose(),
        },
        secondaryButton: undefined,
        freeze: false,
      });
    } catch (e) {
      setPopinArgs({
        testID: "request-detail__cancel-popin",
        icon: "JP-Circle-close",
        title: t("request.my-requests.detail.popin.cancel-error.title"),
        content: t("request.my-requests.detail.popin.cancel-error.text"),
        primaryButton: {
          text: t("request.my-requests.detail.popin.cancel.close-button.text"),
          onClick: () => onClose(),
        },
        secondaryButton: undefined,
        freeze: false,
      })
    }
  }
  return <PopIn {...popinArgs} />
}