import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DynamicInputComponents, DynamicInputProps } from "./index";
import { Field, ComplexInputValues } from "@companion-core/shared/app/Interfaces/wizard";
import { getCurrency } from "@companion-core/shared/app/Utils/config";
import { GooglePlaceAddress } from "@companion-core/shared/app/Interfaces/adresses";
import { useDynamicFormContext } from "@companion-core/shared/app/Components/DynamicForm/DynamicFormContext";

export const DynamicInput = ({ field, registerValidation }: DynamicInputProps) => {
  const { t } = useTranslation();
  const { toNextStep } = useDynamicFormContext();

  const Component = useMemo(() => {
    if (field.type in DynamicInputComponents) {
      return DynamicInputComponents[field.type];
    }
    return null;
  }, [field.type]);

  const getTransformField: Field = useMemo(() => {
    const newField = { ...field }; // create a copy of field
    newField.label = t(newField.label ?? "");
    newField.value = newField.value ?? null;
    if (newField.placeholder) newField.placeholder = t(newField.placeholder);
    if (newField.confirmationLabel) newField.confirmationLabel = t(newField.confirmationLabel);
    delete newField.summaryValue;
    delete newField.fromStep;
    return newField;
  }, [field, t]);

  const setFieldErrors = (errors: string[]) => {
    field.errors = errors;
  };

  const onValueChange = (
    value: string | number | boolean | ComplexInputValues | GooglePlaceAddress,
  ) => {
    if (field.type === "autocomplete") {
      field.value = value;
      field.summaryValue = value.formatted_address;
      return;
    }
    if (field.type === "radio" || field.type === "radioshape") {
      field.value = value;
      field.summaryValue = `${field.options?.find((option) => option.value === value)?.text ?? ""}`;
      if (value) toNextStep();
      return;
    }
    if (typeof value === "object" && "values" in value) {
      field.value =
        Object.keys(value.values).length === 1 ? Object.values(value.values)[0] : value.values;
      field.summaryValue = value.summaryValue;
    } else {
      field.value = value;
      if (field.type === "budget") {
        field.value = (field.value as number) * 100;
        const currency = getCurrency();
        field.summaryValue = currency.template.replace("{val}", value);
        field.currency = currency.iso;
      } else {
        field.summaryValue = `${value}`;
      }
    }
  };

  if (Component) {
    return (
      <Component
        {...getTransformField}
        setFieldErrors={setFieldErrors}
        onValueChange={onValueChange}
        registerValidation={registerValidation}
      />
    );
  }
  return <div>The component {field.type} has not been created yet.</div>;
};
