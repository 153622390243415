import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getLocales } from "@companion-core/shared/app/Utils/config";
import { JPInputSelect } from "@companion-core/shared/app/Components/Inputs/JPInputSelect";
import { loadLanguageAsync } from "@companion-core/shared/app/I18n";
import { useI18nStore } from "@companion-core/shared/app/Store/i18nStore";
import { getAntdLocale } from "Utilities/lang";
import "@companion-core/web/src/Assets/Styles/scss/languageSwitcher.scss";

export const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const { setAntdLocale } = useI18nStore();
  const formattedLanguages = getLocales().map((language: string) => ({
    value: language,
    text: `common.languages.${language}`,
  }));
  const [defaultValue, setDefaultValue] = useState<string>("");

  const changeLanguage = async (lang: string) => {
    if (lang) {
      await loadLanguageAsync(lang);
      setAntdLocale(getAntdLocale(lang));
      window.scrollTo(0, 0); // go to top page after changing language
    }
  };

  useEffect(() => {
    setDefaultValue(i18n.language);
  }, [i18n.language]);

  return (
    <div className="language-switcher">
      <JPInputSelect
        name="language-switcher"
        defaultValue={defaultValue}
        options={formattedLanguages}
        dropdownClass="language-switcher__dropdown"
        testID="JPInputSelect__language-switcher"
        onValueChange={(lang: string) => changeLanguage(lang)}
      />
    </div>
  );
};
