import React from "react";
import { useNavigate } from "react-router-dom";
import { SelectionLayoutProps } from "@companion-core/shared/app/Interfaces/Blocks";
import { Button } from "@companion-core/shared/app/Components/Button";
import { ComponentCard } from "@companion-core/shared/app/Components/Cards/ComponentCard";
import { SelectionCommonLayout } from "@companion-core/shared/app/Components/Blocks/Layouts/SelectionCommonLayout/SelectionCommonLayout";
import { useMediaQuery } from "@companion-core/web/src/Utilities/mediaQuery";
import { redirectTo } from "@companion-core/web/src/Utilities/navigation";
import "@companion-core/web/src/Assets/Styles/scss/selectionPushLayout.scss";

export const SelectionPushLayout = ({ text, cardList, button }: SelectionLayoutProps) => {
  const navigate = useNavigate();
  const isMobileSize = useMediaQuery("(max-width: 768px)");

  return isMobileSize ? (
    <SelectionCommonLayout text={text} cardList={cardList} button={button} />
  ) : (
    <section className="selection-push-layout" data-testid="selection-push">
      {text && (
        <h2
          data-testid="selection-push__title"
          className="selection-push-layout__title super-title-1">
          {text.title}
        </h2>
      )}
      {text?.subtitle && (
        <span
          data-testid="selection-push__subtitle"
          className="selection-push-layout__subtitle title-8">
          {text.subtitle}
        </span>
      )}
      <div data-testid="selection-push__cards" className="selection-push-layout__cards">
        {cardList?.map((component) => {
          return (
            <ComponentCard
              key={`selection-push-layout-card-${component.id}`}
              component={component}
            />
          );
        })}
      </div>
      {button && button.button_title && (
        <Button
          testID="selection-push__cta"
          label={button.button_title}
          size="large"
          onClick={() =>
            redirectTo(button.button_link ?? "/", button?.button_type ?? "internal", navigate)
          }
        />
      )}
    </section>
  );
};
