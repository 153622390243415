import React, { useMemo } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getModule } from "@companion-core/shared/app/Utils/config";

type Props = {
  publicOnly?: boolean;
  onlyAvailableForSSOCompanion?: boolean;
};

const SSOGuard = ({ publicOnly = false, onlyAvailableForSSOCompanion = false }: Props) => {
  const ssoModule = getModule("sso");
  const isCompanionSso = useMemo(() => ssoModule?.enabled, []);
  const isCompanionSsoOnly = useMemo(() => ssoModule?.options?.ssoOnly, []);

  if (onlyAvailableForSSOCompanion && !isCompanionSso) {
    return <Navigate to="/not-found" />;
  }
  if (publicOnly && isCompanionSsoOnly) {
    return <Navigate to="/sso/no-rights" />;
  }
  return <Outlet />;
};

export default SSOGuard;
