import React from "react";
import { useLocation, Navigate, useParams, Outlet } from "react-router-dom";
import { useAuthStore } from "@companion-core/shared/app/Store/authStore";
import { getConfig } from "@companion-core/shared/app/Utils/config";
import SSOGuard from "./SSOGuard";

type Props = {
  publicOnly?: boolean;
};

const AuthGuard = ({ publicOnly = false }: Props) => {
  const authenticated = useAuthStore.getState().authenticated;
  const { pathname, search } = useLocation();
  const { slug } = useParams();
  let isPublicSlug = false;

  if (slug) isPublicSlug = getConfig()?.options.publicRoutes.includes(slug);

  if (publicOnly) {
    return !authenticated ? <SSOGuard publicOnly /> : <Navigate to="/" />;
  }
  if (isPublicSlug || publicOnly || (!publicOnly && authenticated)) {
    return <Outlet />;
  }
  return <Navigate to="/login" state={{ redirectPath: `${pathname}${search}` }} />;
};

export default AuthGuard;
