import {
  APIBlockItem,
  APIContentComponent,
  ComponentData,
  Media,
} from "@companion-core/shared/app/Interfaces/API";

export const TYPE_TEXT = "text";
export const TYPE_CARD = "card";
export const TYPE_IMAGE = "image";
export const TYPE_BUTTON = "button";
export const TYPE_MEDIA = "media";
export const COMPONENTS_PAGE_SIZE = 21;

export function getComponentsFromBlockItems(blockItem: APIBlockItem[]): ComponentData[] {
  return blockItem.flatMap((result) => result.components.data);
}

export function getSortedByOrderComponents(blockItem: APIBlockItem[]): ComponentData[] {
  return blockItem
    .sort((a, b) => a.order - b.order)
    .map((result) => result.components.data)
    .flat();
}

export function getComponentText(components: ComponentData[]): ComponentData | undefined {
  return components.find((obj) => obj.type === TYPE_TEXT);
}

export function getComponentsCard(components: ComponentData[]): ComponentData[] {
  return components.filter((obj) => obj.type === TYPE_CARD);
}

export function getComponentButton(components: ComponentData[]): ComponentData | undefined {
  return components.find((obj) => obj.type === TYPE_BUTTON);
}

export function getComponentMedia(components: ComponentData[]): ComponentData | undefined {
  return components.find((obj) => obj.type === TYPE_MEDIA);
}

/**
 * Get the media URL of a content component if exists. Otherwise, get the vendor media URL.
 *
 * @param {APIContentComponent} component - The content component object.
 * @param {string} mediaType - The type of the media we are looking for.
 * @returns {string | undefined} - The media URL if found, otherwise undefined.
 */
export const getContentComponentMediaUrl = (
  component: APIContentComponent,
  mediaType = "image",
): string | undefined => {
  const componentMedia: Media | undefined = component.medias?.find(
    (media: Media) => media.type === mediaType,
  );
  return componentMedia
    ? componentMedia.url
    : component.vendor?.medias?.find((media: Media) => media.type === mediaType)?.url;
};

export function sortData(data: any) {
  return data.sort((a: any, b: any) => {
    const keyA = a[a?.order_by ? a.order_by : "order"];
    const keyB = b[b?.order_by ? b.order_by : "order"];
    // Compare the 2 keys
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
  });
}

export function sortByOrder(a?: any, b?: any) {
  if (a.order && b.order) {
    return a.order - b.order;
  } else {
    return 0;
  }
}
