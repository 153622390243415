import { useEffect, useState } from "react";
import { APIMemberData, APISubscriptionData } from "@companion-core/shared/app/Interfaces/API";
import { useAuthStore } from "@companion-core/shared/app/Store/authStore";
import { useMember, useSubscriptions, useVerifyAccount } from "@companion-core/shared/app/Queries";
import { useMemberStore } from "@companion-core/shared/app/Store/memberStore";
import { useClientToken } from "@companion-core/shared/app/Hooks/clientToken";
import { setUser } from "@companion-core/shared/app/tracking";

/**
 * Populate member store.
 * Fetch member data and subscriptions data only if user is authenticated.
 * Update store with data or reset if user is not authenticated.
 * @returns {boolean} isPopulatedMember
 */
export const usePopulateMember = (): boolean => {
  const [isPopulatedMember, setIsPopulatedMember] = useState<boolean>(false);
  const { authenticated } = useAuthStore((state) => ({ authenticated: state.authenticated }));
  const { setMemberData, setSubscriptions } = useMemberStore((state) => ({
    data: state.data,
    subscriptions: state.subscriptions,
    setMemberData: state.setMemberData,
    setSubscriptions: state.setSubscriptions,
  }));

  const { data: memberData, isFetched: isMemberFetched } = useMember(authenticated);
  const { data: subscriptionsData, isFetched: isSubscriptionsFetched } =
    useSubscriptions(authenticated);

  useEffect(() => {
    if (isMemberFetched) {
      setMemberData(memberData ? (memberData as APIMemberData) : null);
    }
  }, [isMemberFetched]);

  useEffect(() => {
    if (isSubscriptionsFetched) {
      setSubscriptions(subscriptionsData ? (subscriptionsData as APISubscriptionData[]) : null);
    }
  }, [isSubscriptionsFetched]);

  useEffect(() => {
    if (isMemberFetched && isSubscriptionsFetched) {
      setUser(memberData?.id ?? "", subscriptionsData?.[0]?.id ?? "");
      setIsPopulatedMember(true);
    }
  }, [isMemberFetched, isSubscriptionsFetched]);

  return !authenticated || isPopulatedMember;
};

/**
 * Function to verify a member using a verification token.
 *
 * @param {string} vToken - The verification token.
 * @returns {Object} - An object containing the verified member data and a flag indicating if the verification is loading.
 */
export const useVerifyMember = (vToken: string) => {
  const fetchClientToken = useClientToken();
  const { accessTokenClient } = useAuthStore((state) => ({
    accessTokenClient: state.accessTokenClient,
  }));
  const { data, isLoading } = useVerifyAccount(accessTokenClient ?? "", vToken, fetchClientToken);

  return { verifiedMemberData: data, isVerifyLoading: isLoading };
};
