import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIsFetching, useQueryClient } from "@tanstack/react-query";
import { Spin } from "antd";
import { IndependentDynamicForm } from "@companion-core/shared/app/Components/DynamicForm/IndependentDynamicForm";
import { getJSONFromFile } from "@companion-core/shared/app/Assets/forms/myAccount/jsonLoader";
import { DynamicFormJson, Field } from "@companion-core/shared/app/Interfaces/wizard";
import { formatPhoneNumberToPayload } from "@companion-core/shared/app/Utils/components/JPInputPhoneUtils";
import { APIMemberData } from "@companion-core/shared/app/Interfaces/API";
import { JPInputError } from "@companion-core/shared/app/Components/Inputs/JPInputError";
import { useUpdateMember } from "@companion-core/shared/app/Queries/member";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import "@companion-core/web/src/Assets/Styles/scss/personalInformation.scss";

export const PersonalInformation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const isMemberFetching = useIsFetching({ queryKey: ["member"] });
  const data = queryClient.getQueryData(["member"]) as APIMemberData | null;
  const baseJson = getJSONFromFile("myAccount/personalInformation/personalInformation.json");
  const [requestError, setError] = useState<string | null>(null);
  const [requestValidated, setRequestValidated] = useState<string | null>(null);
  const [jsonForm, setJsonForm] = useState<DynamicFormJson | null>(null);
  const { mutateAsync: updateMember } = useUpdateMember(() => {
    prefillForm(data);
    setError(t("my-account.personal-information.error"));
  });

  useEffect(() => {
    if (!data) return;
    prefillForm(data);
  }, [data]);

  const prefillForm = (formData: APIMemberData | null) => {
    setJsonForm(null);
    baseJson?.steps[0]?.fields.forEach((field: Field) => {
      // TODO: delete this if when the API will be fixed (V2)
      if (
        field.name === "personal_mobilePhone" &&
        // @ts-ignore: temp fix
        formData?.phones?.[0]?.phone &&
        // @ts-ignore: temp fix
        formData?.phones?.[0]?.type === "personal-mobile"
      ) {
        // @ts-ignore: temp fix
        field.value = formData?.phones?.[0]?.phone;
      } else {
        field.value = formData?.[field.name];
      }
    });
    setJsonForm(baseJson);
  };

  const onSubmitForm = async (payload: Record<string, unknown>) => {
    if (!data) return;
    setRequestValidated(null);
    const requestPayload = {
      ...data,
      ...payload,
    };
    const formattedPayload = formatPhoneNumberToPayload(requestPayload);
    await updateMember({ id: data.id, payload: formattedPayload });
    prefillForm(formattedPayload as APIMemberData);
    if (requestError) setError(null);
    setRequestValidated(t("my-account.personal-information.success"));
  };

  return isMemberFetching ? (
    <Spin className="personal-information__spin" size="large" />
  ) : (
    <div className="personal-information">
      <div className="title-5 personal-information__title">
        {t("my-account.personal-information.title")}
      </div>
      {jsonForm && (
        <div className="personal-information__section">
          {requestError && (
            <JPInputError error={requestError} testID="JPInputError-personal-information" />
          )}
          {requestValidated && (
            <div
              className="body-text-9 personal-information__validation"
              data-testid="personal-information__validation">
              <FontIcon
                name="JP-Circle-check"
                size={16}
                className="personal-information__validation-icon"
              />
              {requestValidated}
            </div>
          )}
          <IndependentDynamicForm
            name="personalInformation"
            initialFormJSON={jsonForm}
            onSubmitForm={onSubmitForm}
          />
        </div>
      )}
    </div>
  );
};
