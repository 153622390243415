import React, { useCallback, useState, useImperativeHandle, forwardRef, useEffect } from "react";
import { Form } from "antd";
import { Field } from "@companion-core/shared/app/Interfaces/wizard";
import { DynamicInput } from "../DynamicInput";
import { DynamicStepRef, StepProps, ValidationFunction } from "./index";
import { trackStepBell } from "@companion-core/shared/app/tracking/bell";
import "@companion-core/web/src/Assets/Styles/scss/dynamicForm.scss";

export const DynamicStep = forwardRef<DynamicStepRef, StepProps>(({ step }, ref) => {
  const [validationFunctions, setValidationFunctions] = useState<
    Record<string, ValidationFunction | undefined>
  >({});

  const registerValidation = useCallback((name: string, validateFn: () => void) => {
    setValidationFunctions((prev) => ({ ...prev, [name]: validateFn }));
  }, []);

  const validateAllFields = () => {
    Object.values(validationFunctions).forEach((validateFn) => {
      if (validateFn) {
        validateFn();
      }
    });
  };

  useImperativeHandle(ref, () => ({
    validateAllFields,
  }));

  useEffect(() => {
    if (step.tracking) {
      // eslint-disable-next-line camelcase
      const { name = "", chapter1 = "", chapter2 = "", chapter3 = "" } = step.tracking;
      trackStepBell(name, chapter1, chapter2, chapter3);
    }
  }, [step.tracking]);

  return (
    <>
      {step.fields.map((field: Field) => (
        <Form.Item
          key={field.payloadPath ?? field.name}
          name={field.name}
          className="step__field body-text-5">
          <DynamicInput field={field} registerValidation={registerValidation} />
        </Form.Item>
      ))}
    </>
  );
});

DynamicStep.displayName = "DynamicStep";
