import React, { useMemo, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import { ActivationContentProps } from "./index";
import { getJSONFromFile } from "@companion-core/shared/app/Assets/forms/register/jsonLoader";
import { Button } from "@companion-core/shared/app/Components/Button";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import { IndependentDynamicForm } from "@companion-core/shared/app/Components/DynamicForm/IndependentDynamicForm";
import { Field } from "@companion-core/shared/app/Interfaces/wizard";
import { getModule } from "@companion-core/shared/app/Utils/config";
import "Assets/Styles/scss/register.scss";

export const ActivationContent = ({
  state,
  redirectCallback,
  memberData,
  activationErrorCode,
}: ActivationContentProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formPath = useMemo(() => getModule("register")?.modules?.activation?.formPath ?? "", []);
  const identityForm = useMemo(() => {
    const identityJsonForm = getJSONFromFile("register/identity/identity.json");
    identityJsonForm?.steps[0]?.fields.forEach((field: Field) => {
      field.value = memberData?.[field.name];
    });
    return identityJsonForm;
  }, [memberData]);
  const activationForm = useMemo(() => {
    return formPath ? getJSONFromFile(formPath) : null;
  }, [formPath]);

  const activationErrorKeyMap: Record<string, string> = useMemo(
    () => ({
      400: "register.activation.failed.bad-request.text",
      404: "register.activation.failed.already-activated.text",
      other: "register.activation.failed.unknown.text",
    }),
    [],
  );

  const activationErrorKey: string = useMemo(() => {
    if (activationErrorCode && [400, 404].includes(activationErrorCode)) {
      return activationErrorKeyMap[activationErrorCode];
    }
    return activationErrorKeyMap.other;
  }, [activationErrorCode]);

  const WaitingForVerificationContent = (): ReactElement => (
    <div data-testid="activation__waiting-for-verification">
      <Spin data-testid="activation__loader" size="large" />
    </div>
  );

  const VerifiedContent = (): ReactElement => (
    <div data-testid="activation__verified" className="activation__success">
      <FontIcon
        data-testid="activation__verified--icon"
        className="activation__success-icon"
        name="JP-Circle-check"
        size={40}
      />
      <h2 data-testid="activation__verified--title" className="activation__success-title title-1">
        {t("register.activation.verified.title")}
      </h2>
      <Button
        testID="activation__verified--button"
        classNames={["activation__success-button"]}
        label={t("register.activation.verified.button.label")}
        size="full"
        onClick={redirectCallback}
      />
    </div>
  );

  const VerifyExpiredContent = (): ReactElement => (
    <div data-testid="activation__verify-expired" className="activation__verify-expired">
      <h2 data-testid="activation__verify-expired--title" className="activation__title title-1">
        {t("register.activation.verify-expired.title")}
      </h2>
      <p
        data-testid="activation__verify-expired--body"
        className="activation__body body-text-4"
        dangerouslySetInnerHTML={{ __html: t("register.activation.verify-expired.text") }}
      />
      <Button
        testID="activation__verify-expired--button"
        classNames={["activation__verify-expired-button"]}
        label={t("register.activation.verify-expired.button.label")}
        size="full"
        onClick={() => navigate("/register/member")}
      />
    </div>
  );

  const IdentityContent = (): ReactElement => (
    <div data-testid="activation__identity" className="activation__identity">
      {identityForm && (
        <IndependentDynamicForm
          name="identity"
          initialFormJSON={identityForm}
          onSubmitForm={redirectCallback}
        />
      )}
    </div>
  );

  const ActivationFormContent = (): ReactElement => (
    <div data-testid="activation__activation" className="activation__activation">
      {activationForm && (
        <IndependentDynamicForm
          name="activation"
          initialFormJSON={activationForm}
          onSubmitForm={redirectCallback}
        />
      )}
    </div>
  );

  const ActivationSuccessContent = (): ReactElement => (
    <div data-testid="activation__successful-activation" className="activation__success">
      <FontIcon
        data-testid="activation__successful-activation--icon"
        className="activation__success-icon"
        name="JP-Circle-check"
        size={40}
      />
      <h2
        data-testid="activation__successful-activation--title"
        className="activation__success-title title-1">
        {t("register.activation.successful.title")}
      </h2>
      <p
        data-testid="activation__success--body"
        className="activation__body activation__body--centered body-text-4"
        dangerouslySetInnerHTML={{ __html: t("register.activation.successful.text") }}
      />
      <Button
        testID="activation__successful-activation--button"
        classNames={["activation__success-button"]}
        label={t("register.activation.successful.button.label")}
        size="full"
        onClick={() => navigate("/login")}
      />
    </div>
  );

  const ActivationFailedContent = (): ReactElement => (
    <div data-testid="activation__failed" className="activation__failed">
      <h2 data-testid="activation__failed--title" className="activation__failed-title title-1">
        {t("register.activation.failed.title")}
      </h2>
      <p
        data-testid="activation__failed--body"
        className="activation__body body-text-4"
        dangerouslySetInnerHTML={{ __html: t(activationErrorKey) }}
      />
      <Button
        testID="activation__failed--button"
        classNames={["activation__failed-button"]}
        label={t("register.activation.failed.button.label")}
        size="full"
        onClick={redirectCallback}
      />
    </div>
  );

  const activationContentMap: Record<string, ReactElement> = {
    "waiting-for-verification": <WaitingForVerificationContent />,
    verified: <VerifiedContent />,
    "verify-expired": <VerifyExpiredContent />,
    identity: <IdentityContent />,
    activation: <ActivationFormContent />,
    "successful-activation": <ActivationSuccessContent />,
    "activation-failed": <ActivationFailedContent />,
  };

  return activationContentMap[state];
};
