export const requestStatusColorClass: Record<string, string> = {
  default: "ongoing",
  submitted: "ongoing",
  "in-progress": "ongoing",
  recommended: "ongoing",
  booked: "done",
  closed: "done",
  cancelled: "cancelled",
  "cancellation-requested": "cancelling",
  "modification-requested": "modifying",
}

export const accessibleRequestFields: Record<string, string> = {
  "additional_information_digital": "JP-Star",
  "location": "JP-Position",
  "start_location": "JP-Position",
  "end_location": "JP-Location",
  "start_date": "JP-Calendar",
  "end_date": "JP-Calendar",
  "participants": "JP-User",
  "max_budget": "JP-Credit-card",
  "number_of_rooms": "JP-Bed",
  "category_class": "JP-Collection-tag",
  "description": "JP-Plus",
}

export const serviceIcons: Record<string, string> = {
  "tours": "JP-Luggage",
  "accommodation": "JP-Bed",
  "dining": "JP-Fork-spoon",
  "gourmet": "JP-Fork-spoon",
  "arts-entertainment": "JP-Ticket",
  "sports-events": "JP-Ticket",
  "transportation": "JP-Plane",
  "daily-services": "JP-Home-2",
  "shopping": "JP-Shopping-bag",
  "ground-transfer": "JP-Taxi",
};