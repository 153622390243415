import React, { useMemo } from "react";
import {
  getComponentsCard,
  getComponentsFromBlockItems,
} from "@companion-core/shared/app/Utils/componentHelper";
import { BlockProps } from "@companion-core/shared/app/Interfaces/Blocks";
import { ComponentData } from "@companion-core/shared/app/Interfaces/API";
import { DetailLayout } from "../Layouts/DetailLayout/DetailLayout";

export const Detail = ({ simpleBlockItems }: BlockProps) => {
  const simpleComponents: ComponentData[] = useMemo(
    () => getComponentsFromBlockItems(simpleBlockItems),
    [simpleBlockItems],
  );
  const card: ComponentData = useMemo(
    () => getComponentsCard(simpleComponents)[0],
    [simpleComponents],
  );

  return (
    <DetailLayout
      mediaUrl={card?.medias?.[0].url}
      title={card?.title}
      subtitle={card?.subtitle ?? undefined}
      description={card?.description}
    />
  );
};
