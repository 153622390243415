import { getModule } from "@companion-core/shared/app/Utils/config";
import getValidation from "@companion-core/shared/app/Utils/validators";
import { trackClickLogin } from "@companion-core/shared/app/tracking/login";
import { useAuthStore } from "@companion-core/shared/app/Store/authStore";

/**
 *
 * @param {string} username : username of the user (email or card number)
 * @param {string} password : password of the user
 * @param {Hook} t : using i18n for translation
 * @returns {boolean} : false if login failed true if not
 */
export async function onSubmit(username: string, password: string, t: any) {
  let result = false;
  let trackingError: string | null = null;
  const trimmedUsername: string = username?.trim();

  if (username === "" || password === "") {
    trackingError = "empty_form";
    useAuthStore.getState().setError(t("login.error.form.empty.error"));
  } else {
    const { isValid, errorMessages } = getValidation(
      getModule("login").options?.username?.validators,
      trimmedUsername,
    );

    if (!isValid) {
      useAuthStore.getState().setError(t(errorMessages[0]));
      trackingError = "bad_username_format";
    } else {
      useAuthStore.getState().setError(null);
      const { success, message } = await useAuthStore
        .getState()
        .authAction(trimmedUsername, password);
      result = success;
      if (message) trackingError = message;
    }
  }

  trackClickLogin(result, trackingError);
  return result;
}
