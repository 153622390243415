import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Radio, Space } from "antd";
import type { RadioChangeEvent } from "antd";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import { JPInputRadioButtonProps } from "./index";
import "@companion-core/web/src/Assets/Styles/scss/radiobutton.scss";

export const JPInputRadioButton: React.FC<JPInputRadioButtonProps> = ({
  name,
  label,
  options,
  value,
  payloadPath = name,
  testID,
  onValueChange,
}: JPInputRadioButtonProps) => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const [inputValue, setInputValue] = useState<string>(value);

  useEffect(() => {
    if (form && payloadPath) {
      form.setFieldsValue({
        [payloadPath]: inputValue,
      });
    }
    if (onValueChange) {
      onValueChange(inputValue);
    }
  }, [onValueChange, inputValue, form, payloadPath]);

  return (
    <div data-testid={testID}>
      {label && <p className="body-text-4">{t(label)}</p>}
      <Radio.Group onChange={(e: RadioChangeEvent) => setInputValue(e.target.value)}>
        <Space direction="vertical">
          {options.map((option, index) => (
            <Radio.Button
              key={`radio-${name}-${index}`}
              value={option.value}
              className={"custom-radio-button"}>
              <div className="d-flex align-items-center">
                {inputValue === option.value ? (
                  <FontIcon name="JP-Radio-Check" className="radio-icon" size={24} />
                ) : (
                  <FontIcon name="JP-Circle" className="radio-icon" size={24} />
                )}
                <span className="radio-button-text">{option.text}</span>
              </div>
            </Radio.Button>
          ))}
        </Space>
      </Radio.Group>
    </div>
  );
};
