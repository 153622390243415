import errors from "../Constants/errors";

/**
 * This is equivalent to:
 * type ErrorStrings = 'already_exists' | 'invalid_grant' | 'locked' | 'wrong_password' | 'same_as_previous_password' | 'known_password' | 'email_verification';
 */
type errorString = keyof typeof errors;

export function getErrorCode(error: any) {
    const message: errorString = (
        error?.response?.data?.errors?.[0]?.code ||
        error?.response?.data?.error);
    return errors[message];
}

export function getPasswordError(error: any) {
    const message: errorString = (
        error?.response?.data?.error_description?.previous_password?.[0] ||
        error?.response?.data?.error_description?.new_password?.[0]
    );
    return errors[message];
}

export function getSecretError(error: any) {
    const message: errorString = (
        error?.response?.data?.error_description?.password?.[0] ||
        error?.response?.data?.error_description?.secret_question?.[0] ||
        error?.response?.data?.error_description?.secret_answer?.[0]
    );
    return errors[message];
}
