import React, { CSSProperties } from "react";
import "@companion-core/web/src/Assets/Styles/scss/fontIcon.scss";

interface FontIconProps {
  name: string;
  size?: number;
  className?: string;
  style?: CSSProperties;
  [key: string]: unknown;
}

export const FontIcon = ({ name, size, className, style = {}, ...props }: FontIconProps) => {
  if (size) {
    style = { ...style, fontSize: size };
  }
  return (
    <span
      className={`font-icon icon-${name} ${className ? className : ""}`}
      style={style}
      {...props}
    />
  );
};
