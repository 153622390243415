import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getModule } from "@companion-core/shared/app/Utils/config";
import { TextInShape } from "@companion-core/shared/app/Components/TextInShape";
import { useBellStore } from "@companion-core/shared/app/Store/bellStore";
import { Category } from ".";
import { getJSONFromFile } from "@companion-core/shared/app/Assets/forms/concierge/jsonLoader";
import { trackClickBell, trackStepBell } from "@companion-core/shared/app/tracking/bell";
import "Assets/Styles/scss/bell.scss";

export const BellCategories = () => {
  const { t } = useTranslation();

  const categories = getModule("concierge").modules.bell.options.categories;
  const { setBellContent, setBellCategory, setFormPath } = useBellStore((state) => ({
    setBellContent: state.setBellContent,
    setBellCategory: state.setBellCategory,
    setFormPath: state.setFormPath,
  }));

  const onCategoryClick = (selectedCategory: Category) => {
    setFormPath(selectedCategory.formPath);
    setBellContent("form");
    setBellCategory(selectedCategory);
    const formJson = getJSONFromFile(selectedCategory.formPath);
    if (formJson.steps[0].tracking) {
      const { name = "", chapter1 = "", chapter2 = "", chapter3 = "" } = formJson.steps[0].tracking;
      trackClickBell(name, chapter1, chapter2, chapter3);
    }
  };

  useEffect(() => {
    trackStepBell("step_categories", "bell", "categories");
  }, []);

  return (
    <div data-testid="bell__categories" className="bell__categories">
      <div className="title-2">{t("requestV2.concierge.categories.title")}</div>
      <div className="bell__categories-container">
        {categories.map((category: Category) => (
          <TextInShape
            key={`category--${category.name}`}
            testID={`bell__category--${category.name}`}
            text={t(`request.concierge.form.subject.${category.name}`)}
            icon={category.icon}
            value={category}
            onClick={() => onCategoryClick(category)}
          />
        ))}
      </div>
    </div>
  );
};
