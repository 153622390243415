import React, { useEffect, useMemo, useState } from "react";
import "@companion-core/web/src/Assets/Styles/scss/jpcounter.scss";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import { Card, Divider } from "antd";
import { JPCounterProps } from "./index";

export const JPCounter = ({ testID, counters, onChange, value }: JPCounterProps) => {
  const nbOfCounters = useMemo(() => counters.length, [counters]);
  const [countersState, setCountersState] = useState<Record<string, number>>(
    value ??
      counters.reduce(
        (acc, { name, minimumValue, defaultValue }) => ({
          ...acc,
          [name]: defaultValue ?? (minimumValue ? minimumValue : 0), // If no default value is provided, set the minimum value
        }),
        {},
      ),
  );

  useEffect(() => {
    if (!value) {
      onChange && onChange(countersState);
    }
  }, [value]);

  const setCount = (name: string, value: number) => {
    const newState = { ...countersState, [name]: value };
    setCountersState(newState);
    onChange && onChange(newState);
  };

  return (
    <div className="counter-input" data-testid={testID}>
      <Card className="counter-input__counter-card" bordered={false}>
        {counters.map(({ name, title, subtitle, minimumValue, maximumValue }, index) => (
          <div key={name} data-testid={`counter-input__${name}`}>
            <Counter
              key={name}
              title={title}
              subtitle={subtitle}
              count={countersState[name]}
              setCount={(value: number) => setCount(name, value)}
              minimumCount={minimumValue}
              maximumCount={maximumValue}
            />
            {index < nbOfCounters - 1 && <Divider className="counter-input__line"></Divider>}
          </div>
        ))}
      </Card>
    </div>
  );
};

interface CounterProps {
  title: string;
  subtitle?: string;
  minimumCount?: number;
  maximumCount?: number;
  count: number;
  setCount: (value: number) => void;
}

const Counter = ({
  title,
  subtitle,
  minimumCount = 0,
  maximumCount,
  count,
  setCount,
}: CounterProps) => {
  const minusButtonDisabled = count <= minimumCount;
  const plusButtonDisabled = maximumCount ? count >= maximumCount : false;

  const handleOnClick = (number: number) => {
    if (maximumCount && (count + number > maximumCount || count + number < minimumCount)) {
      return;
    }
    setCount(count + number);
  };

  return (
    <div className="counter-input__container">
      <div className="counter-input__title-container">
        <div className="body-text-5 counter-input__title-container-title">{title}</div>
        {subtitle && (
          <span className="body-text-8 counter-input__title-container-subtitle">{subtitle}</span>
        )}
      </div>
      <div className="counter-input__buttons-container">
        <button
          className={`counter-input__custom-icon-button ${
            minusButtonDisabled ? "counter-input__custom-icon-button--disabled" : ""
          }`}
          onClick={() => handleOnClick(-1)}>
          <FontIcon name={"JP-Circle-minus-outline"} size={25} />
        </button>
        <span className="body-text-6 counter-input__counter">{count}</span>
        <button
          className={`counter-input__custom-icon-button ${
            plusButtonDisabled ? "counter-input__custom-icon-button--disabled" : ""
          }`}
          onClick={() => handleOnClick(1)}>
          <FontIcon name={"JP-Circle-plus-outline"} size={25} />
        </button>
      </div>
    </div>
  );
};
