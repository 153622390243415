import React, { useState, useEffect } from "react";
import { RequestDetail } from "@companion-core/shared/app/Components/Requests/RequestDetail";
import { ContextType } from "@companion-core/shared/app/Interfaces/myRequests";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { APIRequest } from "@companion-core/shared/app/Interfaces/API";
import { useMemberStore } from "@companion-core/shared/app/Store/memberStore";
import { Spin } from "antd";
import "Assets/Styles/scss/myrequests.scss"

const Detail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { activeKey, setActiveKey } = useOutletContext<ContextType>();
  const [ requestData, setRequestData ] = useState<APIRequest>();
  const { requests } = useMemberStore();

  useEffect(() => {
    if (requests) {
      const request = requests.find((value) => value.id === id);
      setRequestData(request);
      if (request) ["closed", "cancelled"].includes(request.status) ? setActiveKey("1") : setActiveKey("0");
    }
  }, [requests]);

  if (requestData) return <RequestDetail data={requestData} goBack={() => navigate(`/my-requests${activeKey === "1"? "/past" : ""}`)} />;
  return <div className="my-requests__no-request"><Spin data-testid="my-requests__loader" size="large" /></div>
}

export default Detail;