/* eslint-disable camelcase */
import { CustomVars } from "@companion-core/shared/app/Interfaces/tracking";

export let customVars: CustomVars = {
  contact_UUID: "",
  subscription_UUID: "",
  url: "",
  referrer: "",
  offer_id: "",
  vendor_id: "",
  case_UUID: "",
  authentification: 2,
}

export function getCustomVars() {
  customVars.authentification = customVars.contact_UUID ? 1 : 2;
  return customVars;
}

export function setCustomVars(vars: CustomVars) {
  customVars = {
    ...customVars,
    ...vars,
  }
}
