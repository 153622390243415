import React, { createContext, ReactNode, useContext, useRef } from "react";
import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";
import { createStore, useStore } from "zustand";
import {
  DynamicFormJson,
  Field,
  formPath,
  FormTree,
  Step,
} from "@companion-core/shared/app/Interfaces/wizard";

interface WizardState {
  currentStep: Step | null;
  fields: Field[];
  haveSummary: boolean;
  formTree: FormTree;
  currentForm: DynamicFormJson | null;
  defaultApiValues: { [key: string]: string | number | null };
  submitButtonLoading: boolean;
}

interface WizardActions {
  setCurrentStep: (step: Step | null) => void;
  setFields: (inputs: Field[]) => void;
  setHaveSummary: (haveSummary: boolean) => void;
  setSubmitButtonLoading: (submitButtonLoading: boolean) => void;
  addFirstStepToFormTree: (
    stepNumber: number,
    formPath: formPath,
    formState?: DynamicFormJson,
  ) => void;
  addStepToFormTree: (stepNumber: number, formPath: formPath, formState?: DynamicFormJson) => void;
  deleteStepsAfterIndex: (index: number) => void;
  setCurrentForm: (form: DynamicFormJson) => void;
  setDefaultApiValues: (defaultApiValues: { [key: string]: string | number | null }) => void;
  resetWizard: () => void;
}

export const initialState: WizardState = {
  currentStep: null,
  fields: [],
  haveSummary: false,
  formTree: [],
  currentForm: null,
  defaultApiValues: {},
  submitButtonLoading: false,
};

// Single wizard store hook
export const useWizardStore = createWithEqualityFn<WizardState & WizardActions>()(
  (set) => ({
    ...initialState,
    setCurrentStep: (step: Step | null) => set({ currentStep: step }),
    setHaveSummary: (haveSummary: boolean) => set({ haveSummary }),
    setSubmitButtonLoading: (submitButtonLoading: boolean) => set({ submitButtonLoading }),
    setFields: (inputs: Field[]) => set({ fields: inputs }),
    addFirstStepToFormTree: (
      stepNumber: number,
      formPath: formPath,
      formState?: DynamicFormJson,
    ) => {
      return set(() => ({
        formTree: [{ stepNumber, formPath, savedForm: formState }],
      }));
    },
    addStepToFormTree: (stepNumber: number, formPath: formPath, formState?: DynamicFormJson) => {
      return set((state) => ({
        formTree: [...state.formTree, { stepNumber, formPath, savedForm: formState }],
      }));
    },
    deleteStepsAfterIndex: (index: number) => {
      return set((state) => ({
        formTree: state.formTree.slice(0, index + 1),
      }));
    },
    setCurrentForm: (form: DynamicFormJson) => set({ currentForm: form }),
    setDefaultApiValues: (defaultApiValues: { [key: string]: string | number | null }) =>
      set({ defaultApiValues }),
    resetWizard: () => set(initialState),
  }),
  shallow,
);

// The following store creation is used to create multiple wizard store instance
// depending on the WizardStoreContext we are.
// To use multiple store instance, you have to encapsulate the component (that is using the store)
// with a provider like that :
// <WizardStoreProvider>
//   <FirstComponent />
// </WizardStoreProvider>
// ...
// <WizardStoreProvider>
//   <SecondComponent />
// </WizardStoreProvider>
// And in FirstComponent.tsx and SecondComponent.tsx, you simply have to use
// useWizardStoreWithProvider() instead of the useWizardStore() hook.
// By this way, the two components don't share the same store instance.
const createWizardStore = () =>
  createStore<WizardState & WizardActions>()((set) => ({
    ...initialState,
    setCurrentStep: (step: Step | null) => set({ currentStep: step }),
    setHaveSummary: (haveSummary: boolean) => set({ haveSummary: haveSummary }),
    setSubmitButtonLoading: (submitButtonLoading: boolean) => set({ submitButtonLoading }),
    setFields: (inputs: Field[]) => set({ fields: inputs }),
    addFirstStepToFormTree: (
      stepNumber: number,
      formPath: formPath,
      formState?: DynamicFormJson,
    ) => {
      return set(() => ({
        formTree: [{ stepNumber, formPath, savedForm: formState }],
      }));
    },
    addStepToFormTree: (stepNumber: number, formPath: formPath, formState?: DynamicFormJson) => {
      return set((state) => ({
        formTree: [...state.formTree, { stepNumber, formPath, savedForm: formState }],
      }));
    },
    deleteStepsAfterIndex: (index: number) => {
      return set((state) => ({
        formTree: state.formTree.slice(0, index + 1),
      }));
    },
    setCurrentForm: (form: DynamicFormJson) => set({ currentForm: form }),
    setDefaultApiValues: (defaultApiValues: { [key: string]: string | number | null }) =>
      set({ defaultApiValues }),
    resetWizard: () => set(initialState),
  }));

const WizardStoreContext = createContext<ReturnType<typeof createWizardStore> | null>(null);

export const WizardStoreProvider = ({ children }: { children: ReactNode }) => {
  const storeRef = useRef<ReturnType<typeof createWizardStore> | null>();
  if (!storeRef.current) {
    storeRef.current = createWizardStore();
  }
  return (
    <WizardStoreContext.Provider value={storeRef.current}>{children}</WizardStoreContext.Provider>
  );
};

export const useWizardStoreWithProvider = () => {
  const store = useContext(WizardStoreContext);
  if (store === null) {
    throw new Error("no provider");
  }
  return useStore(store);
};
