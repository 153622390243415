import i18next from "@companion-core/shared/app/I18n"

const BASE_TITLE = "common.head.title";
let title;

i18next.on("languageChanged init", () => {
  updateDocumentTitle(); // To be sure that the title is updated when i18n instance has finished to load locolize files
});

export function updateDocumentTitle(slugPage = "") {
  const titleKey = slugPage ? `${BASE_TITLE}.${slugPage}` : `${BASE_TITLE}.default`;
  title = i18next.t(titleKey)
  // Update document title
  document.title = title;
}
