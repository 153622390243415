import React, { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import { useMemberStore } from "@companion-core/shared/app/Store/memberStore";
import { useVerifyMember } from "@companion-core/shared/app/Hooks/member";
import { APIMemberData } from "@companion-core/shared/app/Interfaces/API";
import { IdentityCriteria } from "@companion-core/shared/app/Interfaces/member";
import { getModule } from "@companion-core/shared/app/Utils/config";
import { activateAccount } from "@companion-core/shared/app/Utils/apiClient";
import { ActivationContent } from "Components/Register/ActivationContent";
import { trackClickHelp } from "@companion-core/shared/app/tracking/register";
import "Assets/Styles/scss/register.scss";

const Activation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const vToken = useMemo(() => searchParams.get("vtoken"), [searchParams]);
  const { verifiedMemberData, isVerifyLoading } = useVerifyMember(vToken ?? "");
  const activationModule = getModule("register")?.modules.activation;
  const identityCriteriaList: IdentityCriteria[] = useMemo(
    () => activationModule?.identityCriteria,
    [activationModule],
  );
  const [activationContent, setActivationContent] = useState<string>("waiting-for-verification");
  const [activationErrorCode, setActivationErrorCode] = useState<number | null>(null);
  const { memberData, setMemberData } = useMemberStore((state) => ({
    memberData: state.data,
    setMemberData: state.setMemberData,
  }));

  useEffect(() => {
    if (!vToken) {
      navigate("/register/member");
    }
  }, [vToken]);

  useEffect(() => {
    if (!isVerifyLoading) {
      if (verifiedMemberData) {
        setMemberData(verifiedMemberData as APIMemberData);
        setActivationContent("verified");
      } else {
        setMemberData(null);
        setActivationContent("verify-expired");
      }
    }
  }, [verifiedMemberData, isVerifyLoading]);

  const onSubmitIdentity = async (identityFormData?: Record<string, unknown>) => {
    if (identityFormData) {
      const newMemberData: APIMemberData = { ...memberData } as APIMemberData;
      Object.entries(identityFormData).forEach(([key, value]) => (newMemberData[key] = value));
      setMemberData(newMemberData);
    }
    setActivationContent("activation");
  };

  const onSubmitActivation = async (activationFormData?: Record<string, unknown>) => {
    if (memberData && activationFormData) {
      const { success, code } = await activateAccount(
        memberData,
        activationFormData,
        identityCriteriaList,
        "website",
      );
      if (success) {
        setActivationContent("successful-activation");
      } else {
        setActivationErrorCode(code);
        setActivationContent("activation-failed");
      }
    }
  };

  const ActivationHeader = () => {
    return (
      ["activation", "activation-failed"].includes(activationContent) && (
        <div data-testid="register__header" className="register__header">
          <span
            data-testid="register__header--back-button"
            className="register__header-back-button cursor-pointer"
            onClick={() => setActivationContent("identity")}>
            <FontIcon name="JP-Arrow-left-bold" size={24} />
            {t("register.back-button.label")}
          </span>
        </div>
      )
    );
  };

  const redirectCallbackMap: Record<
    string,
    (param?: Record<string, unknown>) => void | Promise<void>
  > = {
    verified: () => setActivationContent("identity"),
    "verify-expired": () => navigate("/register/member"),
    identity: (identityFormData?: Record<string, unknown>) => onSubmitIdentity(identityFormData),
    activation: (activationFormData?: Record<string, unknown>) =>
      onSubmitActivation(activationFormData),
    "successful-activation": () => navigate("/login"),
    "activation-failed": () => setActivationContent("identity"),
  };

  const ActivationFooter = () => {
    return (
      <div data-testid="register__need-help" className="register__need-help activation__need-help">
        <span className="body-text-8">
          {activationContent === "identity"
            ? t("register.activation.identity.links.help.pre")
            : t("register.links.help.pre")}{" "}
          {activationContent === "identity"
            ? t("register.activation.identity.links.help.post")
            : t("register.links.help.post")}
          <Link
            data-testid="register__need-help--link"
            to="/need-help"
            className="body-text-7"
            onClick={() => trackClickHelp()}>
            {t("register.links.help.link")}
          </Link>
        </span>
      </div>
    );
  };

  return (
    <div data-testid="activation" className="register">
      <ActivationHeader />
      <div className="register__container">
        <ActivationContent
          state={activationContent}
          redirectCallback={redirectCallbackMap[activationContent]}
          memberData={memberData}
          activationErrorCode={activationErrorCode}
        />
        <ActivationFooter />
      </div>
    </div>
  );
};

export default Activation;
