/* eslint-disable camelcase */
import { trackPage, trackClick } from ".";
import PagesEnum from "./enums/pages";
import ChaptersEnum from "./enums/chapters";
import LevelsEnum from "./enums/levels";
import ClicTypes from "./enums/clic-types";
import ClicsEnum from "./enums/clics";

// Pages tracking

export function trackPageNeedHelp() {
  return trackPage({
    page: PagesEnum.Contact,
    page_chapter1: ChaptersEnum.Help,
    page_chapter2: ChaptersEnum.Contact,
    site_level2: LevelsEnum.Brand,
  });
}

export function trackPageNeedHelpConfirmation({ error }: {[key: string]: boolean}) {
  return trackPage({
    page: error ? PagesEnum.Error : PagesEnum.Confirmation,
    page_chapter1: ChaptersEnum.Help,
    page_chapter2: ChaptersEnum.Contact,
    site_level2: LevelsEnum.Brand,
  });
}

// Clicks tracking

export function trackClickNeedHelpSubmit() {
  return trackClick({
    click_type: ClicTypes.Action,
    click: ClicsEnum.NeedHelpSubmit,
    click_chapter1: ChaptersEnum.Contact,
    site_level2: LevelsEnum.Brand,
  });
}