import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import { BellHeaderProps } from "@companion-core/shared/app/Components/Bell/Header/index";
import { useWizardStore } from "@companion-core/shared/app/Store/wizardStore";
import { useBellStore } from "@companion-core/shared/app/Store/bellStore";
import {
  getPreviousStep,
  hasFormPath,
  hasOnlyOneRadioShape,
} from "@companion-core/shared/app/Utils/dynamicForm";
import { getModule } from "@companion-core/shared/app/Utils/config";
import { getInitialBellContent } from "@companion-core/shared/app/Utils/bell";
import "@companion-core/web/src/Assets/Styles/scss/bell.scss";

export const BellHeader = ({ onClose }: BellHeaderProps) => {
  const isBellHomeEnabled: boolean = getModule("concierge")?.modules.bell.options.home.enabled;
  const { t } = useTranslation();
  const { bellContent, clearBell, setBellContent } = useBellStore((state) => ({
    bellContent: state.bellContent,
    clearBell: state.clearBell,
    setBellContent: state.setBellContent,
  }));
  const {
    currentStep,
    currentForm,
    formTree,
    setCurrentStep,
    setCurrentForm,
    resetWizard,
    setFields,
    setDefaultApiValues,
  } = useWizardStore((state) => ({
    formTree: state.formTree,
    currentForm: state.currentForm,
    currentStep: state.currentStep,
    resetWizard: state.resetWizard,
    setCurrentStep: state.setCurrentStep,
    setCurrentForm: state.setCurrentForm,
    setFields: state.setFields,
    setDefaultApiValues: state.setDefaultApiValues,
  }));

  /**
   * This state is used to know if we can go back or not
   * We can go back if we are not in the "home" page or in the "categories" page when the "home" page is disabled
   */
  const canGoBack = useMemo(() => {
    return (bellContent === "categories" && isBellHomeEnabled) || bellContent === "form";
  }, [currentForm, currentStep, formTree, bellContent, isBellHomeEnabled]);

  /**
   * This function is used to reset the wizard and the bell and go to the "home" page
   */
  const resetAll = useCallback(() => {
    resetWizard();
    clearBell(getInitialBellContent());
  }, [resetWizard, clearBell]);

  /**
   * This function is used to go back to the previous step
   * - If we are in the "categories" page, we reset the wizard and the bell and go to the "home" page
   * - If we are in the first step of the form, we reset the wizard and the bell and go to the "categories" page
   * - If we are in the middle of the form, we go to the previous step
   */
  const handleBackButton = useCallback(() => {
    if (bellContent === "categories") {
      resetAll();
    } else if (currentForm && currentStep?.order) {
      const { step, jsonForm } = getPreviousStep(currentForm, currentStep.order, formTree);
      const hasFormPathInField = step?.fields.some((field) => hasFormPath(field));
      setCurrentForm(jsonForm);
      if (step && hasOnlyOneRadioShape(step)) step.fields[0].value = ""; // do not need to reload the previous value for radio shapes
      if (hasFormPathInField) {
        setFields([]);
        setDefaultApiValues({ ...jsonForm.defaultApiValues });
        setCurrentStep(step);
      } else if (step) {
        setCurrentStep(step);
      } else {
        resetAll();
        setBellContent("categories");
      }
    }
  }, [currentForm, currentStep, formTree, bellContent]);

  const handleOnClose = useMemo(() => {
    return () => {
      resetAll();
      onClose();
    };
  }, [onClose]);

  return (
    <div data-testid="bell__header" className="bell__header">
      {canGoBack && (
        <span
          data-testid="bell__icon--previous"
          className="bell__header-icon bell__header-icon-previous"
          onClick={handleBackButton}>
          <FontIcon name="JP-Arrow-left-bold" size={24} />
        </span>
      )}
      <p className="bell__header-title title-7">{t("request.concierge.header.title")}</p>
      <span
        data-testid="bell__icon--close"
        className="bell__header-icon bell__header-icon-close"
        onClick={handleOnClose}>
        <FontIcon name="JP-Close-bold" size={24} />
      </span>
    </div>
  );
};
