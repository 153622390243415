import React from "react";
import { useNavigate } from "react-router-dom";
import { BannerLayoutProps } from "@companion-core/shared/app/Interfaces/Blocks";
import { Button } from "@companion-core/shared/app/Components/Button";
import { redirectTo } from "@companion-core/web/src/Utilities/navigation";
import "@companion-core/web/src/Assets/Styles/scss/blockBanner.scss";

export const BannerLayout = ({
  title,
  subtitle,
  description,
  mediaUrl,
  button,
}: BannerLayoutProps) => {
  const navigate = useNavigate();

  return (
    <section
      className="banner-layout"
      data-testid="block-banner"
      style={
        mediaUrl
          ? {
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("${mediaUrl}")`,
            }
          : {}
      }>
      <div className="banner-layout__content">
        <p className="banner-layout__title body-text-6" data-testid="block-banner__title">
          {title}
        </p>
        {subtitle && (
          <p className="banner-layout__subtitle super-title-3" data-testid="block-banner__subtitle">
            {subtitle}
          </p>
        )}
        {description && (
          <p
            className="banner-layout__description"
            data-testid="block-banner__description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
        {button && button.button_title && (
          <Button
            testID="block-banner__cta"
            label={button.button_title}
            type="secondary"
            size="large"
            negative
            classNames={["banner-layout__cta"]}
            onClick={() =>
              redirectTo(button.button_link ?? "/", button?.button_type ?? "internal", navigate)
            }
          />
        )}
      </div>
    </section>
  );
};
