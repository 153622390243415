import { Field } from "@companion-core/shared/app/Interfaces/wizard";
import { JPInput } from "@companion-core/shared/app/Components/Inputs";
import { JPInputPassword } from "@companion-core/shared/app/Components/Inputs/JPInputPassword";
import { JPInputPhone } from "@companion-core/shared/app/Components/Inputs/JPInputPhone";
import { JPInputRadioButton } from "@companion-core/shared/app/Components/Inputs/JPInputRadioButton";
import { JPInputCheckbox } from "@companion-core/shared/app/Components/Inputs/JPInputCheckbox";
import { JPInputSelect } from "@companion-core/shared/app/Components/Inputs/JPInputSelect";
import { JPInputDate } from "@companion-core/shared/app/Components/Inputs/JPInputDate";
import { JPInputCounters } from "@companion-core/shared/app/Components/Inputs/JPInputCounters";
import { JPInputRadioShape } from "@companion-core/shared/app/Components/Inputs/JPInputRadioShape";
import { JPInputAutoComplete } from "@companion-core/shared/app/Components/Inputs/JPInputAutoComplete";
import { JPInputTime } from "@companion-core/shared/app/Components/Inputs/JPInputTime";

export * from "./DynamicInput";

export interface DynamicInputProps {
  field: Field;
  registerValidation: (name: string, validateFn: () => void) => void;
}

export const DynamicInputComponents: Record<
  string,
  | typeof JPInput
  | typeof JPInputPassword
  | typeof JPInputPhone
  | typeof JPInputRadioButton
  | typeof JPInputCheckbox
  | typeof JPInputSelect
  | typeof JPInputDate
  | typeof JPInputCounters
  | typeof JPInputRadioShape
  | typeof JPInputAutoComplete
  | typeof JPInputTime
> = {
  text: JPInput,
  number: JPInput,
  textarea: JPInput,
  password: JPInputPassword,
  phone: JPInputPhone,
  radio: JPInputRadioButton,
  checkbox: JPInputCheckbox,
  select: JPInputSelect,
  date: JPInputDate,
  autocomplete: JPInputAutoComplete,
  counters: JPInputCounters,
  radioshape: JPInputRadioShape,
  budget: JPInput,
  time: JPInputTime,
};
