import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Input, Form } from "antd";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import { getConfig } from "@companion-core/shared/app/Utils/config";
import InputLabel from "@companion-core/web/src/Components/InputLabel";
import "@companion-core/web/src/Assets/Styles/scss/inputpassword.scss";
import { JPInputPasswordProps } from ".";
import { PasswordPolicy } from "@companion-core/shared/app/Interfaces/validators";
import { isStrongPassword, isRegexMatched } from "@companion-core/shared/app/Utils/validators";
import { JPInputError } from "@companion-core/shared/app/Components/Inputs/JPInputError";

export const JPInputPassword: React.FC<JPInputPasswordProps> = ({
  name,
  payloadPath = name,
  required = false,
  label,
  showPolicies = false,
  confirmationPassword = false,
  testID,
  setFieldErrors,
  onValueChange,
  registerValidation,
}: JPInputPasswordProps) => {
  const form = Form.useFormInstance(); // Get current context form instance
  const { t } = useTranslation();
  const [password, setPassword] = useState<string>("");
  const passwordRef = useRef(password); // Create a ref to store the latest value and avoid stale closure
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>("");
  const passwordConfirmationRef = useRef(passwordConfirmation); // Create a ref to store the latest value and avoid stale closure
  const [errorPassword, setErrorPassword] = useState<string>("");
  const [errorConfirmation, setErrorConfirmation] = useState<string>("");
  const policies: PasswordPolicy[] = getConfig()?.options.policies.password ?? [];

  useEffect(() => {
    registerValidation &&
      registerValidation(name, () => {
        handleOnBlur();
        if (confirmationPassword) {
          handleOnBlurConfirmation();
        }
      });
  }, []);

  useEffect(() => {
    // Update refs value
    passwordRef.current = password;
    passwordConfirmationRef.current = passwordConfirmation;
  }, [password, passwordConfirmation]);

  const handleOnBlur = () => {
    const err =
      !passwordRef.current && required
        ? "common.form.input.error.missing"
        : !isStrongPassword(policies, passwordRef.current)
        ? "password.resetV2.instructions"
        : "";
    setErrorPassword(err);
    if (setFieldErrors) {
      const errors = [err, errorConfirmation].filter((element) => Boolean(element));
      setFieldErrors(errors);
    }
  };

  const handleOnBlurConfirmation = () => {
    const err =
      !passwordConfirmationRef.current && required
        ? "common.form.input.error.missing"
        : passwordRef.current !== passwordConfirmationRef.current
        ? "password.resetV2.confirmation.passwordConfirmation.invalid"
        : "";
    setErrorConfirmation(err);
    if (setFieldErrors) {
      const errors = [errorPassword, err].filter((element) => Boolean(element));
      setFieldErrors(errors);
    }
  };

  useEffect(() => {
    if (form && payloadPath) {
      form.setFieldsValue({
        [payloadPath]: password,
      });
    }
    if (onValueChange) {
      onValueChange(password);
    }
  }, [onValueChange, payloadPath, form, password]);

  return (
    <div>
      <div className="input-password">
        {label && <InputLabel label={t(label)} />}
        <Input.Password
          data-testid={testID}
          placeholder={t("login.placeholders.password")}
          status={
            errorPassword && errorPassword !== "password.resetV2.instructions" ? "error" : undefined
          }
          onChange={(e) => setPassword(e.target.value)}
          onBlur={handleOnBlur}
          allowClear={{
            clearIcon: (
              <FontIcon
                size={24}
                name="JP-Circle-close-outline"
                data-testid={`${testID}--clear`}
                className="input-password__icon"
              />
            ),
          }}
          iconRender={(visible: boolean) =>
            visible ? (
              <FontIcon
                size={24}
                name="JP-View"
                data-testid={`${testID}--show-password`}
                className="input-password__icon"
              />
            ) : (
              <FontIcon
                size={24}
                name="JP-Hide"
                data-testid={`${testID}--hide-password`}
                className="input-password__icon"
              />
            )
          }
        />
        {errorPassword && errorPassword !== "password.resetV2.instructions" && (
          <JPInputError testID={testID} error={errorPassword} />
        )}
      </div>
      {confirmationPassword && (
        <div className="input-password-confirmation">
          <InputLabel label={t("password.resetV2.confirmation.title")} />
          <Input.Password
            data-testid={`${testID}--confirmation`}
            placeholder={t("login.placeholders.password")}
            status={errorConfirmation ? "error" : undefined}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            onBlur={handleOnBlurConfirmation}
            allowClear={{
              clearIcon: (
                <FontIcon
                  size={20}
                  name="JP-Circle-close-outline"
                  data-testid={`${testID}--clear`}
                  className="input-password__icon"
                />
              ),
            }}
            iconRender={(visible: boolean) =>
              visible ? (
                <FontIcon
                  size={20}
                  name="JP-View"
                  data-testid={`${testID}--show-password`}
                  className="input-password__icon"
                />
              ) : (
                <FontIcon
                  size={20}
                  name="JP-Hide"
                  data-testid={`${testID}--hide-password`}
                  className="input-password__icon"
                />
              )
            }
          />
          {errorConfirmation && <JPInputError testID={testID} error={errorConfirmation} />}
        </div>
      )}

      {showPolicies && (
        <div className="d-flex flex-direction-column justify-content-flex-start margin-top-24">
          {policies.map((policy: PasswordPolicy, index: number) => {
            return (
              <div key={index} className="d-flex align-items-center margin-bottom-12">
                {isRegexMatched(policy.regex, password) ? (
                  <FontIcon
                    size={20}
                    name="JP-Radio-Check"
                    className="input-password__icon-check"
                  />
                ) : (
                  <FontIcon
                    size={20}
                    name="JP-Circle-close"
                    className="input-password__icon-cancel"
                  />
                )}
                <span className="input-password__policy-label body-text-8">{t(policy.name)}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
