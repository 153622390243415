import React from "react";
import { ComponentData } from "@companion-core/shared/app/Interfaces/API";
import { Carousel } from "@companion-core/shared/app/Components/Carousel";
import { Slide } from "@companion-core/shared/app/Components/Slide";
import { BlockItemProps, PostListLayoutProps } from "@companion-core/shared/app/Interfaces/Blocks";

export const PostListCarouselLayout = ({ cards }: PostListLayoutProps) => {
  return (
    <>
      {cards && cards.length > 0 && (
        <Carousel testID="post-list-carousel">
          {cards.map((slide, index) => (
            <Slide key={`slide-${index}`} slide={slide} />
          ))}
        </Carousel>
      )}
    </>
  );
};
