import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import {
  getCounters,
  getCountersValue,
  getSummaryValue,
  isCountersRequiredButEmpty,
  JPInputCountersProps,
} from "./index";
import { JPCounter } from "@companion-core/shared/app/Components/Counter";
import { JPInputError } from "@companion-core/shared/app/Components/Inputs/JPInputError";

/**
 * Primary UI component for user interaction
 * @return {Component} Input component
 */
export const JPInputCounters: React.FC<JPInputCountersProps> = ({
  name,
  required = true,
  counters,
  value,
  testID,
  payloadPath = name,
  setFieldErrors,
  onValueChange,
  registerValidation,
}: JPInputCountersProps) => {
  const { t } = useTranslation();
  const form = Form.useFormInstance(); // Get current context form instance
  const transformedCounters = useMemo(() => getCounters(counters, t), [counters, t]);
  const transformedValue = useMemo(() => getCountersValue(value, transformedCounters), []);
  const [error, setError] = useState<string>("");
  const [inputValue, setInputValue] = useState<{ [key: string]: number } | number>(value);
  const inputValueRef = useRef(inputValue); // Create a ref to store the latest value and avoid stale closure

  const handleInputChange = (values: { [key: string]: number }) => {
    if (form && payloadPath) {
      form.setFieldsValue({
        [payloadPath]: values,
      });
    }
    setInputValue(values);
    if (onValueChange) {
      onValueChange({ values: values, summaryValue: getSummaryValue(values, t) });
    }
  };

  useEffect(() => {
    registerValidation && registerValidation(name, validateInput);
  }, []);

  useEffect(() => {
    inputValueRef.current = inputValue; // Update ref value
  }, [inputValue]);

  const validateInput = () => {
    const validationError =
      isCountersRequiredButEmpty(required, inputValueRef) && required
        ? "common.form.input.error.missing"
        : "";
    setError(validationError);
    setFieldErrors && setFieldErrors(validationError ? [validationError] : []);
  };

  return (
    <div className="JPInputCounters">
      <JPCounter
        testID={testID}
        required={required}
        counters={transformedCounters}
        value={transformedValue}
        onChange={(values) => handleInputChange(values)}
      />
      {error && <JPInputError testID={testID} error={error} />}
    </div>
  );
};
