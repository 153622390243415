import { PasswordPolicy, Validator } from "@companion-core/shared/app/Interfaces/validators";

export const isRegexMatched = (regex: string, value: string) => {
  const regExp = new RegExp(regex);
  return regExp.test(value);
};

export const isString = (value: any): boolean => {
  if (typeof value !== "string") {
    return false;
  } else {
    return !!(value && value.trim());
  }
};

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isEmail = (value: string): boolean => {
  return emailRegex.test(String(value).toLowerCase());
};

export const isStrongPassword = (policies: PasswordPolicy[], value: string): boolean => {
  return policies.every(policy => isRegexMatched(policy.regex, value));
}

// TODO : remove this function to use isStrongPassword on reset password screen
export const isStrongPasswordReset = (policies: PasswordPolicy[]): boolean =>
  !policies.some((policy) => policy.isValid === false);

// TODO : remove this function to use isStrongPassword on reset password screen
export const validatePasswordPolicies = (
  passwordPolicies: PasswordPolicy[],
  value: string
): PasswordPolicy[] => {
  return passwordPolicies.map((policy) => {
    const regex = new RegExp(policy.regex);
    policy.isValid = regex.test(value);
    return policy;
  });
};

const visaCreditCardRegex = /\b4[0-9]{3}[ -]*[0-9]{4}[ -]*[0-9]{4}[ -]*[0-9](?:[0-9]{3})?/;

export const isVisaCreditCard = (value: string): boolean => {
  return visaCreditCardRegex.test(String(value));
};

export const isArray = (data: any): boolean => {
  return Array.isArray(data);
};

export const isArrayEmpty = (data: any): boolean => {
  return data.length === 0;
};

export const isBoolean = (value: any): boolean => {
  return typeof value === "boolean";
};

export const isObject = (value: any): boolean => {
  return typeof value === "object" && value !== null;
};

export const isLastDigits = (value: Array<number>): boolean => {
  return value.length === 4;
};

export const isAtLeastFourCharLong = (value: any): boolean => {
  return value.match(/[a-zA-Z0-9]/g)?.length >= 4;
};

export const isAllHeartistsCardNumber = (value: string): boolean => {
  return /^[A-Z0-9 ]+$/g.test(value);
};

export const validators = {
  isArray,
  isArrayEmpty,
  isBoolean,
  isEmail,
  isObject,
  isString,
  isStrongPassword,
  isVisaCreditCard,
  isLastDigits,
  isAtLeastFourCharLong,
  isAllHeartistsCardNumber,
};

export default function getValidation(
  arrayValidators: Array<Validator>,
  valueToValidate: string
) {
  // Set the type
  const errorMessages: Array<string> = [];
  // Store all validators result
  const validatorsResult: Array<boolean> = [];

  // Check value for each validator present in config.json
  arrayValidators.forEach((validator: Validator) => {
    const isValueMatchTheValidator: boolean = validators[validator](valueToValidate);
    validatorsResult.push(isValueMatchTheValidator);
    if (!isValueMatchTheValidator) {
      // Generate error message specific to validator
      errorMessages.push("common.form.input.validators." + validator + ".error");
    }
  });

  return {
    isValid: validatorsResult.find((validatorResult: boolean) => !validatorResult) === undefined,
    errorMessages,
  };
}
