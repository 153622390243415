import React, { useState, useEffect, useRef } from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { Checkbox, Form } from "antd";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import { JPInputCheckboxProps } from "./index";
import "@companion-core/web/src/Assets/Styles/scss/checkbox.scss";

export const JPInputCheckbox: React.FC<JPInputCheckboxProps> = ({
  name,
  payloadPath = name,
  value,
  label = "",
  link,
  required = false,
  testID,
  onValueChange,
  setFieldErrors,
  registerValidation,
}: JPInputCheckboxProps) => {
  const form = Form.useFormInstance();
  const [checked, setChecked] = useState<boolean>(value ?? false);
  const checkedRef = useRef(checked); // Create a ref to store the latest value and avoid stale closure
  const [error, setError] = useState<string>("");

  useEffect(() => {
    registerValidation &&
      registerValidation(name, () => {
        validateInput(checkedRef.current);
      });
  }, []);

  useEffect(() => {
    checkedRef.current = checked; // Update ref value
  }, [checked]);

  useEffect(() => {
    if (form && payloadPath) {
      form.setFieldsValue({
        [payloadPath]: checked,
      });
    }
    if (onValueChange) {
      onValueChange(checked);
    }
  }, [onValueChange, checked, form, payloadPath]);

  const validateInput = (isChecked: boolean) => {
    const err = !isChecked && required ? "common.form.input.error.missing" : "";
    setError(err);
    setFieldErrors && setFieldErrors(err ? [err] : []);
  };

  return (
    <div data-testid={testID} className="JPInputCheckbox">
      <Checkbox
        data-testid={`${testID}__checkbox`}
        className="JPInputCheckbox__checkbox"
        onChange={(inputValue) => {
          setChecked(inputValue.target.checked);
          validateInput(inputValue.target.checked);
        }}>
        <div className="d-flex align-items-center body-text-6">
          {checked ? (
            <FontIcon
              data-testid={`${testID}__icon--checked`}
              name="JP-Checkbox-Check"
              className="JPInputCheckbox__checkbox-icon"
            />
          ) : (
            <FontIcon
              data-testid={`${testID}__icon--unchecked`}
              name="JP-Checkbox-Uncheck"
              className={`JPInputCheckbox__checkbox-icon ${
                error && "JPInputCheckbox__checkbox-icon--error"
              }`}
            />
          )}
          {label && (
            <span
              data-testid={`${testID}__label`}
              className={`JPInputCheckbox__checkbox-text ${
                required && "JPInputCheckbox__checkbox-text--required"
              }`}>
              <Trans
                i18nKey={label}
                components={{
                  lnk: (
                    <Link
                      data-testid={`${testID}__link`}
                      to={link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="body-text-5"
                    />
                  ),
                }}
              />
            </span>
          )}
        </div>
      </Checkbox>
    </div>
  );
};
