import React from "react";
import { JPInputErrorProps } from ".";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import { useTranslation } from "react-i18next";
import "@companion-core/web/src/Assets/Styles/scss/inputerror.scss";

export const JPInputError: React.FC<JPInputErrorProps> = ({
  error,
  testID,
  position = "bottom",
}: JPInputErrorProps) => {
  const { t } = useTranslation();

  return (
    <div
      data-testid={`${testID}--error`}
      className={`JPInputError ${position === "top" ? "JPInputError--top" : null}`}>
      <FontIcon
        data-testid={`${testID}--error-icon`}
        name="JP-Info"
        size={20}
        className="JPInputError__icon-error"
      />
      <span className="body-text-8">{t(`${error}`)}</span>
    </div>
  );
};
