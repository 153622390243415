import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BlockProps } from "@companion-core/shared/app/Interfaces/Blocks";
import { ComponentData } from "@companion-core/shared/app/Interfaces/API";
import { useBlockItemComponents } from "@companion-core/shared/app/Queries";
import {
  getComponentsFromBlockItems,
  getComponentText,
} from "@companion-core/shared/app/Utils/componentHelper";
import { ListLayout } from "@companion-core/shared/app/Components/Blocks/Layouts/ListLayout";

export const List = ({ simpleBlockItems, queryBlockItems, contentProps }: BlockProps) => {
  const { i18n } = useTranslation();
  const i18nLanguage: string = i18n?.languages[0]?.toLocaleLowerCase();
  const simpleComponents: ComponentData[] = useMemo(
    () => getComponentsFromBlockItems(simpleBlockItems),
    [simpleBlockItems],
  );
  const title: string = useMemo(
    () => getComponentText(simpleComponents)?.title ?? "",
    [simpleComponents],
  );
  const { isLoading: isQueryComponentsLoading, data: queryComponentsData } = useBlockItemComponents(
    {
      id: queryBlockItems[0]?.id,
      lang: i18nLanguage,
      pageSize: 21,
      page: 1,
    },
  );
  const queryComponents: ComponentData[] = useMemo(
    () => queryComponentsData?.data.results,
    [queryComponentsData],
  );

  return <ListLayout title={title} queryComponents={queryComponents} blockId={contentProps.id} />;
};
