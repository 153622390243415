import { TFunction } from "i18next";
import { ComplexInputValues } from "@companion-core/shared/app/Interfaces/wizard";
import { Counter } from "@companion-core/shared/app/Components/Counter";

export * from "./JPInputCounters";

export interface CounterProps {
  minimumValue: number;
  maximumValue: number;
  defaultValue: number;
}

export interface JPInputCountersProps {
  testID: string;
  name: string;
  required?: boolean;
  value: { [key: string]: number } | number;
  payloadPath?: string;
  counters: { [key: string]: CounterProps };
  onValueChange?: (value: ComplexInputValues) => void;
}

export const getCountersValue = (
  value: { [key: string]: number } | number,
  counters: Counter[],
): Record<string, number> =>
  typeof value === "number" && counters.length === 1
    ? { [counters[0].name]: value }
    : (value as Record<string, number>);

export const getCounters = (counters: { [key: string]: CounterProps }, t: TFunction) => {
  return Object.keys(counters).map((counter) => ({
    name: counter,
    title: t(`common.counters.${counter}.label`),
    subtitle: t(`common.counters.${counter}.description`),
    minimumValue: counters?.[counter]?.minimumValue,
    maximumValue: counters?.[counter]?.maximumValue,
    defaultValue: counters?.[counter]?.defaultValue,
  }));
};

export const getSummaryValue = (values: { [key: string]: number }, t: TFunction) => {
  const separator = t("common.counters.values-separator");
  let summaryValue = "";
  Object.keys(values).forEach((key) => {
    if (values[key] > 0) {
      const value = values[key];
      summaryValue += t(`commonV2.counters.${key}.value`, { count: value, n: value });
      summaryValue += separator;
    }
  });
  return summaryValue.slice(0, -2);
};

export const isCountersRequiredButEmpty = (required, inputValueRef): boolean => {
  if (!required) {
    return false;
  }
  if (typeof inputValueRef.current === "number") {
    return inputValueRef.current === 0;
  }
  return Object.values(inputValueRef.current).every((value) => value === 0);
};
