import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useNavigation } from "react-router-dom";
import { useMediaQuery } from "@companion-core/web/src/Utilities/mediaQuery";
import { useAuthStore } from "@companion-core/shared/app/Store/authStore";
import { getConfig, getHomeRoutes } from "@companion-core/shared/app/Utils/config";
import { TabBarItem } from "@companion-core/shared/app/Interfaces/tabBar";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import { useBellStore } from "@companion-core/shared/app/Store/bellStore";
import { trackClickBell } from "@companion-core/shared/app/tracking/bell";
import "@companion-core/web/src/Assets/Styles/scss/tabBar.scss";

export const TabBar = () => {
  const isMobileSize = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { options } = getConfig();
  const { logged: homeRouteLogged } = getHomeRoutes();
  const { authenticated } = useAuthStore((state) => ({ authenticated: state.authenticated }));
  const [scrolling, setScrolling] = useState<boolean>(false);
  const { isPopUpVisible, setIsPopUpVisible } = useBellStore((state) => ({
    isPopUpVisible: state.isPopUpVisible,
    setIsPopUpVisible: state.setIsPopUpVisible,
  }));
  let scrollShadowTimeout: NodeJS.Timeout;

  const isDisplayed = () => {
    let hasMatched = false;
    options.routesWithoutTabBarRegexps?.forEach((regexp) => {
      const r = new RegExp(regexp, "gm");
      let m;
      while ((m = r.exec(window.location.pathname)) !== null) {
        if (m.index === r.lastIndex) {
          r.lastIndex++;
        }
        if (m.length > 0) hasMatched = true;
      }
    });
    return !hasMatched;
  };

  const handleOnScroll = () => {
    clearTimeout(scrollShadowTimeout);
    setScrolling(true);
    scrollShadowTimeout = setTimeout(() => setScrolling(false), 500);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleOnScroll);

    return () => {
      window.removeEventListener("scroll", handleOnScroll);
    };
  }, []);

  const isCurrentLocation = (pathname: string) => {
    if (pathname === homeRouteLogged) {
      return `${homeRouteLogged}` === location.pathname || "/" === location.pathname;
    }
    return `/${pathname}` === location.pathname;
  };

  const getModifier = (isMiddle: boolean, pathname: string) => {
    if (isMiddle) {
      return "tab-bar__item--middle";
    } else if (isCurrentLocation(pathname)) {
      return "tab-bar__item--focused";
    }
    return "";
  };

  const toggleBell = () => {
    setIsPopUpVisible(!isPopUpVisible);
    trackClickBell(isPopUpVisible ? "Reduce_Bell" : "Open_Bell");
  };

  return (
    isDisplayed() &&
    authenticated &&
    isMobileSize && (
      <div data-testid="tab-bar" className={`tab-bar ${scrolling && "tab-bar--scrolling"}`}>
        {options.tabBar.map((item: TabBarItem, index: number) => (
          <div
            key={index}
            data-testid={`tab-bar__item${item.name === "concierge" ? "-bell" : ""}`}
            className={`tab-bar__item ${getModifier(item.isMiddle, item.slugPage)}`}
            onClick={() => (item.name === "concierge" ? toggleBell() : navigate(item.slugPage))}>
            <FontIcon className="tab-bar__item-icon" name={item.icon} size={24} />
            {!item.isMiddle && (
              <span
                className={`tab-bar__item-label ${
                  isCurrentLocation(item.slugPage) ? "body-text-9" : "body-text-10"
                }`}>
                {t(`common.tabBar.links.${item.name}`)}
              </span>
            )}
          </div>
        ))}
      </div>
    )
  );
};
