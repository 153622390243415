import { requestStatusColorClass } from "@companion-core/shared/app/Constants/myRequests";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  status: string;
}

export const RequestStatus = ({ status }:Props) => {
  const { t } = useTranslation();

  return <div
    className={`body-text-7 request-card__status request-card__status--${requestStatusColorClass[status]}`}
    data-testid="request-card__status"
  >
    {t(`request.my-requests.statusV2.${status}`)}
  </div>
}