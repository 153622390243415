import React from "react";
import { JPInputTipsProps } from ".";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import { useTranslation } from "react-i18next";
import "@companion-core/web/src/Assets/Styles/scss/inputtips.scss"

export const JPInputTips: React.FC<JPInputTipsProps> = ({
  tip,
  icon,
  testID,
}: JPInputTipsProps) => {
  const { t } = useTranslation();

  return (
    <div
      data-testid={`${testID}--tips`}
      className="JPInputTips">
      {icon && <FontIcon
        name={icon}
        size={20}
        className="JPInputTips__icon-tips"
      />}
      <span className="body-text-8">{t(`${tip}`)}</span>
    </div>
  );
};