import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

// Styles
import "@companion-core/web/src/Assets/Styles/scss/passwordManagement.scss";

// Tracking
import { trackPageResetPassword } from "@companion-core/shared/app/tracking/login";
import { useRecoverPassword } from "@companion-core/shared/app/Queries";
import { IndependentDynamicForm } from "@companion-core/shared/app/Components/DynamicForm/IndependentDynamicForm";
import { getJSONFromFile } from "@companion-core/shared/app/Assets/forms/password/jsonLoader";
import { Spin } from "antd";

const RecoverPassword = () => {
  const { mutate: recoverPassword, isLoading, isSuccess } = useRecoverPassword();
  const { t } = useTranslation();
  const jsonForm = useMemo(() => getJSONFromFile("password/recover.json"), []);

  useEffect(() => {
    trackPageResetPassword();
  }, []);

  return (
    <div className="passwordManagement__container d-flex justify-content-center">
      <span className="super-title-3 d-flex align-self-center">
        {isSuccess ? t("password.recoverV2.confirmation.title") : t("password.recoverV2.title")}
      </span>

      <div className="margin-y-32">
        <span className="body-text-6">
          {isSuccess ? t("password.recoverV2.confirmation.text") : t("password.recoverV2.text")}
        </span>
      </div>

      {isSuccess ? (
        <a href="/login" className="body-text-5 text-decoration-none">
          {t("password.recoverV2.confirmation.links.back")}
        </a>
      ) : (
        jsonForm && (
          <>
            <IndependentDynamicForm
              name="recoverPassword"
              initialFormJSON={jsonForm}
              onSubmitForm={(payload) => {
                recoverPassword(payload as { "email": string });
              }}
            />
            {isLoading && <Spin data-testid="recoverPassword__loader" size="large" />}
          </>
        )
      )}
    </div>
  );
};

export default RecoverPassword;
