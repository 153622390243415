// Note: This file is used to load the JSON files for the dynamic forms in the Bell component
// The JSON files are located in the shared/app/Assets/forms/concierge folder
// ReactNative does not support the dynamic loading of JSON files, so we have to import them manually
import { DynamicFormJson } from "@companion-core/shared/app/Interfaces/wizard";
import hotelJson from "./accommodation/hotel.json";
import recommendationJson from "./accommodation/recommendation.json";
import reservationJson from "./accommodation/reservation.json";
import ticketJson from "./ticket/ticket.json";
import museumJson from "./ticket/museum.json";
import concertJson from "./ticket/concert.json";
import sportsEventsJson from "./ticket/sportsEvents.json";
import ticketOtherJson from "./ticket/other.json";
import transportationJson from "./transportation/transportation.json";
import flightJson from "./transportation/flight.json";
import trainJson from "./transportation/train.json";
import taxiJson from "./transportation/taxi.json";
import carServiceJson from "./transportation/carService.json";
import otherTransportationJson from "./transportation/other.json";
import transportationOneWayJson from "./transportation/oneWay.json";
import transportationRoundTripJson from "./transportation/roundTrip.json";
import stayJson from "./stay/tours.json";
import stayOneWayJson from "./stay/oneWay.json";
import stayRoundTripJson from "./stay/roundTrip.json";
import gourmetJson from "./gourmet/gourmet.json";
import deliveryJson from "./gourmet/delivery.json";
import homeCookJson from "./gourmet/chef.json";
import restaurantJson from "./gourmet/restaurant.json";
import recommandationJson from "./gourmet/recommandation.json";
import restaurantReservationJson from "./gourmet/reservation.json";
import dailyServicesJson from "./dailyServices/dailyServices.json";
import childCareJson from "./dailyServices/childcare.json";
import cleaningJson from "./dailyServices/cleaning.json";
import electricianJson from "./dailyServices/electrician.json";
import formalitiesJson from "./dailyServices/formalities.json";
import locksmithJson from "./dailyServices/locksmith.json";
import plumberJson from "./dailyServices/plumber.json";
import otherJson from "./dailyServices/other.json";
import carRentalJson from "./carRental/carRental.json";
import shoppingJson from "./shopping/shopping.json";

const conciergeFormsMap: Record<string, Record<string, DynamicFormJson>> = {
  accommodation: {
    hotel: hotelJson,
    recommendation: recommendationJson,
    reservation: reservationJson,
  },
  gourmet: {
    gourmet: gourmetJson,
    delivery: deliveryJson,
    chef: homeCookJson,
    restaurant: restaurantJson,
    recommandation: recommandationJson,
    reservation: restaurantReservationJson,
  },
  ticket: {
    ticket: ticketJson,
    museum: museumJson,
    concert: concertJson,
    sportsEvents: sportsEventsJson,
    other: ticketOtherJson,
  },
  transportation: {
    flight: flightJson,
    train: trainJson,
    taxi: taxiJson,
    carService: carServiceJson,
    other: otherTransportationJson,
    oneWay: transportationOneWayJson,
    roundTrip: transportationRoundTripJson,
    transportation: transportationJson,
  },
  stay: {
    tours: stayJson,
    oneWay: stayOneWayJson,
    roundTrip: stayRoundTripJson,
  },
  dailyServices: {
    dailyServices: dailyServicesJson,
    childCare: childCareJson,
    cleaning: cleaningJson,
    electrician: electricianJson,
    formalities: formalitiesJson,
    locksmith: locksmithJson,
    plumber: plumberJson,
    other: otherJson,
  },
  carRental: {
    carRental: carRentalJson,
  },
  shopping: {
    shopping: shoppingJson,
  },
};

export const createDeepCopy = (obj: DynamicFormJson) => {
  return JSON.parse(JSON.stringify(obj)) as DynamicFormJson;
};

/* Get the JSON from the file path provided
 * @param path - the path to the JSON file
 * @returns the JSON object
 * */
export const getJSONFromFile = (path: string) => {
  const [formCategory, formSubCategory] = path.replace(".json", "").split("/").slice(-2);
  const originalFile = conciergeFormsMap[formCategory]?.[formSubCategory] ?? {};
  return createDeepCopy(originalFile);
};
