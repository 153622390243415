// Note: This file is used to load the JSON files for the dynamic forms in the RequestDetail component
// The JSON files are located in the shared/app/Assets/forms/myRequests folder
// ReactNative does not support the dynamic loading of JSON files, so we have to import them manually
import { DynamicFormJson } from "@companion-core/shared/app/Interfaces/wizard";
import modifyJson from "./detail/modify.json";

const myRequestsFormsMap: Record<string, Record<string, DynamicFormJson> | DynamicFormJson> = {
  detail: {
    modify: modifyJson,
  },
};

export const createDeepCopy = (obj: DynamicFormJson) => {
  return JSON.parse(JSON.stringify(obj)) as DynamicFormJson;
};

/* Get the JSON from the file path provided
 * @param path - the path to the JSON file
 * @returns the JSON object
 * */
export const getJSONFromFile = (path: string) => {
  const [myRequestsMethod, myRequestsForm] = path.replace(".json", "").split("/").slice(-2);
  const originalFile = myRequestsFormsMap[myRequestsMethod]?.[myRequestsForm] ?? {};
  return createDeepCopy(originalFile);
};
