import dayjs, { Dayjs } from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import { LOCALE_IMPORTS } from "@companion-core/shared/app/Constants/locales";
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(localeData);

/**
 * Loads the specified locale into the application.
 *
 * @param {string} lang - The language code of the locale to be loaded.
 * @returns {Promise} - A promise that resolves after the locale is loaded.
 */
export const loadLocale = async (lang: string) => {
  // English is the default locale, so we don't need to load it.
  if (lang !== "en") await LOCALE_IMPORTS[lang]();
  dayjs.locale(lang);
};

/**
 * Retrieves the localized date format.
 *
 * @returns {string} The localized date format.
 */
export const getLocalizedDateFormat = (): string => {
  return dayjs.localeData().longDateFormat("L");
};

/**
 * Retrieves the localized time format.
 *
 * @returns {string} The localized time format.
 */
export const getLocalizedTimeFormat = (): string => {
  return dayjs.localeData().longDateFormat("LT");
};

/**
 * Converts a localized string to a Dayjs object representing date and time.
 *
 * @param {string} date - The localized string representation of date.
 * @returns {Dayjs} - A Dayjs object representing the converted date.
 */
export const localizedStringToDate = (date: string): Dayjs => {
  return dayjs(date, "L");
};

/**
 * Converts a localized string to a Dayjs object representing time.
 *
 * @param {string} time - The localized time string.
 * @returns {Dayjs} The Dayjs object representing the time.
 */
export const localizedStringTotime = (time: string): Dayjs => {
  return dayjs(time, "LT");
};

/**
 * Converts a Dayjs instance to a localized string representation of the date.
 *
 * @param {Dayjs} date - The Dayjs instance to convert.
 * @returns {string} - The localized string representation of the date.
 */
export const dateToLocalizedString = (date: Dayjs): string => {
  return dayjs(date).format("L");
};

/**
 * Converts a Dayjs object to a localized string representation of time.
 *
 * @param {Dayjs} time - The Dayjs object representing the time.
 * @returns {string} - The localized string representation of time.
 */
export const timeToLocalizedString = (time: Dayjs): string => {
  return dayjs(time).format("LT");
};

/**
 * Converts a date and optional time to a payload format using the localized date and time formats.
 *
 * The payload format is based on the provided date and time, and is formatted using the localized date and time formats.
 *
 * @param {string} date - The date in string format (e.g., "2022-01-01").
 * @param {string} [time] - Optional. The time in string format (e.g., "14:30").
 * @returns {string} - The date and time formatted in the payload format.
 */
export const datetimeToPayloadFormat = (date: string, time?: string) => {
  const dateFormat = getLocalizedDateFormat();
  const timeFormat = getLocalizedTimeFormat();
  const datetime = time ? `${date} ${time}` : date;
  const format = time ? `${dateFormat} ${timeFormat}` : dateFormat;
  return dayjs(datetime, format).format();
};

export const getDate = (date: string) => {
  const number = date.match(/(\d+)/);
  const unit = date.match(/(day|week|month|year)/g);
  if (number && unit) return dayjs().add(+number[0], unit[0]);
  return date === "now" ? dayjs() : dayjs(date);
};

export const isBetweenDates = (d: Dayjs, minimumDate: string, maximumDate: string) => {
  return !!(
    !d ||
    (minimumDate && d.isBefore(getDate(minimumDate), "day")) ||
    (maximumDate && d.isAfter(getDate(maximumDate), "day"))
  );
};

/**
 * Checks if the current locale's time format is in 12-hour format.
 *
 * @returns {boolean} - `true` if the time format is in 12-hour format, `false` otherwise.
 */
export const is12HourFormat = () => {
  try {
    return /[Aa]/.test(getLocalizedTimeFormat());
  } catch {
    return false;
  }
};
