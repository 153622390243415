import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import { getUpdatedFields, toApiPayload } from "@companion-core/shared/app/Utils/wizardHelper";
import {
  isStepValid,
  getNextStep,
  getFirstStep,
  hasFormPath,
  hasOnlyOneRadioShape,
} from "@companion-core/shared/app/Utils/dynamicForm";
import { DynamicFormProps } from "./index";
import { DynamicStep, DynamicStepRef } from "./DynamicStep";
import { Button } from "@companion-core/shared/app/Components/Button";
import "@companion-core/web/src/Assets/Styles/scss/dynamicForm.scss";
import "@companion-core/web/src/Assets/Styles/scss/global.scss";
import { Summary } from "@companion-core/shared/app/Components/DynamicForm/Summary";
import {
  useWizardStoreWithProvider,
  useWizardStore,
} from "@companion-core/shared/app/Store/wizardStore";
import { trackClickBell } from "@companion-core/shared/app/tracking/bell";
import DynamicFormContextProvider from "./DynamicFormContext";

/**
 * DynamicForm is a React component that renders a dynamic form based on the provided JSON configuration.
 * It supports multiple steps, validation, and summary.
 *
 * **You should not give different initialFormJSON to the same dynamic form.**
 * *Eventually, you should be able to use the same dynamic form with different initialFormJSON
 * if you reset it or if you have just one step.*
 *
 * @component
 * @param {Object} props - The properties that define the DynamicForm component.
 * @param {string} props.name - The name of the form.
 * @param {Object} props.initialFormJSON - The initial JSON configuration of the form.
 * @param {boolean} [props.useProvider=false] - Determines whether to use the wizard store with provider.
 * @param {Function} props.onSubmitForm - The function to be called when the form is submitted.
 * @param {Function} [props.onSubmitConclude] - The function to be called after the form submission is concluded.
 *
 * @returns {React.ReactElement} The DynamicForm component.
 */
export const DynamicForm = ({
  name,
  initialFormJSON,
  useProvider = false,
  onSubmitForm,
  onSubmitConclude,
}: DynamicFormProps): React.ReactElement => {
  const {
    fields,
    haveSummary,
    currentStep,
    currentForm,
    defaultApiValues,
    formTree,
    submitButtonLoading,
    resetWizard,
    addStepToFormTree,
    addFirstStepToFormTree,
    deleteStepsAfterIndex,
    setFields,
    setCurrentStep,
    setCurrentForm,
    setDefaultApiValues,
    setHaveSummary,
    setSubmitButtonLoading,
  } = useProvider ? useWizardStoreWithProvider() : useWizardStore();

  const { t } = useTranslation();
  const [summaryStepAdded, setSummaryStepAdded] = useState<boolean>(false);
  const numberOfSteps = useMemo(() => currentForm?.steps.length, [currentForm, summaryStepAdded]);
  const dynamicStepRef = useRef<DynamicStepRef>(null);
  const isOneStepForm = useMemo(() => numberOfSteps === 1, [numberOfSteps]);
  const [summaryTitle, setSummaryTitle] = useState<string>("");

  const addSummaryStep = () => {
    if (!numberOfSteps) return;
    const summaryStep = {
      order: numberOfSteps + 1,
      fields: [],
      type: "summary",
      title: summaryTitle,
    };
    currentForm?.steps.push(summaryStep);
    setSummaryStepAdded(true);
  };

  // Final step is the last step of the complete form
  const isFinalStep = useMemo(() => {
    if (!currentStep) return false;

    let isFinalStep = false;
    if (isOneStepForm || currentStep?.order === numberOfSteps) {
      const hasFormPathInField = currentStep?.fields.some((field) => hasFormPath(field));
      isFinalStep = !hasFormPathInField;
    }
    if (isFinalStep && haveSummary && !summaryStepAdded) {
      addSummaryStep();
      return false;
    }
    return isFinalStep || currentStep?.type === "summary";
  }, [isOneStepForm, currentStep, numberOfSteps, currentForm]);

  useEffect(() => {
    if (currentForm && currentStep) return; // We don't want to reset the form if we already have a form
    setHaveSummary(initialFormJSON.summary ?? false);
    setSummaryTitle(initialFormJSON.summaryTitle ?? "");
    setCurrentForm(initialFormJSON);
    setDefaultApiValues(initialFormJSON.defaultApiValues ?? {});
    const step = getFirstStep(initialFormJSON);
    setCurrentStep(step);
    if (step) {
      addFirstStepToFormTree(step?.order, initialFormJSON.jsonPath, initialFormJSON);
    }
  }, [initialFormJSON]);

  const incrementStep = () => {
    if (!currentStep || !currentForm || !dynamicStepRef.current) return;
    dynamicStepRef.current.validateAllFields();
    if (!isStepValid(currentStep)) return;

    const updatedFields = getUpdatedFields(fields, currentStep, currentForm.jsonPath);
    setFields(updatedFields);
    const { step, jsonForm } = getNextStep(
      currentForm,
      currentStep,
      formTree,
      addStepToFormTree,
      deleteStepsAfterIndex,
    );
    if (step?.tracking) {
      // eslint-disable-next-line camelcase
      const { name = "", chapter1 = "", chapter2 = "", chapter3 = "" } = step.tracking;
      trackClickBell(name, chapter1, chapter2, chapter3);
    } else if (step?.type === "summary" && currentStep.tracking) {
      const { chapter1 = "", chapter2 = "" } = currentStep.tracking;
      trackClickBell("step_summary", chapter1, chapter2);
    }
    setDefaultApiValues({ ...defaultApiValues, ...jsonForm.defaultApiValues });
    setCurrentForm(jsonForm);
    if (step) setCurrentStep(step);
  };

  const submitForm = async () => {
    if (!currentStep || !currentForm) return;
    dynamicStepRef.current && dynamicStepRef.current.validateAllFields();
    if (!isStepValid(currentStep)) return;

    setSubmitButtonLoading(true);
    const updatedFields = getUpdatedFields(fields, currentStep, currentForm.jsonPath);
    setFields(updatedFields);
    const payload = toApiPayload(updatedFields, defaultApiValues);
    await onSubmitForm({ ...payload });
    resetWizard();
    if (onSubmitConclude) onSubmitConclude();
    setSubmitButtonLoading(false);
  };

  return (
    <Form
      name={name}
      data-testid={`dynamic-form--${name}`}
      layout="vertical"
      autoComplete="off"
      requiredMark="optional"
      className={`dynamic-form ${
        currentStep && hasOnlyOneRadioShape(currentStep) ? "dynamic-form--full-height" : ""
      }`}>
      {currentForm && currentStep?.title && (
        <span className="dynamic-form__title title-6">{t(`${currentStep?.title}`)}</span>
      )}
      {currentForm &&
        currentStep &&
        (currentStep.type !== "summary" ? (
          <DynamicFormContextProvider
            toNextStep={currentStep && !isFinalStep ? incrementStep : submitForm}>
            <DynamicStep
              key={`step--${currentStep.order}`}
              step={currentStep}
              ref={dynamicStepRef}
            />
          </DynamicFormContextProvider>
        ) : (
          <Summary />
        ))}
      {currentStep && !hasOnlyOneRadioShape(currentStep) && (
        <div className="dynamic-form__button-container">
          {!isFinalStep && (
            <Form.Item className="margin-left-auto margin-top-auto">
              <Button
                testID={`dynamic-form__button-next--${name}`}
                htmlType="submit"
                type="primary"
                label={t("dynamic-form.button.next")}
                onClick={incrementStep}
              />
            </Form.Item>
          )}
          {isFinalStep && (
            <Form.Item className="margin-top-auto">
              <Button
                testID={`dynamic-form__button-submit--${name}`}
                type="primary"
                icon={currentForm?.submitIcon}
                label={
                  currentForm?.submitLabel
                    ? t(currentForm?.submitLabel)
                    : t("dynamic-form.button.submit")
                }
                loading={submitButtonLoading}
                onClick={submitForm}
              />
            </Form.Item>
          )}
        </div>
      )}
    </Form>
  );
};
