import React from "react";

import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import { useTranslation } from "react-i18next";

import "@companion-core/web/src/Assets/Styles/scss/myrequestsCard.scss";

import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { getFamilyIcon } from "@companion-core/shared/app/Utils/myRequests";
import { RequestStatus } from "../RequestStatus/RequestStatus";
import { RequestCardProps } from ".";
dayjs.extend(localizedFormat);

export const RequestCard = ({ data, onClick }: RequestCardProps) => {
  const { t } = useTranslation();
  const location = data["start_location"] ?? data["location"];

  return(
    <div
      className={`request-card ${data.status === "cancelled" ? "request-card--cancelled" : ""}`}
      onClick={() => onClick()}
      data-testid="request-card"
    >
      <div className="request-card__data">
        <div className="d-flex" data-testid="request-card__title">
          <FontIcon name={getFamilyIcon(data.family, data.subfamily)} size={24} className="body-text-5 margin-right-8 request-card__icon" />
          <div className="body-text-5 margin-bottom-8">
            {data.subject}
            {location &&
              <>
                {t("request.my-requests.location.particle")}
                {location.city as string}
              </>
            }
          </div>
        </div>
        <div className="body-text-8 request-card__date" data-testid="request-card__date">
          <span>
            {dayjs(data.created_date).format( `L [${t("request.my-requests.time.particle")}] LT`)}
          </span>
        </div>
        <div>
          <RequestStatus status={data.status} />
        </div>
      </div>
      <div className="request-card__placeholder"></div>
      <div className="request-card__arrow" data-testid="request-card__arrow">
        <FontIcon size={16} name="JP-Arrow-right-bold"/>
      </div>
    </div>
  );
}