import React, { useMemo } from "react";
import { Input as InputAnt } from "antd";
import { JPInputElementProps } from "@companion-core/shared/app/Components/Inputs/JPInput/JPInputElement";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import { JPInputError } from "@companion-core/shared/app/Components/Inputs/JPInputError";
import InputLabel from "@companion-core/web/src/Components/InputLabel";

const Components: Record<string, typeof InputAnt | typeof InputAnt.TextArea> = {
  text: InputAnt,
  number: InputAnt,
  textarea: InputAnt.TextArea,
  budget: InputAnt,
};

export const JPInputElement = ({
  testID,
  type,
  name,
  icon,
  label,
  prefix,
  value,
  maxLength,
  error,
  onChange,
  onBlur,
  onReset,
  placeholder,
  ...props
}: JPInputElementProps): React.ReactElement<JPInputElementProps> => {
  const Component = useMemo(() => {
    return Components[type];
  }, [type]);

  return (
    <div className="JPInputElement">
      <InputLabel label={label ?? ""} icon={icon} />
      <Component
        {...props}
        placeholder={placeholder}
        status={error ? "error" : undefined}
        data-testid={testID}
        name={name}
        prefix={prefix}
        value={value}
        maxLength={maxLength}
        allowClear={{
          clearIcon: (
            <FontIcon
              name="JP-Circle-close-outline"
              size={24}
              data-testid={`${testID}--clear`}
              onClick={onReset}
            />
          ),
        }}
        onChange={onChange}
        onBlur={onBlur}
      />
      {error && <JPInputError testID={testID} error={error} />}
    </div>
  );
};
