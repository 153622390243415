import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";
import { persist, createJSONStorage } from "zustand/middleware";
import i18n from "i18next";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useBellStore } from "@companion-core/shared/app/Store/bellStore";
import { useMemberStore } from "@companion-core/shared/app/Store/memberStore";
import { useWizardStore } from "@companion-core/shared/app/Store/wizardStore";
import { useAccountNavigationStore } from "@companion-core/shared/app/Store/accountNavigationStore";
import { authLogin, authLogout } from "@companion-core/shared/app/Utils/apiClient";
import { isWeb } from "@companion-core/shared/app/Utils/platform";
import { setUser } from "@companion-core/shared/app/tracking";
import { refetchConfig } from "@companion-core/shared/app/Utils/config";

interface AuthType {
  success: boolean;
  message?: string;
}

interface AuthState {
  accessTokenClient: string | null;
  authenticated: boolean;
  error: string | null;
  revokeError: string | null;
  loading: boolean;
  revoking: boolean;
  memberToRegister: Record<string, unknown> | null;
}

interface AuthActions {
  setAccessTokenClient: (accessTokenClient: string | null) => void;
  setAuthenticated: (authenticated: boolean) => void;
  setError: (error: string | null) => void;
  setLoading: (loading: boolean) => void;
  setMemberToRegister: (memberToRegister: Record<string, unknown> | null) => void;
  authAction: (username: string, password: string) => Promise<AuthType>;
  logout: () => Promise<void>;
  resetAuth: () => void;
}

export const initialState: AuthState = {
  accessTokenClient: null,
  authenticated: false,
  error: null,
  revokeError: null,
  loading: false,
  revoking: false,
  memberToRegister: null,
};

export const useAuthStore = createWithEqualityFn<AuthState & AuthActions>()(
  persist(
    (set, get) => ({
      ...initialState,
      setAccessTokenClient: (accessTokenClient: string | null) => set({ accessTokenClient }),
      setAuthenticated: (authenticated: boolean) => set({ authenticated }),
      setError: (error: string | null) => set({ error }),
      setLoading: (loading: boolean) => set({ loading }),
      setMemberToRegister: (memberToRegister: Record<string, unknown> | null) =>
        set({ memberToRegister }),
      authAction: async (username: string, password: string) => {
        try {
          set({ loading: true });
          await authLogin(username, password);
          set({ authenticated: true });
          await refetchConfig();
          set({ loading: false });
          return { success: true };
        } catch (error: any) {
          const { message } = error;
          set({ loading: false, error: i18n.t("login.error." + message) });
          return { success: false, message };
        }
      },
      logout: async () => {
        if (!get().revoking) {
          try {
            set({ ...initialState, revoking: true });
            await authLogout();
            setUser("", "", ""); // Reset User infos for tracking events
            set({ revoking: false });
            // We also need to reset all other stores
            useBellStore.getState().resetBell();
            useAccountNavigationStore.getState().resetAccountNavigation();
            useMemberStore.getState().resetMember();
            useWizardStore.getState().resetWizard();
            await refetchConfig();
          } catch (error: any) {
            set({ revoking: false, error: error });
          }
        }
      },
      resetAuth: () => set({ ...initialState }),
    }),
    {
      name: "auth-storage",
      storage: createJSONStorage(() => (isWeb() ? localStorage : AsyncStorage)),
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(([key]) => ["authenticated"].includes(key)),
        ), // persist only authenticated state
    },
  ),
  shallow,
);
