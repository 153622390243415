import { useState, useEffect } from "react";
import { useAuthStore } from "@companion-core/shared/app/Store/authStore";
import { useAuthClientToken } from "@companion-core/shared/app/Queries";
import jwtDecode, { JwtPayload } from "jwt-decode";

/**
 * TO DO : remove this hooks when refresh client token will be handle by BFF
 * Refresh client token when needed (current token is expired or null)
 * return true when client token has been refreshed or not expired yet
 * @returns {boolean} isClientTokenFetched
 */
export const useClientToken = () => {
  const authenticated = useAuthStore.getState().authenticated;
  const accessTokenClient = useAuthStore.getState().accessTokenClient;
  const [shouldRefreshClientToken, setShouldRefreshClientToken] = useState<boolean>(false);
  const [isClientTokenFetched, setIsClientTokenFetched] = useState<boolean>(false);
  const { data, isLoading } = useAuthClientToken(shouldRefreshClientToken);

  useEffect(() => {
    if (authenticated) {
      setShouldRefreshClientToken(false);
      setIsClientTokenFetched(true);
    } else {
      if (accessTokenClient) {
        const jwt: JwtPayload = jwtDecode(accessTokenClient);
        const isExpired = jwt.exp ? Date.now() >= jwt.exp * 1000 : false;
        setShouldRefreshClientToken(isExpired);
        setIsClientTokenFetched(!isExpired);
      } else {
        setShouldRefreshClientToken(true);
        setIsClientTokenFetched(false);
      }
    }
  }, [authenticated, accessTokenClient]);

  useEffect(() => {
    if (!isLoading && data) {
      useAuthStore.getState().setAccessTokenClient(data.access_token);
      setIsClientTokenFetched(true);
    }
  }, [data, isLoading]);

  return isClientTokenFetched;
};
