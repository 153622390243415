/* eslint-disable camelcase */
import instance, { client } from "@johnpaul/jp-api-client";
import { getApiURL, getClientCredentials } from "./config";
import { getErrorCode } from "@companion-core/shared/app/Utils/errors";
import {
  APIMemberData,
  APIRegisterResponse,
  HashParams,
} from "@companion-core/shared/app/Interfaces/API";
import { IdentityCriteria } from "@companion-core/shared/app/Interfaces/member";
import dayjs from "dayjs";
import { useAuthStore } from "@companion-core/shared/app/Store/authStore";
import jwtDecode, { JwtPayload } from "jwt-decode";
import {
  GooglePlaceAddress,
  SearchAddressResponse,
} from "@companion-core/shared/app/Interfaces/adresses";
import { formatLocation } from "@companion-core/shared/app/Utils/address";
import { useConfigStore } from "@companion-core/shared/app/Store/configStore";

export interface APIError {
  response: {
    status: number;
  };
}

export let hashPassword: (
  password: string,
  salt: string,
  iterations: number,
  memory: number,
  parallelism: number,
  hashLength: number,
) => Promise<string>;

export function initHashPassword(
  hashPasswordFunction: (
    password: string,
    salt: string,
    iterations: number,
    memory: number,
    parallelism: number,
    hashLength: number,
  ) => Promise<string>,
) {
  hashPassword = hashPasswordFunction;
}

export function initApiClient() {
  instance.defaults.baseURL = getApiURL();
}

export async function getHashParams({ username = "", sub = "", token = "" }) {
  const params: HashParams = {};
  if (username) params.username = username;
  if (sub) params.sub = sub;
  if (token) params.token = token;

  return (await client.auth.password.hash(params)).data;
}

/**
 * Retrieves the client token using the client credentials and returns it.
 * Must be called with a system to refresh the token.
 * @async
 * @function getClientTokenFn
 * @returns {Promise<any>} A promise that resolves to the function response.
 * @throws {Error} Throws an error if unable to retrieve the client token.
 */
export async function getClientTokenFn(): Promise<any> {
  return client.auth
    .token(getClientCredentials().clientId, getClientCredentials().clientSecret, {
      grant_type: "client_credentials",
    })
    .then((response: Record<string, unknown>) => response?.data)
    .catch((error: any) => {
      throw new Error(getErrorCode(error));
    });
}

/**
 * Retrieves the client token used for authentication and refreshes it if needed.
 * This function complements the `useClientToken` hook by providing a way to retrieve the token
 *
 * @async
 * @returns {Promise<string>} The client token if the user is authenticated and the token is valid.
 * @throws {Error} Throws an error if unable to retrieve the client token.
 */
export const getClientToken = async (): Promise<string> => {
  const authenticated = useAuthStore.getState().authenticated;
  const accessTokenClient = useAuthStore.getState().accessTokenClient;

  if (authenticated && accessTokenClient) {
    const jwt: JwtPayload = jwtDecode(accessTokenClient);
    const isExpired = jwt.exp ? Date.now() >= jwt.exp * 1000 : false;

    if (!isExpired) {
      return accessTokenClient;
    }
  }

  try {
    const newToken = await getClientTokenFn();
    useAuthStore.getState().setAccessTokenClient(newToken.access_token);
    return newToken.access_token;
  } catch (error) {
    throw new Error(getErrorCode(error));
  }
};

export async function authLogin(username: string, password: string) {
  try {
    const { clientId, clientSecret } = getClientCredentials();
    const { data } = await client.auth.token(clientId, clientSecret, {
      grant_type: "client_credentials",
    });

    const { hash_len, m, p, salt, t } = await getHashParams({ username, token: data.access_token });

    const hashedPassword = await hashPassword(password, salt, t, m, p, hash_len);

    const params = {
      grant_type: "password",
      username: username.toLowerCase(),
      password: hashedPassword,
    };

    const response = await client.auth.token(clientId, clientSecret, params, {
      doNotForceExit: true,
    });
    return response.data;
  } catch (error) {
    throw new Error(getErrorCode(error));
  }
}

export async function authLogout(): Promise<void> {
  try {
    await client.auth.revoke(
      getClientCredentials().clientId,
      getClientCredentials().clientSecret,
      "",
    );
  } catch (error: any) {
    throw new Error(getErrorCode(error));
  }
}

export async function bellRequest(family: string, payload: Record<string, unknown>) {
  try {
    const response = await client.requests.post(family, payload);
    return { success: true, response };
  } catch (error: any) {
    return { success: false, error };
  }
}

export async function registerRequest(
  payload: Record<string, unknown>,
): Promise<APIRegisterResponse> {
  try {
    const { clientId, clientSecret } = getClientCredentials();
    const { data } = await client.auth.token(clientId, clientSecret, {
      "grant_type": "client_credentials",
    });
    const response = await client.members.v2.register.checkEligibility(payload, data.access_token);
    return { success: true, result: response.data.result, code: response.data.code };
  } catch (error: any) {
    return { success: false, error: error, code: error.status };
  }
}

export async function registerEmailRequest(payload: Record<string, unknown>, memberId: string) {
  try {
    const { clientId, clientSecret } = getClientCredentials();
    const { data } = await client.auth.token(clientId, clientSecret, {
      "grant_type": "client_credentials",
    });
    const response = await client.members.v2.register.updateMemberEmail(
      payload,
      memberId,
      data.access_token,
    );
    return { success: true, response };
  } catch (error: any) {
    return { success: false, error: error };
  }
}

export async function activateAccount(
  memberData: APIMemberData,
  activationFormData: Record<string, unknown>,
  identityCriteriaList: IdentityCriteria[],
  platform: string,
) {
  try {
    const { clientId, clientSecret } = getClientCredentials();
    const { data } = await client.auth.token(clientId, clientSecret, {
      "grant_type": "client_credentials",
    });
    const { hash_len, m, p, salt, t } = await getHashParams({
      username: memberData.username ?? undefined,
      token: data.access_token,
    });

    // Build activation payload
    const activationPayload: Record<string, unknown> = {
      ...activationFormData,
      term_of_use_accepted: true,
      registration_method: platform,
      registration_date: dayjs().format("YYYY-MM-DD"),
      marketing_email: activationFormData.marketing_email ? "opt-in" : null,
      marketing_sms: activationFormData.marketing_sms ? "opt-in" : null,
      crm_id: memberData.id,
    };

    // Add identity criteria to the activation payload
    identityCriteriaList
      .filter((identityCriteria) => identityCriteria.editable === true)
      .forEach((identityCriteria) => {
        activationPayload[identityCriteria.criteria] = memberData[identityCriteria.criteria];
      });

    // Add encrypted web_password to the activation payload
    activationPayload.web_password = await hashPassword(
      activationPayload.web_password as string,
      salt,
      t,
      m,
      p,
      hash_len,
    );

    const response = await client.members.v2.register.activateAccount(
      data.access_token,
      activationPayload,
    );
    return { success: true, response };
  } catch (error: any) {
    const errorJSON = error?.toJSON();
    return { success: false, error: errorJSON, code: errorJSON?.status };
  }
}

export interface AddressesResponse {
  success: boolean;
  data?: SearchAddressResponse[];
  error?: any;
}
export async function getAddresses(value: string, addressType: string): Promise<AddressesResponse> {
  try {
    const { data } = await client.addresses.autocomplete({
      input: value,
      type: addressType,
    });
    return { success: true, data: data.data as SearchAddressResponse[] };
  } catch (error: any) {
    return { success: false, error: error.toJSON() };
  }
}

export interface AddressResponse {
  success: boolean;
  response?: GooglePlaceAddress;
  error?: any;
}
export async function getAddress(addressId: string): Promise<AddressResponse> {
  try {
    // Fetch address detail
    const addressData = await client.addresses.place(addressId);
    return { success: true, response: formatLocation(addressData.data) };
  } catch (error: any) {
    return { success: false, error: error.toJSON() };
  }
}

export async function cancelRequest(id: string, family: string) {
  try {
    const { data } = await client.requests.delete(id, family);
    return { success: true, data: data.data };
  } catch (error: any) {
    return { success: false, error: error.toJSON() };
  }
}

export async function modifyRequest(id: string, family: string, notes: string) {
  try {
    const { data } = await client.requests.comment(id, family, notes);
    return { success: true, data: data.data };
  } catch (error: any) {
    return { success: false, error: error.toJSON() };
  }
}

export async function fetchConfig(id: string) {
  useConfigStore.getState().setFetching(true)
  try {
    const authenticated = useAuthStore.getState().authenticated;
    let authToken: string | undefined = undefined;

    if (!authenticated) {
      const { clientId, clientSecret } = getClientCredentials();
      const { data } = await client.auth.token(clientId, clientSecret, {
        "grant_type": "client_credentials",
      });
      authToken = data.access_token;
    }

    const response = await client.content.v2.resources.get(id, authToken);
    useConfigStore.getState().setFetching(false);
    return {
      success: true,
      config: response.data.results[0].data,
    }
  } catch (error: any) {
    useConfigStore.getState().setFetching(false);
    return { success: false, error: error.toJSON() };
  }
}
