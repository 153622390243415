import React, { useEffect, useMemo, useState, useCallback } from "react";
import { formatConciergeRequestPayload } from "@companion-core/shared/app/Utils/wizardHelper";
import { DynamicFormJson } from "@companion-core/shared/app/Interfaces/wizard";
import { useBellStore } from "@companion-core/shared/app/Store/bellStore";
import { BellHome } from "@companion-core/shared/app/Components/Bell/Home";
import { CategoryHeader } from "@companion-core/shared/app/Components/Bell/CategoryHeader";
import { BellConfirmation } from "@companion-core/shared/app/Components/Bell/Confirmation";
import { BellCategories } from "@companion-core/shared/app/Components/Bell/Categories/BellCategories";
import { DynamicForm } from "@companion-core/shared/app/Components/DynamicForm";
import { getJSONFromFile } from "@companion-core/shared/app/Assets/forms/concierge/jsonLoader";
import { BellContentProps } from ".";
import { trackClickBell, trackStepBell } from "@companion-core/shared/app/tracking/bell";
import { getModule } from "@companion-core/shared/app/Utils/config";
import { getInitialBellContent } from "@companion-core/shared/app/Utils/bell";
import { useWizardStore } from "@companion-core/shared/app/Store/wizardStore";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useBellRequest } from "@companion-core/shared/app/Queries/requests";
import "Assets/Styles/scss/bell.scss";

export const BellContent = ({ onClose }: BellContentProps) => {
  const isBellHomeEnabled: boolean = getModule("concierge")?.modules.bell.options.home.enabled;
  const { bellContent, bellCategory, formPath, setBellContent, clearBell } = useBellStore(
    (state) => ({
      bellContent: state.bellContent,
      bellCategory: state.bellCategory,
      formPath: state.formPath,
      setBellContent: state.setBellContent,
      clearBell: state.clearBell,
    }),
  );
  const { resetWizard } = useWizardStore((state) => ({ resetWizard: state.resetWizard }));
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [payload, setPayload] = useState<Record<string, unknown>>({});
  const [jsonForm, setJsonForm] = useState<DynamicFormJson | null>(null);
  const { mutateAsync: sendBellRequest, reset, status } = useBellRequest();

  useEffect(() => {
    setJsonForm(null);
    if (formPath) {
      setJsonForm(getJSONFromFile(formPath));
    }
  }, [formPath]);

  const dynamicFormName = useMemo(() => {
    const splitPath = formPath?.split("/");
    if (!splitPath) return "form";
    return splitPath[splitPath.length - 1].replace(".json", "");
  }, [formPath]);

  const onSubmitForm = async (payload: Record<string, unknown>, refetch = false) => {
    const category: string = payload.family
      ? (payload.family as string)
      : bellCategory?.category ?? "";
    trackClickBell(refetch ? "bell_submit" : "retry_submit", category);
    const formattedPayload: Record<string, unknown> = formatConciergeRequestPayload(
      payload,
      category,
    );
    try {
      await sendBellRequest({
        family: category,
        payload: formattedPayload,
      });
    } catch (error) {
      setPayload(payload);
    }
    trackStepBell(status, "bell", category);
    setBellContent("confirmation");
  };

  /**
   * This function is used to reset the wizard and the bell and go to the "home" page
   */
  const resetAll = useCallback(() => {
    onClose();
    resetWizard();
    clearBell(getInitialBellContent());
  }, [resetWizard, clearBell]);

  const onConfirmationButtonClicked = useCallback(async () => {
    if (status === "success") {
      trackClickBell("My_Request", "", "My_Request");
      queryClient.invalidateQueries({ queryKey: ["my-requests"] });
      resetAll();
      navigate("/my-requests");
    } else if (status === "error") {
      reset(); // reset mutation to retry into error page
      await onSubmitForm(payload, true);
    }
  }, [status]);

  const ContentComponent = () => {
    if (isBellHomeEnabled && bellContent === "home") {
      return <BellHome />;
    }
    if (bellContent === "categories") {
      return <BellCategories />;
    }
    if (bellContent === "form" && jsonForm) {
      return (
        <DynamicForm
          name={dynamicFormName}
          initialFormJSON={jsonForm}
          onSubmitForm={onSubmitForm}
        />
      );
    }
    if (bellContent === "confirmation") {
      return <BellConfirmation requestStatus={status} onClick={onConfirmationButtonClicked} />;
    }
  };

  return (
    <div data-testid="bell__content" className="d-flex bell__content">
      {bellCategory && bellContent !== "confirmation" && <CategoryHeader category={bellCategory} />}
      <ContentComponent />
    </div>
  );
};
