import React from "react";
import "@companion-core/web/src/Assets/Styles/scss/dynamicPage.scss";
import { DynamicContent } from "@companion-core/shared/app/Components/Blocks/DynamicContent";
import { useParams } from "react-router-dom";
import { useBlocks } from "@companion-core/shared/app/Queries";
import { useClientToken } from "@companion-core/shared/app/Hooks/clientToken";
import { useI18nStore } from "@companion-core/shared/app/Store/i18nStore";

interface Block {
  id: string;
  type: string;
  slug: string;
  display_type: string;
}

export const DynamicPage = () => {
  const { locale: lang } = useI18nStore((state) => ({ locale: state.locale }));
  const { slug } = useParams();
  const fetchClientToken = useClientToken();
  const { isLoading, data } = useBlocks(slug ?? "", lang, fetchClientToken);

  return (
    <div data-testid="dynamic-page" className="dynamic-page">
      {!isLoading &&
        data &&
        data.map((block: Block, index: number) => {
          return (
            <div key={`block-${index}`} className="colored-background">
              <DynamicContent
                type={block.type}
                contentProps={{
                  id: block.id,
                  slug: block.slug,
                  lang: lang,
                  displayType: block.display_type,
                }}
              />
            </div>
          );
        })}
    </div>
  );
};
