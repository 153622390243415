import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import { JPInputRadioShapeProps } from "./index";
import "@companion-core/web/src/Assets/Styles/scss/radiobutton.scss";
import { TextInShape } from "@companion-core/shared/app/Components/TextInShape";
import { JPInputError } from "@companion-core/shared/app/Components/Inputs/JPInputError";
import "@companion-core/web/src/Assets/Styles/scss/inputradioshape.scss";

export const JPInputRadioShape: React.FC<JPInputRadioShapeProps> = ({
  name,
  options,
  value,
  payloadPath = name,
  testID,
  required,
  registerValidation,
  setFieldErrors,
  onValueChange,
}: JPInputRadioShapeProps) => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const [error, setError] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>(value);
  const inputValueRef = useRef(inputValue); // Create a ref to store the latest value and avoid stale closure

  useEffect(() => {
    registerValidation(name, validateInput);
  }, []);

  useEffect(() => {
    inputValueRef.current = inputValue; // Update ref value
  }, [inputValue]);

  useEffect(() => {
    if (form && payloadPath) {
      form.setFieldsValue({
        [payloadPath]: inputValue,
      });
    }
    if (onValueChange) {
      onValueChange(inputValue);
    }
  }, [onValueChange, inputValue, form, payloadPath]);

  const validateInput = () => {
    const err = !inputValueRef.current && required ? "common.form.input.error.missing" : "";
    setError(err);
    setFieldErrors && setFieldErrors(err ? [err] : []);
  };

  return (
    <>
      {error && <JPInputError testID={testID} error={error} position="top" />}
      <div data-testid={testID} className="radioshape-input">
        {options.map((option) => (
          <div key={`radioshape-${name}-${option.value}`} className="radioshape-input__button">
            <TextInShape
              value={option.value}
              testID={`${testID}-radioshape`}
              text={t(option.text)}
              onClick={(value) => setInputValue(value)}
              selected={inputValue === option.value}
            />
          </div>
        ))}
      </div>
    </>
  );
};
