/* eslint-disable camelcase */
import { useQuery } from "@tanstack/react-query";
import { client } from "@johnpaul/jp-api-client";

import { APIBlock, JPClientRequest } from "@companion-core/shared/app/Interfaces/API";
import { sortData } from "@companion-core/shared/app/Utils/componentHelper";

export const useBlocks = (pageSlug: string, lang: string, enabled = true) => {
  return useQuery(
    ["pages", pageSlug, lang],
    () => client.content.v2.pages.getPageBlocks(pageSlug, { lang: lang }),
    {
      select: (data: JPClientRequest<APIBlock>) => sortData(data.data.results) as APIBlock[],
      enabled, // The query will not execute until the enabled param exists => useful when we need to wait another request
    },
  );
};
