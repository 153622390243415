import React from "react";
import { useTranslation } from "react-i18next";

export const NoRights = () => {
  const { t } = useTranslation();

  return (
    <div data-testid="sso__no-rights" className="error__container">
      <img
        data-testid="sso__no-rights--image"
        className="error__image"
        src="/assets/images/error/forbidden_error.svg"
        alt="error image"
      />
      <h1 data-testid="sso__no-rights--title" className="body-text-3 error__title">
        {t("common.error.sso.no-rights.title")}
      </h1>
      <p data-testid="sso__no-rights--body" className="error__sso-body body-text-4 margin-top-24">
        <span className="error__sso-body-item">{t("common.error.sso.no-rights.text.pre")}</span>
        <span className="error__sso-body-item error__sso-body-item--space-between">
          {t("common.error.sso.no-rights.text.partner")}
        </span>
        <span className="error__sso-body-item">{t("common.error.sso.no-rights.text.post")}</span>
      </p>
    </div>
  );
};
