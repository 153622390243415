import React from "react";
import { useParams } from "react-router-dom";
import { PersonalInformation } from "@companion-core/shared/app/Components/MyAccount/Sections";
import { WizardStoreProvider } from "@companion-core/shared/app/Store/wizardStore";

export const DynamicSection = () => {
  const { section } = useParams();
  const dynamicSection = section ?? "personal-information";

  if (dynamicSection) {
    return (
      <WizardStoreProvider>
        {dynamicSection === "personal-information" && <PersonalInformation />}
      </WizardStoreProvider>
    );
  }
  return <div>The component {dynamicSection} has not been created yet.</div>;
};
