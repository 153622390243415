export default {
  Home: 1,
  Login: 2,
  Registration: 3,
  Contents: 4,
  Bell: 5,
  MyAccount: 6,
  Brand: 7,
  Error: 8,
};
