import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "@companion-core/shared/app/Components/Button";
import { FontIcon } from "@companion-core/shared/app/Components/FontIcon";
import { getConfig } from "@companion-core/shared/app/Utils/config";

export const ServerError = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const conciergeEmail = getConfig()?.options.conciergeEmail;

  return (
    <div className="error__container">
      <img className="error__image" src="/assets/images/error/server_error.svg" />
      <div className="body-text-3 error__title">{t("common.error.500.title")}</div>
      <div className="body-text-4 margin-top-24">{t("common.error.500.text")}</div>
      <Button
        classNames={["error__button"]}
        testID="server-error__button"
        label={t("common.error.500.button")}
        onClick={() => navigate(-1)}
      />
      <div className="body-text-4 margin-top-24">{t("common.error.500.contact-us.text")}</div>
      <span className="error__contact-us-email">
        <FontIcon name="JP-Message" size={24} className="margin-right-8" />
        <a className="link-1" href={`mailto:${conciergeEmail}`}>
          {conciergeEmail}
        </a>
      </span>
    </div>
  );
};
